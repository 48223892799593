<template>
  <div class="fileSelector">
    <div v-if="noFiles" class="rectangle-copy"
      @drop.prevent="onDrop" @dragover.prevent="onDragOver" @dragleave.prevent="offDragOver">
      <div v-if="!isDragging" class="choose-files-or-drag" style="margin: 12rem 0px 12rem;">
          <div>
            <RocIcon icon="upload"></RocIcon>
          </div>
        <input ref="filesDirectory" webkitdirectory mozdirectory msdirectory odirectory directory multiple type="file" style="display: none" @change="selectFile($event)"/>
        <input ref="filesIndividual" type="file" style="display: none" @change="selectFile($event)" multiple/>
        Choose <a @click.prevent="selectDirectory" style="color: var(--overwatch-primary); cursor: pointer;">Directory</a>, <a @click.prevent="selectIndividual" style="color: var(--overwatch-primary); cursor: pointer;">Files</a> or drag them here
      </div>
      <div v-else class="choose-files-or-drag" style="margin: 12rem 0px 12rem;">
        Drop Here
      </div>
    </div>
    <div v-else style="display: flex; flex-direction: column; height: 100%;">
      <div class="files-grid">
        <div class="files-grid-titles">
          <div></div>
          <div>First Name</div>
          <div>Last Name</div>
          <div>Internal ID</div>
          <div>Path</div>
          <div class="final-column">
            <RocIcon
            v-if="showDelete"
            style="display: block; margin-left: auto;"
            icon="trash"
            size="sm"
            color="black"
            @click="clearFiles"/>
            <div v-else>
              Status
            </div>
          </div>
        </div>
        <div class="file-panel" v-for="(file, index) in filterFiles" :key="index">
          <div>
            <img class="preview-img" :src="previewImageCache[index]" style='max-height: 38px; width: auto;'/>
          </div>
          <div>
            <div id="f"  class="editable" :contenteditable="globalAllowEdit" @blur="contentChanged($event, index)">
              {{file.f}}
            </div>
          </div>
          <div>
            <div id="l" class="editable" :contenteditable="globalAllowEdit" @blur="contentChanged($event, index)">
              {{file.l}}
            </div>
          </div>
          <div>
            <div id="internalId" class="editable" :contenteditable="globalAllowEdit" @blur="contentChanged($event, index)">
              {{ file.internalId }}
            </div>
          </div>
          <div>{{file.path}}</div>
          <div class="final-column">
            <RocIcon v-if="showDelete" style="margin-right: 8px; cursor: pointer;" size="sm" icon="edit" @click="toggleEditNotes(index)"/>
            <RocIcon v-if="showDelete" style="cursor: pointer;" color="red"  size="sm" icon="trash" @click="deleteFile(index)"/>
            <div v-if="file.enrollStatus === 'success'">
              <Popper class="rocPopper" arrow content="Enrollment successful.">
                <RocIcon icon="check" size="lg" color="primary"/>
              </Popper>
            </div>
            <div v-if="file.enrollStatus === 'failed'">
              <Popper class="rocPopper" arrow :content='file.enrollStatusMsg'>
                <RocIcon icon="error" color="red" size="lg"/>
              </Popper>
            </div>
            <div v-if="file.enrollStatus === 'pending'">
              <roc-spinner :size="'sm'"/>
            </div>
          </div>
          <div v-if="isEditNotes(index)" class="edit-notes-section">
            <div id="notes"
            class="editable"
            :contenteditable="globalAllowEdit" @blur="contentChanged($event, index)">
              {{file.notes}}
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: auto; margin-bottom: 1rem">
        <div class="d-flex justify-content-between" style="margin-top: 2px; margin-bottom: 2px; width: 95%; margin-left: 16px;">
          <div style="min-width: 33.33%; text-align: left; padding-left: 6px;">
            {{fileNames.length}} 
            <RocIcon icon="upload" size="sm"/>
          </div>
          <div style="min-width: 33.33%; text-align: center;">
            {{page}} / {{totalPages}}
          </div>
          <div style="min-width: 33.33%; text-align: right; padding-right: 4px;">
          </div>
        </div>
        <div class="d-flex justify-content-between" style="width: 95%; margin-left: 16px; margin-top: 1rem;">
          <div style="min-width: 33.33%; text-align: left"></div>
          <div style="min-width: 33.33%">
            <RocButton size="sm" @click="decrementPage"
            style="padding: var(--spacing-s); margin-right: var(--spacing-s)">
              <RocIcon icon="downArrow" size="sm" color="white" style="transform: rotate(90deg)"/>
            </RocButton>
            <RocButton size="sm" @click="incrementPage" style="padding: var(--spacing-s)">
              <RocIcon icon="downArrow" size="sm" color="white" style="transform: rotate(-90deg)"/>
            </RocButton>
          </div>
          <div style="min-width: 33.33%; text-align: right; padding-right: 4px;">
            <RocButton ref="enrollButton" size="normal" @click="enroll" :disabled="buttonDisabled">{{buttonTitle}}</RocButton>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from "vue";
import Popper from "vue3-popper";
import { useStore } from 'vuex';
import { Capacitor } from "@capacitor/core";
import csvHelper from "@/js/csvHelper";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  name: "WatchlistFileSelector",
  emit: ["enroll", "close"],
  props: {
    buttonTitle: String,
    buttonDisabled: Boolean,
  },
  components: {
    Popper,
    RocIcon,
    RocButton
  },
  setup(props, context)
  {
    const store = useStore()
    const files = ref(null);
    const fileNames = ref([]);
    const page = ref(1);
    const totalPages = ref(1);
    const previewImageCache = ref([]);
    const filesDirectory = ref(null);
    const filesIndividual = ref(null);
    const showDelete = ref(true);
    const globalAllowEdit = ref(true);
    const notesEditIndex = ref(-1);
    let lastId = -1;
    const isDragging = ref(false);

    watch(page, () => {
      previewImageCache.value = [];
    });

    function selectDirectory() {
      filesDirectory.value.click();
    }

    function selectIndividual() {
      filesIndividual.value.click();
    }

    function enrollEmpty() {
      notesEditIndex.value = -1;
      showDelete.value = false;
      globalAllowEdit.value = false;
      context.emit('enrollEmpty');
    }

    function enroll() {
      if(props.buttonTitle === 'Close') {
        clearFiles();
        context.emit('close');
      }
      else {
        notesEditIndex.value = -1;
        showDelete.value = false;
        globalAllowEdit.value = false;
        context.emit('enroll', {files: fileNames, page: page});
      }
    }

    function clearFiles() {
      fileNames.value = [];
      page.value = 1;
      totalPages.value = 1;
      previewImageCache.value = [];
      files.value = [];
      showDelete.value = true;
      globalAllowEdit.value = true;
    }

    function contentChanged(e, index) {
      if(e.target.id === 'f') {
        if(filterFiles.value[index]) {
          filterFiles.value[index].f = e.target.innerText;
        }
      }
      else if(e.target.id === 'l') {
        if(filterFiles.value[index]) {
          filterFiles.value[index].l = e.target.innerText;
        }
      }
      else if(e.target.id === 'internalId') {
        if(filterFiles.value[index]) {
          filterFiles.value[index].internalId = e.target.innerText;
        }
      }
      else if(e.target.id === 'notes') {
        if(filterFiles.value[index]) {
          filterFiles.value[index].notes = e.target.innerText;
        }
      }
    }

    const noFiles = computed(function() {
      if(fileNames.value.length) {
        store.commit('watchlists/setNoFiles', false)
        return false;
      }
      else {
        store.commit('watchlists/setNoFiles', true)
        return true;
      }
    });

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      })
    });

    const itemsPerPage = computed(() => {
      if (windowWidth.value <= 480) {
        // TODO: Fit as many as possible on screen. - Sean
        return 5;
      } else {
        return 10;
      }
    })

    const filterFiles = computed(function() {
      const startPos = (page.value - 1) * itemsPerPage.value;
      const endPos = (startPos + itemsPerPage.value);
      return fileNames.value.slice(startPos, endPos);
    });

    watch(filterFiles, () => {
      loadPreviewImageCache(filterFiles.value);
    });

    async function loadPreviewImageCache(filteredResultSet) {
      previewImageCache.value = [];
      for(let i = 0; i < filteredResultSet.length; i++) {
        const file = filteredResultSet[i];
        if(file) {
            if(!previewImageCache.value[i]) {
              const bytes = await loadPreview(file);
              previewImageCache.value.push(bytes);
            }
          }
        }
    }

    function deleteFile(index) {
      fileNames.value.splice(index, 1);
      previewImageCache.value.splice(index, 1);
      const newMaxPages = Math.ceil(fileNames.value.length / itemsPerPage.value);
      if(newMaxPages != totalPages.value) {
        totalPages.value = newMaxPages;
        decrementPage();
      }
    }

    function getNameParts(fullName) {
      let first, last;
      let regexFirst, regexLast;

      var platform = Capacitor.getPlatform();        
      if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent) || platform === 'ios') {
         // Special case for Safari/iOS as it doesn't support the 'positive lookbehind' RegEx in the general case
        const isCamelOrPascal = new RegExp('(([A-Z][a-z0-9]+)+)|(^[a-z]+(([A-Z][a-z0-9]+)+))', 'g');
        if (isCamelOrPascal.test(fullName)) {
          try {
            var splitByCapitals = fullName.match(/^([a-z]+(?=[A-Z]))|[A-z][a-z]+/g);
            first = splitByCapitals[0].charAt(0).toUpperCase() + splitByCapitals[0].slice(1);
            last = splitByCapitals[splitByCapitals.length-1];
          } catch {
            first = '';
            last = '';
          }
        } else {
          var splitBySpaces = fullName.split(/-_ /g);
          first = splitBySpaces[0];
          last = splitBySpaces[splitBySpaces.length-1];
        }
      } else {
        const regexCaseSeparation = new RegExp('^[A-Z0-9][a-z0-9]+(?<=[a-z0-9])[A-Z0-9][a-zA-Z0-9]+', 'g');
        if(regexCaseSeparation.test(fullName)) {
          regexFirst = new RegExp('^[A-Z0-9][a-z0-9]+', 'g');
          regexLast = new RegExp('(?<=[a-z0-9])[A-Z0-9][a-zA-Z0-9]+', 'g');
        }
        else {
          regexFirst = new RegExp('^[a-zA-Z0-9]+', 'g');
          regexLast = new RegExp('(?<=[- _])[a-zA-Z0-9_ -]+', 'g');
        }
      
        first = fullName.match(regexFirst) ? fullName.match(regexFirst)[0].trim() : '';
        last = fullName.match(regexLast) ? fullName.match(regexLast)[0].trim() : '';        
      }

      return {
        firstname: first,
        lastname: last
      };
    }

    async function selectFile(e) {
      files.value = e.target.files;
      let fileArray = [];
      try {
        fileArray = Array.from(files.value);
      } catch (err) {
        console.log(err);
      }
      processFileArray(fileArray);
    }

    async function processFileArray(fileArray) {
      let totalImageFiles = 0;
      let manifest;
      try {
        fileArray.sort((a,b) => {
          if(a.name > b.name) {
            return 1;
          }
          if(a.name < b.name) {
            return -1;
          }
          return 0;
        });
        const manifestFile = fileArray.find(file => file.name === 'manifest.csv')
        if(manifestFile) {
          console.log("manifest.csv found.  Processing.");
          const manifestData = await readAsText(manifestFile);
          manifest = CSVToManifestObjArray(manifestData);
        }
      } catch(err) {
        console.log(err);
      }

      for(let i = 0; i < fileArray.length; i++) {
        let file = fileArray[i];
        const fileExt = file.name.substring(file.name.lastIndexOf('.')+1, file.name.length).toLowerCase();
        if(file && (file['type'].split('/')[0] === 'image' || fileExt === 'eft' || fileExt === 'ebts')) {
          totalImageFiles++;
          let fileDetails;
          try {
            if(manifest && manifest.rows) {
              fileDetails = manifest.rows.find(row => {
                if(row.file_name.toLowerCase() === file.name.toLowerCase()) {
                  return true;
                }
              });
              if(!fileDetails) {
                console.log(`details for this file (${file.name}) were not found in the manifest.  Check your CSV data and formatting.`)
              }
            }
          } catch(err) {
            console.log('failed to process mainfest.csv, check formatting. ', err);
          }

          let details = fileDetails;
          const nameNoEx = file.name.split('.')[0];
          if (!details) {
            if (nameNoEx && fileExt !== 'eft') {
              details = getNameParts(nameNoEx);
            } else {
              details = {};
            }
          }
          try {
            const filePath = file.webkitRelativePath ? file.webkitRelativePath : file.name;
            const enrollmentPackage = {
              id: ++lastId,
              f: details.firstname,
              l: details.lastname,
              notes: details.notes,
              internalId: details.internalId,
              code: details.code,
              fileHandle: file,
              path: filePath
            };
            fileNames.value.push(enrollmentPackage);
          }
          catch(err) {
            console.log(err);
          }
        }
      }
      totalPages.value = Math.ceil(totalImageFiles / itemsPerPage.value);
    }

    function CSVToManifestObjArray(CSV_string) {
      const delimiter = ',';
      const firstRowHeader = true;

      const resultObj = new csvHelper().csvToObject(CSV_string, delimiter, firstRowHeader);
      if(resultObj && resultObj.rows.length) {
        try {
          const arrayOfObjs = resultObj.rows.map(enrollment => ({
            firstname: enrollment[0],
            lastname: enrollment[1],
            code: enrollment[2],
            notes: enrollment[3],
            file_name: enrollment[4],
            internalId: enrollment[5],
          }));
          resultObj.rows = arrayOfObjs;
          return resultObj;
        }

        catch(err) {
          console.log(err);
        }
        return {};
      }
    }

   async function loadPreview(file) {
      return await loadImage(file.fileHandle);
   }

   async function loadImage(file) {
      if (file) {
        if (file.type.startsWith("image") === true) {
          return await readAsDataURL(file);
        }
        else {
          return null;
        }
      }
    }

    function readAsDataURL(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsDataURL(file);
      });
    }

    function readAsText(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsText(file, "UTF-8");
      });
    }

    function decrementPage() {
      if(page.value > 1) {
        page.value--;
        notesEditIndex.value = -1;
      }
    }

    function incrementPage() {
      if(page.value < totalPages.value) {
        page.value++;
        notesEditIndex.value = -1;
      }
    }

    function toggleEditNotes(index) {
      if (notesEditIndex.value === index) {
        notesEditIndex.value = -1;
      } else {
        notesEditIndex.value = index;
      }
    }

    function isEditNotes(index) {
      return notesEditIndex.value === index;
    }

    async function onDrop(ev) {
      ev.preventDefault();
      isDragging.value = false;
      ev.currentTarget.classList.remove('rectangle-drag-active');
      
      let fileArray = [];
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...ev.dataTransfer.items].forEach(async (item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            fileArray.push(item.getAsFile());
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...ev.dataTransfer.files].forEach(async (file, i) => {
          fileArray.push(file);
        });
      }
      processFileArray(fileArray);
    }

    function onDragOver(ev) {
      ev.preventDefault();
      isDragging.value = true;
      ev.currentTarget.classList.add('rectangle-drag-active');
    }
    function offDragOver(ev) {
      ev.preventDefault();
      isDragging.value = false;
      ev.currentTarget.classList.remove('rectangle-drag-active');
    }

    return {
      selectFile,
      noFiles,
      fileNames,
      filterFiles,
      page,
      totalPages,
      deleteFile,
      loadPreview,
      previewImageCache,
      contentChanged,
      decrementPage,
      incrementPage,
      itemsPerPage,
      clearFiles,
      enroll,
      enrollEmpty,
      filesDirectory,
      filesIndividual,
      selectDirectory,
      selectIndividual,
      showDelete,
      globalAllowEdit,
      notesEditIndex,
      toggleEditNotes,
      isEditNotes,
      onDrop,
      onDragOver,
      offDragOver,
      isDragging
    }
  }
};
</script>

<style scoped lang="scss">
.fileSelector {
  @include overwatch-body-xsmall;
  width: 100%;
}
.rectangle-copy {
  box-sizing: border-box;
  /* height: 475px; */
  width: 100%;
  height: 98%;
  border: 3px dashed var(--overwatch-neutral-100);
    background-color: var(--overwatch-light-button-primary-20);
}

.choose-files-or-drag {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  line-height: 35px;
  text-align: center;
}

.files-container {
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}

.files-grid {
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,3fr) minmax(0,3fr) minmax(0,2fr) minmax(0,4fr) minmax(0,2fr);
  grid-template-rows: 24px;
  grid-auto-rows: 42px;
  justify-items: start;
  align-items: center;
  
  margin-top: var(--spacing-base);

  height: 450px;
  overflow-y: auto;
}

.files-grid-titles {
  display: contents;
}

.files-grid-titles > div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.file-panel {
  display: contents;
}

.file-panel > div {
  height: 100%;
  width: 100%;
  background-color: (--overwatch-secondary);

  border: 1px solid var(--overwatch-neutral-300);
  border-left: 0px;
  border-right: 0px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  padding: var(--spacing-base);
}

.file-panel > div:first-child {
  border-left: 1px solid var(--overwatch-neutral-300);
} 

.file-panel > div:last-child {
  border-right: 1px solid var(--overwatch-neutral-300);
}

.final-column {
  display: flex;
  width: 100%;
  justify-content: flex-end !important;
}

.edit-notes-section {
  border: 1px solid var(--overwatch-neutral-300) !important;
  background-color: var(--overwatch-neutral-500);
  grid-column: 1 / -1;
}

.editable {
  height: 24px;
  width: 100%;
  text-align: start;
  
}

.preview-img {
  border-radius: 4px;
}
.rocPopper :deep(.popper) {
  background: var(--overwatch-primary);
  padding: 20px;
  border-radius: 20px;
  color: var(--overwatch-button-text);
  text-transform: uppercase;
}

.rocPopper :deep(.popper #arrow::before) {
  background: var(--overwatch-primary);
}

.rocPopper :deep(.popper:hover),
.rocPopper :deep(.popper:hover > #arrow::before) {
  background: var(--overwatch-primary);
}

textarea {
  resize: none;
}

.rectangle-drag-active {
  border: 3px solid var(--overwatch-neutral-300);
}

@media (max-width: 480px) {
  .files-container {
    /* display:flex; 
    flex-direction: column; */
    height: auto;
  }

  .rectangle-copy {
    height: 100%;
  }

  .file-path {
    min-width: 30%;
  }
  .choose-files-or-drag {
    font-size: 18px;
  }
}

</style>