<template>
  <div class="order-buttons">
    <RocButton class="button top" @click="$emit('up')">
      <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'white'"
        @click="$emit('up')"
        style="transform: rotate(180deg);"
      />
    </RocButton>
    <RocButton class="button bottom" @click="$emit('down')">
      <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'white'"
        @click="$emit('down')"
      />
    </RocButton>
  </div>
</template>

<script>
import RocButton from './RocButton.vue'
import RocIcon from './RocIcon.vue'

export default {
  name: 'OrderButtons',
  components: {
    RocIcon,
    RocButton
  },
  emit: ['up', 'down']
}

</script>

<style scoped>
.order-buttons {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}
.button {
  padding-top: 0;
  padding-bottom: 0;
}

.btn-primary{
  background: var(--overwatch-button-primary);
  color: var(--overwatch-button-text);
}

.top {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.bottom {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>