<template>
  <div class="d-flex flex-column user-card justify-content-between" id="configCard" v-if="!hideCard">
    <div class="d-flex flex-row align-items-center" >
      <div v-if="title" class="overwatch-title-small flex-grow-1">{{title}}</div>
        <div
        style=
          'color:var(--overwatch-neutral-100);
          text-decoration: underline;
          -webkit-appearance: none;
          -moz-appearance: none;
          cursor: pointer;
          appearance: none;
          padding: 8px 15px 10px;'
          tag='a'
          @click.prevent="isAdvancedEditorDisplayed = !isAdvancedEditorDisplayed"
        >
         Advanced
        </div>
    </div>
    <slot></slot>
  </div>
  <BaseDialog :show="isAdvancedEditorDisplayed" :title="editorTitle" @close="cancelAdvancedSettings" :style="dialogStyle">
    <div :style="jsonEditorStyle">
      <JsonEditor
       v-model="jsonEditorValue"
       class="json-editor"
       style="height: 100%; width: 100%;"></JsonEditor>
    </div>
    <div class="d-flex justify-content-end" style="margin-top: 10px; margin-right: 10px; gap: var(--spacing-s)">
      <RocButton @click="okAdvancedSettings" color="secondary">OK</RocButton>
      <RocButton @click="cancelAdvancedSettings" color="link">Cancel</RocButton>
    </div>
  </BaseDialog>
</template>

  <script>
  import { computed, ref, watch, onMounted } from "vue";
  import JsonEditor from "json-editor-vue3";
  import BaseDialog from "@/components/ui/BaseDialog.vue";
  import { useStore } from "vuex";
  import RocButton from "@/components/ui/RocButton.vue";

  export default {
    name: "JSONObjectSettings",
    components: {
    JsonEditor,
    BaseDialog,
    RocButton
    },
    emits: ["editedValues", "close"],
    props: {
      title: String,
      cardJsonObject: Object,
      jsonObject: Object,
      path: String,
      showEditor: Boolean,
      hideCard: Boolean
    },
    setup(props, context) {
      const store = useStore();
      const windowWidth = ref(window.innerWidth);
      const isAdvancedEditorDisplayed = ref(props.showEditor);
      const jsonEditorValue = ref(props.cardJsonObject);

      onMounted(async () => {
        window.addEventListener('resize', () => {
          windowWidth.value = window.innerWidth;
        });
      })

      watch(() => props.cardJsonObject, () => {
        jsonEditorValue.value = props.cardJsonObject;
      });

      const editorTitle = computed(() => {
        if (props.path) {
          return props.path
        }
        else{
          return "Advanced Editor"
        }
      });

      const dialogStyle = computed(() => {
        if (windowWidth.value <= 480) {
          // Mobile style
          return {
            'width': '100%'
          }
        }
        else {
          return {
            'width': '900px',
            'max-width': '90%',
            'max-height': '90%'
          }
        }
      });

      const jsonEditorStyle = computed(() => {
        if (windowWidth.value <= 480) {
          return {
            'height': '80vh',
            'filter': darkMode.value ? 'invert(1)' : 'invert(0)'
          }
        }
        else {
          return {
            'height': '500px',
            'max-height': '90%',
            'filter': darkMode.value ? 'invert(1)' : 'invert(0)'
          }
        }
      });

      function cancelAdvancedSettings() {
        isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
        context.emit("close");
      }

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    function cancelAdvancedSettings() {
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
      context.emit("close");
    }

    function okAdvancedSettings() {
      context.emit("editedValues", jsonEditorValue.value, props.path);
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
    }

      return {
        jsonEditorValue,
        isAdvancedEditorDisplayed,
        dialogStyle,
        cancelAdvancedSettings,
        okAdvancedSettings,
        editorTitle,
        jsonEditorStyle
      };
    }
  };
  </script>

  <style scoped lang="scss">
  .user-card {
    width: 100% !important;
    min-width: 25rem;
    border-radius: 0px 0px 0px 0px !important;
    padding: 4px !important;
    margin: 0;
    background: var(--overwatch-secondary);
    border-bottom: 1px solid var(--overwatch-neutral-300);
    margin-right: 0rem !important;
  }

  .disabled {
    color: var(--overwatch-error);
  @include overwatch-body-small;
    line-height: 21px;
  }

  .dropdown {
    display: flex;
    align-items: center;
  }

  .disabled-mobile {
    display: none;
  }

  .json-editor :deep(.json-editor-vue),
  .json-editor :deep(.json-editor-vue) * {
    font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace;
  }

  @media (max-width: 480px) {
    .user-card {
      min-width: 0;
      width: 100%;
      margin-bottom: 0 !important;
    }

    .disabled-mobile {
      display: block;
      margin-top: 3px;
    }

    .disabled-desktop {
      display: none;
    }
  }

  </style>