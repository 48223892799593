<script setup>
import { ref, onMounted, watchEffect, mounted, onBeforeUnmount, computed, defineEmits, defineProps } from 'vue';
import RocColorPicker from '@/components/ui/RocColorPicker.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import RocSwitch from '@/components/ui/RocSwitch.vue';
import RocButton from '@/components/ui/RocButton.vue';
import RocPopper from '@/components/ui/RocPopper.vue';
import RocRange from '@/components/ui/RocRange.vue';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu.vue';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import { MDBDropdown, MDBDropdownItem } from "mdb-vue-ui-kit";
import { useStore } from 'vuex';
import IAcknowledge from '@/components/settings/IAcknowledge.vue';
import { useRoute } from "vue-router";

const route = useRoute();
const store = useStore();
const emits = defineEmits(['close']);
const props = defineProps(['zones', 'cameraId']);
const settingsMenuRef = ref(null);
const settingsMenuIconRef = ref(null);
const colorPickerRef = ref(null);
const camera = ref(null);
const cameraId = ref(null);
const cameraName = ref(null);
const cameraPreview = ref(null);
const disconnectCount = ref(0);
const showAcknowledge = ref(false);

let socket;
onMounted(async () => {
    showAcknowledge.value = true;

    cameraId.value = route.query.cameraGuid;
    cameraName.value = route.query.cameraName;
    camera.value = store.getters['cameras/findByGUID'](cameraId.value);

    //TODO - this call to socket doesn't work yet, will be addressed in later task
    const payload = `feed=livestream&topic=${cameraId.value}`;
      socket = await store.dispatch("auth/getSocketIO", payload);
      socket.on(cameraId.value, (payload) => {
        isLoading.value = false;
        cameraPreview.value = 'data:image/png;base64, ' + payload.image;
      });

      cameraPreview.value = camera.value.previewImage;

      socket.on('disconnect', async (reason) => {
        camState.value = false;
        if (reason === "io server disconnect" || reason === "ping timeout") {
          console.log("socket.io disconnected.");
        }
      });

      socket.on('connect', async (reason) => {
        if(disconnectCount.value > 0) {
          await store.dispatch("cameras/loadCameras");
        }
      });
});

// onBeforeUnmount(() => {
//       socket.close();
// });

function closeAcknowledge() {
    showAcknowledge.value = false;
    //close window
    window.close();
}

const settingsToggle = ref(false);
function openCloseSettings(){
    settingsToggle.value = !settingsToggle.value;
}

watchEffect((onRefInvalidate) => {
      //check if settingsToggle is true before adding event listener.
      if (settingsToggle.value) {
        // Attach click event listener to the document body when div is visible
        document.body.addEventListener('click', handleOutsideClick, { passive: true });
        // Remove event listener when component is unmounted or div is hidden
        onRefInvalidate(() => {
          document.body.removeEventListener('click', handleOutsideClick, { passive: true });
        });
      }
    });

const handleOutsideClick = (event) => {
    // Check if the clicked element is outside the target div
    if (settingsMenuRef.value && !settingsMenuRef.value.contains(event.target)) {
        //if the click is on the settings menu icon do not perform function
        if(settingsMenuIconRef.value && !settingsMenuIconRef.value.contains(event.target)){
            settingsToggle.value = false;
        }
    }
};

const activeDisableMenuToggle = ref(false);
const liveCamActive = ref(false);

const includeSwitch = ref(false);
const liveCamToggle = ref(false);
const opacityValue = ref(0.75);
const colorValue = ref('#00fff0');

function revert() {
    includeSwitch.value = false;
    liveCamToggle.value = false;
    opacityValue.value = 0.75;
    colorValue.value = '#00fff0';
}

//TODO - this doesn't work yet, will be addressed in later task
const isLoading = ref(false);
async function toggleCamState(refresh = false) {
    isLoading.value = true;
    try {
        const result = await store.dispatch('cameras/toggleCameraState', {
        forceRefresh: refresh,
        GUID: cameraId.value,
        });
        if(result && result.status === 'failed') {
        camState.value = false;
        }
    } catch (error) {
        error.value = error.message || 'Something went wrong!';
    }
    isLoading.value = false;
}

const camState = ref(false);
function handleSwitchToggle(t) {
    camState.value = t;
    toggleCamState(true);
}

</script>

<template>
    <div class="page-container">
        <div class="header">
            <div class="overwatch-title-large">{{cameraName}}</div>
            <RocIcon
            style="margin-left: auto; cursor: pointer;"
            icon="addButton" color="primary" size="lg"
            />
        </div>
        <div class="editor">
            <img :src="cameraPreview" style="height: 100%; width: 100%"/>
        </div>
        <div class="settings-footer">
            <div class="overwatch-title-small">Capture Zones</div>
            <MDBDropdown
            dropend
            v-model="activeDisableMenuToggle"
            align="start"
            class="active-disabled-menu-container"
            >
                <RocDropdownToggle @click="activeDisableMenuToggle = !activeDisableMenuToggle">
                    <RocButton :size="'md'" color="primary" class="active-disabled-button">
                        <div :class="{ 'active-bubble': liveCamActive, 'disabled-bubble': !liveCamActive }"></div>
                        <div>
                          {{ liveCamActive ? 'Active' : 'Disabled' }}
                        </div>
                        <RocIcon
                        icon="inputArrow"
                        :rotate90="true"
                        size="xs"
                        color="white"
                        style="margin-left: auto;"
                        />
                    </RocButton>
                </RocDropdownToggle>
                <RocDropdownMenu
                class="active-disabled-dropdown-menu"
                @click="activeDisableMenuToggle = !activeDisableMenuToggle">
                    <MDBDropdownItem href="#" @click="liveCamActive = true">
                       <div class="active-disabled-dropdown-menu-item">
                            <div class="active-bubble"></div>
                            <div>Active</div>
                        </div>
                    </MDBDropdownItem>
                    <MDBDropdownItem href="#" @click="liveCamActive = false">
                        <div class="active-disabled-dropdown-menu-item">
                            <div class="disabled-bubble"></div>
                            <div>Disabled</div>
                        </div>
                    </MDBDropdownItem>
                </RocDropdownMenu>
            </MDBDropdown>
            <div>Exclude</div>
            <RocSwitch :isActive="camState" @switch-toggled="handleSwitchToggle" :disabled="isLoading" style=""/>
            <div>Include</div>
            <div class="settings-icon" @click="openCloseSettings()" ref="settingsMenuIconRef">
                <RocIcon icon="settings" color="black" size="md"/>
            </div>

            <div class="settings-menu" v-if="settingsToggle" ref="settingsMenuRef">
                <RocIcon
                @click="includeSwitch = false"
                style="margin-left: auto"
                icon="exit" color="black" size="sm"
                />
                <div class="settings-menu-items">
                    <div class="overwatch-title-small">Video Playback</div>
                    <div class="menu-item-container">
                        <div>Live Camera</div>
                        <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
                            <RocIcon size="sm" color="primary" icon="tooltip" />
                            <template #content>
                            <div class="d-flex flex-column" style="max-width: 225px">
                                <div>Live camera shows a video stream instead of snapshots.</div>
                            </div>
                            </template>
                        </RocPopper>
                        <div style="margin-left: auto">
                            <RocSwitch :isActive="includeSwitch" @switch-toggled="includeSwitch = $event" />
                        </div>
                    </div>
                    <div class="overwatch-title-small">Capture Zones</div>
                    <div class="menu-item-container">
                        <div>Opacity</div>
                        <input
                        class="opacity-input"
                        v-model="opacityValue"/>
                    </div>
                    <div class="menu-item-container">
                        <div style="width: 100%">
                            <RocRange v-model="opacityValue" :min="0" :max="1" :step="0.01"/>
                        </div>
                    </div>
                    <div class="menu-item-container">
                        <div>Color</div>
                        <RocColorPicker
                        v-model="colorValue"
                        placement="left"
                        ref="colorPickerRef"
                        />
                    </div>
                </div>
                <div class="revert" @click="revert()">
                    Revert
                </div>
            </div>
        </div>
    </div>

    <IAcknowledge
    v-if="showAcknowledge"
    @confirm="showAcknowledge = false;"
    @close="closeAcknowledge">
    <div>
        Once a Capture Zone is defined and saved as included or excluded,
        <span style="font-weight: 700">only included data will be stored and retrieved </span>
        until the zone is cleared and saved.
    </div>
    </IAcknowledge>
</template>

<style scoped lang="scss">
.page-container{
    display: flex;
    flex-direction: column;
    overflow: auto;
    height: 100vh;
}
.header{
    display: flex;
    align-items: center;
    padding: var(--spacing-s);
    height: 70px;
    flex: 0 0 auto;
}

.editor{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: calc(100vh - 140px);
}

.settings-footer{
    display: flex;
    align-items: center;
    height: 70px;
    width: 100%;
    flex: 0 0 auto;
    padding: var(--spacing-s);
    gap: var(--spacing-s);
    color: var(--overwatch-neutral-100);
}

.active-disabled-button{
    @include overwatch-body-small;
    display: flex;
    align-items: center;
    gap: var(--spacing-base);
    padding: var(--spacing-s);
    width: 115px;
}

//dropend button remove from mdb styling
.dropend .dropdown-toggle::after {
    display: none;
}

.active-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(to bottom, #e7f0e6 0%, #7ec778 39%, var(--overwatch-light-success) 100%);
}

.disabled-bubble{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: radial-gradient(circle at 50% 50%, #c9c9c9, #c5c5c5 0%, #b8b8b8 32%, var(--overwatch-light-neutral-300) 71%);
}

.active-disabled-dropdown-menu{
    margin-bottom: 8px;
    margin-left: 2px;
}

.active-disabled-dropdown-menu-item{
    display: flex;
    align-items: center;
    width: 100%;
    gap: var(--spacing-base);
    padding: var(--spacing-base) 0;
}

.settings-icon{
    margin-left: auto;
    cursor: pointer;
    position: relative;
}

/* menu popup */
.settings-menu{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--spacing-s);
    position: absolute;
    height: 300px;
    width: 325px;
    bottom: 75px;
    right: 10px;
    background-color: var(--overwatch-secondary);
    border-radius: 5px;
    z-index: 2;
}

/* container for all menu items */
.settings-menu-items{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-s);
    width: 100%;
    align-items: flex-start;
    padding: var(--spacing-s);
}

/* container for individual menu items */
.menu-item-container{
    display: flex;
    align-items: center;
    gap: var(--spacing-s);
    width: 100%;
}

.opacity-input{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    border-radius: 5px;
    background-color: var(--overwatch-neutral-500);
    border: 1px solid var(--overwatch-neutral-300);
    padding: var(--spacing-base);
}

input{
    all: unset;
}

.revert{
    color: var(--overwatch-primary);
    cursor: pointer;
    margin-left: auto;
    text-decoration: underline;
}
</style>