<script setup>
  import { ref, computed, watchEffect } from 'vue';
  import { useStore } from 'vuex';
  import RocIcon from "./RocIcon.vue";

  const props = defineProps({
    width: {
      type: String,
      default: '250px'
    },
    height: {
      type: String,
      default: '150px'
    }
  });

  const store = useStore();

  const logoSrc = ref(null);
  const isWhiteLabelEnabled = ref(false);

  const preAuthConfig = computed(() => store.getters['auth/preAuthConfig'])

  watchEffect(async () => {
    if(preAuthConfig.value){
      logoSrc.value = await store.dispatch('settings/getLogo', { preAuthConfig: preAuthConfig.value });
      isWhiteLabelEnabled.value = store.getters['auth/preAuthConfig'].isWhiteLabelEnabled;
    }
  })

</script>

<template>
      <div class="logoHolder">
          <RocIcon v-if="!isWhiteLabelEnabled"  icon="rocLogo" color="black" size="match"/>
          <img
            v-else-if="logoSrc !== null && logoSrc !== ''"
            :src='logoSrc'
            style="width: 100%; max-height: 100%; object-fit: contain;"
          />
      </div>
</template>

<style scoped>
.logoHolder {
  display: flex;
  width: v-bind(width);
  height: v-bind(height);
  max-height: v-bind(height);
  margin-left: auto;
  margin-right: auto;
}
</style>
