import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    lastFetch: null,
    encounters: [],
    encountersTimeout: [],
    encounterFilterState: true,
    dtFilterRange: [],
    searchTemplateId: null,
    searchImageData: null,
    searchModality: 'face',
    showImageSearchBox: false,
    searchText: null,
    cursor: null,
    moreData: false,
    liveFeedCameras:[],
    cameraLiveFeedState: 'collapsed',
    cameraLiveFeedMode: 'grid',
    matchesOnly: false,
    adjudicationFilter: [{label:'none'},{label:'confirmed'},{label:'rejected'}],
    attributesFilter: [],
    allFaceAttributes: [],
    allObjectAttributes: [],
    analyticsFilter: [],
    liveFeedEnabled: true,
    maxLiveEncounters: 5000,
    queryPageSize: 50
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
