<template>
  <div class="wrapper">
    <RocIcon 
      color="black"
      size="md"
      icon="previousEncounter"
      @click="$emit('previous')"
    />
    <RocIcon 
      color="black"
      size="md"
      icon="rewind10"
      @click="$emit('rewind10')"
    />
    <RocIcon 
      class="margins"
      color="black"
      size="sm"
      icon="rewind"
      @click="$emit('rewind')"
    />
    <RocIcon 
      v-show="isPlay"
      color="black"
      size="md"
      icon="play"
      @click="play"
    />
    <RocIcon 
      v-show="!isPlay"
      color="black"
      size="md"
      icon="pause2"
      @click="pause"
    />
    <RocIcon 
      class="margins"
      color="black"
      size="sm"
      icon="fastForward"          
      @click="$emit('fastForward')"
    />
    <RocIcon 
      color="black"
      size="md"
      icon="forward10"          
      @click="$emit('forward10')"
    />
    <RocIcon 
      color="black"
      size="md"
      icon="nextEncounter"
      @click="$emit('next')"
    />  
  </div>
</template>

<script>
import { ref } from 'vue';
import RocIcon from '@/components/ui/RocIcon';

export default {
  name: 'VideoControls',
  components: {
    RocIcon
  },
  emits: ['play', 'pause', 'forward10', 'rewind10', 'rewind', 'fastForward', 'next', 'prev'],
  setup(props, context) {
    const isPlay = ref(true);

    function play() {
      context.emit('play');
      isPlay.value = false;
    }

    function pause() {
      context.emit('pause');
      isPlay.value = true;
    }

    return {
      isPlay,
      play,
      pause,
    }
  }
}

</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  gap: $--spacing-base;

  padding: $--spacing-base 0;
  
  align-items: center;

  :deep(svg) {
    cursor: pointer;
  }
}

.margins {
  margin: 0 $--spacing-base;
}
</style>