import userAccessHelper from "@/js/userAccessHelper";

export default {
  token(state) {
    return state.token;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  isSSO(state) {
    return state.isSSO;
  },
  firstname(state) {
    return state.firstname;
  },
  lastname(state) {
    return state.lastname;
  },
  refreshToken(state) {
    return state.refreshToken;
  },
  email(state) {
    return state.email;
  },
  isAdmin(state) {
    try {
      const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
      return userAccessCookie && (userAccessCookie.role === 'admin');
    } catch (err) {
      return false;
    }
    // return state.userAccess.role === 'admin';
  },
  isViewOnly(state) {
    try {
      const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
      return userAccessCookie && (userAccessCookie.role === 'viewOnlyUser');
    } catch (err) {
      return false;
    }
    // return state.userAccess.role === 'admin';
  },
  userInitials(state) {
    // do we always have a firstname/lastname to use for intials?
    let intials = 'XX';
    if (state.firstname && state.firstname.length >= 1 &&
          state.lastname && state.lastname.length >= 1) {
      intials = state.firstname.substring(0,1) + state.lastname.substring(0,1);
    }
    return intials.toUpperCase();
  },
  serverId(state) {
    return state.server_id;
  },
  serverName(state) {
    return state.server_name;
  },
  serverProtocol(state) {
    return state.server_protocol;
  },
  serverHost(state) {
    return state.server_host;
  },
  serverPort(state) {
    return state.server_port;
  },
  backendUrl(state) {
    if (state.server_protocol && state.server_host) {
      return state.server_protocol + state.server_host + ((state.server_port != '') ? (":" + state.server_port) : '');
    } else {
      return '';
    }
  },
  socketUrl(state) {
    if (state.server_protocol && state.server_host) {
      if(state.server_protocol === 'http://') {
        return 'ws://' + state.server_host + ((state.server_port != '') ? (":" + state.server_port) : '');
      } else {
        return state.server_protocol + state.server_host + ((state.server_port != '') ? (":" + state.server_port) : '');
      }
    } else {
      return '';
    }
  },
  lastRefreshDelay(state) {
    return state.lastRefreshDelay;
  },
  lastRefreshTimeout(state) {
    return state.lastRefreshTimeout;
  },
  presetServers(state) {
    return state.presetServers;
  },
  oneTimeToken: (state) => {
    return state.oneTimeToken;
  },
  userConfig: (state) => {
    return state.userConfig
  },
  userSettingsChanged: (state) => {
    return state.userSettingsChanged
  },
  preAuthConfig: (state) => {
    return state.preAuthConfig ?? {};
  }
};
