class rocEnrollCaptureConfig {
  constructor() {}
  getConfigTags() {
      const tags = [];
      for (const config of this.configs) {
          if (!config.hidden) {
              tags.push(config.tag);
          }
      }
      return tags;
  }
  getConfig(tag) {
      for (const config of this.configs) {
          if (config.tag === tag) {
              return config;
          }
      }
      return this.configs[0];
  }
  getSelectedConfig() {
      return this.configs[this.selectedConfigIndex];
  }
  // TODO this overwrites existing config with matching tag
  addConfig(config) {
      let index = this.configs.findIndex(x => x.tag === config.tag);
      if (index != -1) {
          this.configs[index] = config;
      } else {
          this.configs.push(config);
      }
  }
  removeConfig(tag) {
      let index = this.configs.findIndex(x => x.tag === tag);
      if (index != -1) {
          this.configs.splice(index, 1);
      }
  }
  setSelectedConfig(tag) {
      for (let i = 0; i < this.configs.length; i++) {
          if (this.configs[i].tag === tag) {
              this.selectedConfigIndex = i;
              break;
          }
      }
  }
  initConfigList(configList) {
      this.configs = [];
      configList.forEach((config) => {
          if (config.tag == 'Credential') {
              this.configCredential = config;
          } else {
              this.configs.push(config);
          }
      });
  }

  isRequireICAO() {
      return this.configs[this.selectedConfigIndex].captureThresholds.icaoRequired;
  }

  checkFaceCompliance(metaData, detectionData, imageWidth, imageHeight, scaleFactor) {
    const thresholds = this.configs[this.selectedConfigIndex].captureThresholds;
    // only check Spoof, Evasion, GaussianBlur, Quality, MotionBlur, JPEG if they are available to check
    const livenessCompliant = (metaData.Spoof ? this.checkFaceLiveness(metaData.Spoof) : true);
    const evasionCompliant = (metaData.Evasion ? this.checkFaceEvasion(metaData.Evasion) : true);
    const gaussianBlurCompliant = (metaData.GaussianBlur ? this.checkFaceGaussianBlur(metaData.GaussianBlur) : true);
    const qualityCompliant = (metaData.Quality ? this.checkFaceQuality(metaData.Quality) : true);
    const motionBlurCompliant = (metaData.MotionBlur ? this.checkFaceMotionBlur(metaData.MotionBlur) : true);
    const jpegCompliant = (metaData.JPEG ? this.checkFaceJpeg(metaData.JPEG) : true);

    const isCompliant = (mdStr, compliance) => {
      if (thresholds.omittedThresholds && !thresholds.omittedThresholds.includes(mdStr)) {
        return compliance;
      } else {
        return true;
      }
    }

    const verifyCompliant =
      // only check Mask, Emotion, Glasses, Age, if they are available to check
      (metaData.MaskDetection ? (!this.checkFaceObstructions(metaData.MaskDetection)) : true) &&
      (metaData.Emotion ? this.checkFaceEmotion(metaData.Emotion) : true) &&
      (metaData.Glasses ? this.checkFaceGlasses(metaData.Glasses) : true) &&
      (metaData.Age ? this.checkFaceAge(metaData.Age) : true) &&
      this.checkFacePitch(metaData.Pitch) &&
      this.checkFaceYaw(metaData.Yaw) &&
      this.checkFaceCenterX(detectionData.x, imageWidth) &&
      this.checkFaceCenterY(detectionData.y, imageHeight) &&
      this.checkFaceRotation(detectionData.rotation) &&
      this.checkFaceIOD(metaData.IOD, scaleFactor) &&
      isCompliant('GaussianBlur', gaussianBlurCompliant) &&
      isCompliant('Evasion', evasionCompliant) &&
      isCompliant('Spoof', livenessCompliant) &&
      isCompliant('Quality', qualityCompliant) &&
      isCompliant('MotionBlur', motionBlurCompliant) &&
      isCompliant('JPEG', jpegCompliant);

    const icaoBaseCompliant =
      metaData.ICAODynamicRangeCompliant &&
      metaData.ICAOLightingCompliant &&
      metaData.ICAOEyesAlignedCompliant &&
      metaData.ICAOGlareCompliant &&
      metaData.ICAOEyeVisibilityCompliant &&
      metaData.ICAOOcclusionCompliant;

    const icaoAdvancedCompliant =
      metaData.ICAOUniformBackgroundCompliant;

    const compliance = {
        verify: verifyCompliant,
        icaoBase: icaoBaseCompliant,
        icaoAdvancedCompliant: icaoAdvancedCompliant,
        liveness: livenessCompliant
    };
    return compliance;
  }

  checkIdDocCompliance(metaData, detectionData) {
      const compliant =
          // this.checkIdPitch(metaData.Pitch) &&
          // this.checkIdYaw(metaData.Yaw) &&
          // this.checkIdRotation(detectionData.rotation) &&
          // this.checkIdIOD(metaData.IOD) &&
          (metaData.Quality ? this.checkFaceQuality(metaData.Quality) : true) && 
          this.checkIdLiveness(metaData.Spoof);

      const compliance = {};
      compliance.idCompliant = compliant;
      return compliance;
  }

  checkFaceAge(age) {
    return age >= this.configs[this.selectedConfigIndex].captureThresholds.Age;
  }
  checkFacePitch(pitch) {
      return pitch >= this.configs[this.selectedConfigIndex].captureThresholds.Pitch.min && 
          pitch <= this.configs[this.selectedConfigIndex].captureThresholds.Pitch.max;
  }
  checkFaceYaw(yaw) {
      return yaw >= this.configs[this.selectedConfigIndex].captureThresholds.Yaw.min && 
          yaw <= this.configs[this.selectedConfigIndex].captureThresholds.Yaw.max;
  }

  checkFaceCenterX(faceX, imageWidth) {
      const thresholdXmin = this.configs[this.selectedConfigIndex].captureThresholds.CenterX.min * imageWidth;
      const thresholdXmax = this.configs[this.selectedConfigIndex].captureThresholds.CenterX.max * imageWidth;
      return faceX >= thresholdXmin && faceX <= thresholdXmax;
  }
  checkFaceCenterY(faceY, imageHeight) {
      const thresholdYmin = this.configs[this.selectedConfigIndex].captureThresholds.CenterY.min * imageHeight;
      const thresholdYmax = this.configs[this.selectedConfigIndex].captureThresholds.CenterY.max * imageHeight;
      return faceY >= thresholdYmin && faceY <= thresholdYmax;
  }
  checkFaceRotation(rotation) {
      return rotation >= this.configs[this.selectedConfigIndex].captureThresholds.Roll.min && 
          rotation <= this.configs[this.selectedConfigIndex].captureThresholds.Roll.max;
  }
  checkFaceIOD(iod, scaleFactor=1) {
    return iod >= (this.configs[this.selectedConfigIndex].captureThresholds.IOD*scaleFactor);
  }
  checkFaceLiveness(liveness) {
      return liveness <= this.configs[this.selectedConfigIndex].captureThresholds.Spoof;
  }
  checkFaceGaussianBlur(gaussianBlur) {
    return gaussianBlur <= this.configs[this.selectedConfigIndex].captureThresholds.GaussianBlur;
  }
  checkFaceMotionBlur(motionBlur) {
    return motionBlur <= this.configs[this.selectedConfigIndex].captureThresholds.MotionBlur;
  }
  checkFaceJpeg(jpeg) {
    return jpeg <= this.configs[this.selectedConfigIndex].captureThresholds.JPEG;
  }
  checkFaceEvasion(evasion) {
    return evasion <= this.configs[this.selectedConfigIndex].captureThresholds.Evasion;
  }
  checkFaceEmotion(emotion) {
      if (!emotion) {
          return false;
      }
      const allowedEmotionArr = Array.from(this.configs[this.selectedConfigIndex].captureThresholds.allowedEmotions);
      if (allowedEmotionArr.length == 0) {
          // empty allowed emotions list means any emotion allowed
          return true;
      } else {
          // check for emotion in the allowed emotions array, case insensitive
          const emotionFound = allowedEmotionArr.findIndex(element => {
              return element.toLowerCase() === emotion.toLowerCase();
          });
          return emotionFound != -1;
      }
  }
  checkFaceGlasses(glasses) {
      if (this.configs[this.selectedConfigIndex].captureThresholds.allowGlasses) {
          return true;
      } else {
          return glasses == "None";
      }
  }
  checkFaceObstructions(maskDetection) {
    return maskDetection == "Mask";
  }
  checkFaceQuality(quality) {
      return quality >= this.configs[this.selectedConfigIndex].captureThresholds.Quality;
  }

  checkIdPitch(pitch) {
      return pitch >= this.configCredential.captureThresholds.Pitch.min && pitch <= this.configCredential.captureThresholds.Pitch.max
  }
  checkIdYaw(yaw) {
      return yaw >= this.configCredential.captureThresholds.Yaw.min && yaw <= this.configCredential.captureThresholds.Yaw.max
  }
  checkIdRotation(rotation) {
      return rotation >= this.configCredential.captureThresholds.Roll.min && rotation <= this.configCredential.captureThresholds.Roll.max;
  }
  checkIdIOD(iod) {
      return iod >= this.configCredential.captureThresholds.IOD;
  }
  checkIdLiveness(liveness) {
      return liveness > this.configCredential.captureThresholds.Spoof;
  }
  checkIdCenterX(idX, imageWidth) {
      const thresholdXmin = this.configCredential.captureThresholds.CenterX.min * imageWidth;
      const thresholdXmax = this.configCredential.captureThresholds.CenterX.max * imageWidth;
      return idX >= thresholdXmin && idX <= thresholdXmax;
  }
  checkIdCenterY(idY, imageHeight) {
      const thresholdYmin = this.configCredential.captureThresholds.CenterY.min * imageHeight;
      const thresholdYmax = this.configCredential.captureThresholds.CenterY.max * imageHeight;
      return idY >= thresholdYmin && idY <= thresholdYmax;
  }
  checkIdQuality(quality) {
      return quality >= this.configCredential.captureThresholds.Quality;
  }
  getIcaoRetryCount() {
      try {
          return this.configs[this.selectedConfigIndex].frameCounts.icaoRetry;
      } catch {
          return 10;
      }
  }
  getMinCompliantFaceCount() {
      try {
          return this.configs[this.selectedConfigIndex].frameCounts.minCompliantFace;
      } catch {
          return 1;
      }
  }
  getIdPageOneWait() {
      try {
          return this.configCredential.frameCounts.pageOneWait;
      } catch {
          return 5;
      }
  }
  getIdPageTwoWait() {
      try {
          return this.configCredential.frameCounts.pageTwoWait;
      } catch {
          return 5;
      }
  }
  getIdPageTwoRetry() {
      try {
          return this.configCredential.frameCounts.pageTwoRetry;
      } catch {
          return 5;
      }
  }
  getIdPageOneTimeout() {
    try {
        return this.configCredential.frameCounts.pageOneTimeout;
    } catch {
        return 5;
    }
  }
  getIdBarcodeTimeout() {
    try {
        return this.configCredential.frameCounts.barcodeTimeout;
    } catch {
        return 5;
    }
  }
  getIdBarcodeTimeoutWaiting() {
    try {
        return this.configCredential.frameCounts.barcodeTimeoutWait;
    } catch {
        return 10;
    }
  }
  getImageConfig() {
    try {
      if (this.configs[this.selectedConfigIndex].imageConfig) {
        return this.configs[this.selectedConfigIndex].imageConfig;
      } else {
        return {};
      }
    } catch {
        return {};
    }
  }
  getImageConfigId() {
    try {
      if (this.configCredential.imageConfig) {
        return this.configCredential.imageConfig;
      } else {
        return {};
      }
    } catch {
        return {};
    }
  }
  isIcaoBackgroundEnabled() {
    return this.configs[this.selectedConfigIndex].captureThresholds.icaoRequired && this.configs[this.selectedConfigIndex].captureThresholds.enableIcaoBackground;
  }
  isIcaoBackgroundRemovalEnabled() {
    return this.configs[this.selectedConfigIndex].captureThresholds.icaoRequired && this.configs[this.selectedConfigIndex].captureThresholds.enableIcaoBackgroundRemoval;
  }
  isIdUseRocFaceDetect() {
    try {
      return this.configCredential.useRocFaceDetect;
    } catch {
      return false;
    }
  }
  getIdQualityThresholds() {
    try {
      if (this.configCredential.qualityThresholds) {
        return this.configCredential.qualityThresholds;
      } else {
        return {
          glare: {enabled: false},
          focus: {enabled: false},
          resolution: {enabled: false},
          colorness: {enabled: false},
          perspective: {enabled: false},
          bounds: {enabled: false},
          screenCapture: {enabled: false},
          portrait: {enabled: false},
          relativeSize: {enabled: false}
        };
      }
    } catch {
      return {
        glare: {enabled: false},
        focus: {enabled: false},
        resolution: {enabled: false},
        colorness: {enabled: false},
        perspective: {enabled: false},
        bounds: {enabled: false},
        screenCapture: {enabled: false},
        portrait: {enabled: false},
        relativeSize: {enabled: false}
      };
    }
  }
  getIdQualityThresholdFocus() {
    try {
      if (this.configCredential.qualityThresholds && this.configCredential.qualityThresholds.focus) {
        return this.configCredential.qualityThresholds.focus;
      } else {
        return { enabled: false };
      }
    } catch {
      return { enabled: false };
    }
  }
  getIdQualityThresholdPerspective() {
    try {
      if (this.configCredential.qualityThresholds && this.configCredential.qualityThresholds.perspective) {
        return this.configCredential.qualityThresholds.perspective;
      } else {
        return { enabled: false };
      }
    } catch {
      return { enabled: false };
    }
  }
  getIdQualityThresholdResolution() {
    try {
      if (this.configCredential.qualityThresholds && this.configCredential.qualityThresholds.resolution) {
        return this.configCredential.qualityThresholds.resolution;
      } else {
        return { enabled: false };
      }
    } catch {
      return { enabled: false };
    }
  }
  getIdQualityThresholdBounds() {
    try {
      if (this.configCredential.qualityThresholds && this.configCredential.qualityThresholds.bounds) {
        return this.configCredential.qualityThresholds.bounds;
      } else {
        return { enabled: false };
      }
    } catch {
      return { enabled: false };
    }
  }
  isIdAuthenticityEnforced() {
    try {
      return this.configCredential.enforceAuthenticity;
    } catch {
      return false;
    }
  }
  isIdOcrResultEnforced() {
    try {
      return this.configCredential.enforceOcrResults;
    } catch {
      return false;
    }
  }
  isIdExpirationEnforced() {
    try {
      return this.configCredential.enforceExpiration;
    } catch {
      return false;
    }
  }
  isIdAuthenticityChecked() {
    try {
      return this.configCredential.checkAuthenticity;
    } catch {
      return false;
    }
  }
  getGenericBarcodeCaptureTimeout() {
    try {
      return this.configCredential.genericBarcodeTimeout;
    } catch {
      return 10;
    }
  }
  getMetricsObjectID() {
    return {
      pitch:{ value: '', compliant: '' },
      yaw: { value: '', compliant: '' },
      rotation: { value: '', compliant: '' },
      iod: { value: '', compliant: '' },
      spoof: { value: '', compliant: '' },
      quality: { value: '', compliant: '' }
    }
  }
  getMetricsObject() {
    return {
      obstructions : { value: '', compliant: '' },
      emotion : { value: '', compliant: '' },
      glasses : { value: '', compliant: '' },
      age : { value: '', compliant: '' },
      pitch : { value: '', compliant: '' },
      yaw : { value: '', compliant: '' },
      centerx : { value: '', compliant: '' },
      centery : { value: '', compliant: '' },
      rotation : { value: '', compliant: '' },
      iod : { value: '', compliant: '' },
      spoof : { value: '', compliant: '' },
      quality : { value: '', compliant: '' },
      ICAOLightingCompliant : { value: '', compliant: '' },
      ICAODynamicRangeCompliant : { value: '', compliant: '' },
      ICAOEyesAlignedCompliant : { value: '', compliant: '' },
      ICAOEyeVisibilityCompliant: { value: '', compliant: '' },
      ICAOGlareCompliant : { value: '', compliant: '' },
      ICAOOcclusionCompliant: { value: '', compliant: '' },
      ICAOUniformBackgroundCompliant : { value: '', compliant: '' },
      gaussianBlur : { value: '', compliant: '' },
      motionBlur: { value: '', compliant: '' },
      evasion: { value: '', compliant: '' },
      jpeg: { value: '', compliant: '' },
    }
  }
  updateFaceMetrics(metaData, detectionData, imageWidth=0, imageHeight=0, scaleFactor=1, mirroredImage=false) {
    let coachingMsg = '';
    let coachingUpdated = false;
    let metrics = this.getMetricsObject();
    if (typeof metaData.IOD !== 'undefined') {
      metrics.iod.value = metaData.IOD;
      const iodCompliant = this.checkFaceIOD(metaData.IOD, scaleFactor);
      metrics.iod.compliant = iodCompliant ? 'compliant' : 'noncompliant';
      if (!iodCompliant && !coachingUpdated) {
        if (metaData.IOD > this.configs[this.selectedConfigIndex].captureThresholds.IOD) {
          // coachingMsg = "Face Too Big: Please move further from the camera.";
          // coachingUpdated = true;
        } else {
          coachingMsg = "Please move closer to the camera.";
          coachingUpdated = true;
        }
      }
    } else {
      metrics.iod.value = "";
      metrics.iod.compliant = "";
    }
    if (typeof metaData.Spoof !== 'undefined') {
      metrics.spoof.value = (Math.round(metaData.Spoof * 1000000) / 1000000).toFixed(4);
      metrics.spoof.compliant = this.checkFaceLiveness(metaData.Spoof) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceLiveness(metaData.Spoof) && !coachingUpdated) {
        coachingMsg = "Present Face for Capture";
        coachingUpdated = true;
      }
    } else {
      metrics.spoof.value = "";
      metrics.spoof.compliant = "";
    }
    if (typeof metaData.GaussianBlur !== 'undefined') {
      metrics.gaussianBlur.value = (Math.round(metaData.GaussianBlur * 1000000) / 1000000).toFixed(4);
      metrics.gaussianBlur.compliant = this.checkFaceGaussianBlur(metaData.GaussianBlur) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceGaussianBlur(metaData.GaussianBlur) && !coachingUpdated) {
        coachingMsg = "Hold Still";
        coachingUpdated = true;
      }
    } else {
      metrics.gaussianBlur.value = "";
      metrics.gaussianBlur.compliant = "";
    }
    if (typeof metaData.MotionBlur !== 'undefined') {
      metrics.motionBlur.value = (Math.round(metaData.MotionBlur * 1000000) / 1000000).toFixed(4);
      metrics.motionBlur.compliant = this.checkFaceMotionBlur(metaData.MotionBlur) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceMotionBlur(metaData.MotionBlur) && !coachingUpdated) {
        coachingMsg = "Hold Camera Still";
        coachingUpdated = true;
      }
    } else {
      metrics.motionBlur.value = "";
      metrics.motionBlur.compliant = "";
    }
    if (typeof metaData.Evasion !== 'undefined') {
      metrics.evasion.value = (Math.round(metaData.Evasion * 1000000) / 1000000).toFixed(4);
      metrics.evasion.compliant = this.checkFaceEvasion(metaData.Evasion) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceEvasion(metaData.Evasion) && !coachingUpdated) {
        coachingMsg = "Present Face for Capture";
        coachingUpdated = true;
      }
    } else {
      metrics.evasion.value = "";
      metrics.evasion.compliant = "";
    }
    // ICAO only provided if requested, check for presence
    if (typeof metaData.ICAODynamicRangeScore !== 'undefined') {
      metrics.ICAODynamicRangeCompliant.value = metaData.ICAODynamicRangeCompliant;
      metrics.ICAODynamicRangeCompliant.compliant = metaData.ICAODynamicRangeCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAODynamicRangeCompliant && !coachingUpdated) {
        coachingMsg = "Uneven Lighting: Please adjust lighting";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAODynamicRangeCompliant.value = "";
      metrics.ICAODynamicRangeCompliant.compliant = "";
    }
    if (typeof metaData.ICAOLightingScore !== 'undefined') {
      metrics.ICAOLightingCompliant.value = metaData.ICAOLightingCompliant;
      metrics.ICAOLightingCompliant.compliant = metaData.ICAOLightingCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOLightingCompliant && !coachingUpdated) {
        coachingMsg = "Please adjust lighting";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOLightingCompliant.value = "";
      metrics.ICAOLightingCompliant.compliant = "";
    }
    if (typeof metaData.ICAOEyesAlignedCompliant !== 'undefined') {
      metrics.ICAOEyesAlignedCompliant.value = metaData.ICAOEyesAlignedCompliant;
      metrics.ICAOEyesAlignedCompliant.compliant = metaData.ICAOEyesAlignedCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOEyesAlignedCompliant && !coachingUpdated) {
        coachingMsg = "Your face should be in a frontal and level position";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOEyesAlignedCompliant.value = "";
      metrics.ICAOEyesAlignedCompliant.compliant = "";
    }
    if (typeof metaData.ICAOGlareScore !== 'undefined') {
      metrics.ICAOGlareCompliant.value = metaData.ICAOGlareCompliant;
      metrics.ICAOGlareCompliant.compliant = metaData.ICAOGlareCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOGlareCompliant && !coachingUpdated) {
        coachingMsg = "Glare detected: Please adjust angle with light source";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOGlareCompliant.value = "";
      metrics.ICAOGlareCompliant.compliant = "";
    }
    if (typeof metaData.ICAOUniformBackgroundScore !== 'undefined') {
      metrics.ICAOUniformBackgroundCompliant.value = metaData.ICAOUniformBackgroundCompliant;
      metrics.ICAOUniformBackgroundCompliant.compliant = metaData.ICAOUniformBackgroundCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOUniformBackgroundCompliant && !coachingUpdated) {
        coachingMsg = "Uniform Background: Please move to an area with a uniform background";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOUniformBackgroundCompliant.value = "";
      metrics.ICAOUniformBackgroundCompliant.compliant = "";
    }
    if (typeof metaData.ICAOOcclusionCompliant !== 'undefined') {
      metrics.ICAOOcclusionCompliant.value = metaData.ICAOOcclusionCompliant;
      metrics.ICAOOcclusionCompliant.compliant = metaData.ICAOOcclusionCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOOcclusionCompliant && !coachingUpdated) {
        coachingMsg = "Occlusions detected: Please ensure face is in full view";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOOcclusionCompliant.value = "";
      metrics.ICAOOcclusionCompliant.compliant = "";
    }
    if (typeof metaData.ICAOEyeVisibilityCompliant !== 'undefined') {
      metrics.ICAOEyeVisibilityCompliant.value = metaData.ICAOEyeVisibilityCompliant;
      metrics.ICAOEyeVisibilityCompliant.compliant = metaData.ICAOEyeVisibilityCompliant ? 'compliant' : 'noncompliant';
      if (!metaData.ICAOEyeVisibilityCompliant && !coachingUpdated) {
        coachingMsg = "Your face should be in a frontal and level position";
        coachingUpdated = true;
      }
    } else {
      metrics.ICAOEyeVisibilityCompliant.value = "";
      metrics.ICAOEyeVisibilityCompliant.compliant = "";
    }
    if (typeof metaData.Quality !== 'undefined') {
      metrics.quality.value = (Math.round(metaData.Quality * 1000000) / 1000000).toFixed(4);
      metrics.quality.compliant = this.checkFaceQuality(metaData.Quality) ? 'compliant' : 'noncompliant';
    } else {
      metrics.quality.value = "";
      metrics.quality.compliant = "";
    }
    if (typeof metaData.Pitch !== 'undefined') {
      metrics.pitch.value = metaData.Pitch;
      metrics.pitch.compliant = this.checkFacePitch(metaData.Pitch) ? 'compliant' : 'noncompliant';
      if (!this.checkFacePitch(metaData.Pitch) && !coachingUpdated) {
        coachingMsg = "Face Camera Directly: Your face should be in a full frontal and level position.";
        coachingUpdated = true;
      }
    } else {
      metrics.pitch.value = "";
      metrics.pitch.compliant = "";
    }
    if (typeof metaData.Yaw !== 'undefined') {
      metrics.yaw.value = metaData.Yaw;
      metrics.yaw.compliant = this.checkFaceYaw(metaData.Yaw) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceYaw(metaData.Yaw) && !coachingUpdated) {
        coachingMsg = "Face Camera Directly: Your face should be in a full frontal and level position.";
        coachingUpdated = true;
      }
    } else {
      metrics.yaw.value = "";
      metrics.yaw.compliant = "";
    }
    metrics.rotation.value = Math.trunc(detectionData.rotation);
    metrics.rotation.compliant = this.checkFaceRotation(detectionData.rotation) ? 'compliant' : 'noncompliant';
    if (!this.checkFaceRotation(detectionData.rotation) && !coachingUpdated) {
      coachingMsg = "Face Camera Directly: Your face should be in a full frontal and level position.";
      coachingUpdated = true;
    }
    if (metaData.Emotion) {
      metrics.emotion.value = metaData.Emotion;
      metrics.emotion.compliant = this.checkFaceEmotion(metaData.Emotion) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceEmotion(metaData.Emotion) && !coachingUpdated) {
        coachingMsg = `${metaData.Emotion} Expression: Your face should be in a neutral expression.`;
        coachingUpdated = true;
      }
    } else {
      metrics.emotion.value = "";
      metrics.emotion.compliant = "";
    }
    if (typeof metaData.Glasses !== 'undefined') {
      metrics.glasses.value = metaData.Glasses;
      metrics.glasses.compliant = this.checkFaceGlasses(metaData.Glasses) ? 'compliant' : 'noncompliant';
      if (!this.checkFaceGlasses(metaData.Glasses) && !coachingUpdated) {
        coachingMsg = "Glasses Present: Please Remove Glasses for Capture";
        coachingUpdated = true;
      }
    } else {
      metrics.glasses.value = "";
      metrics.glasses.compliant = "";
    }
    if (typeof metaData.MaskDetection !== 'undefined') {
      metrics.obstructions.value = metaData.MaskDetection === "Mask" ? "Mask" : "None";
      metrics.obstructions.compliant = !this.checkFaceObstructions(metaData.MaskDetection) ? 'compliant' : 'noncompliant';
      if (this.checkFaceObstructions(metaData.MaskDetection) && !coachingUpdated) {
        coachingMsg = "Face Obstructed: Make sure nothing is between your face and the camera.";
        coachingUpdated = true;
      }
    } else {
      metrics.obstructions.value = "";
      metrics.obstructions.compliant = "";
    }
    // TODO Coaching?
    if (typeof metaData.Age !== 'undefined') {
      metrics.age.value = (Math.round(metaData.Age * 1000000) / 1000000).toFixed(4);
      metrics.age.compliant = this.checkFaceAge(metaData.Age) ? 'compliant' : 'noncompliant';
    } else {
      metrics.age.value = "";
      metrics.age.compliant = "";
    }
    if (typeof metaData.JPEG !== 'undefined') {
      metrics.jpeg.value = (Math.round(metaData.JPEG * 1000000) / 1000000).toFixed(4);
      metrics.jpeg.compliant = this.checkFaceJpeg(metaData.JPEG) ? 'compliant' : 'noncompliant';
    } else {
      metrics.jpeg.value = "";
      metrics.jpeg.compliant = "";
    }
    
    // TODO Coaching?
    const location = {
      tooFarLeft: false,
      tooFarRight: false,
      tooHigh: false,
      tooLow: false
    };
    if (imageWidth > 0 && imageHeight > 0) {
      let thresholdXmin = this.configs[this.selectedConfigIndex].captureThresholds.CenterX.min * imageWidth;
      let thresholdXmax = this.configs[this.selectedConfigIndex].captureThresholds.CenterX.max * imageWidth;
      let thresholdYmin = this.configs[this.selectedConfigIndex].captureThresholds.CenterY.min * imageHeight;
      let thresholdYmax = this.configs[this.selectedConfigIndex].captureThresholds.CenterY.max * imageHeight;
      metrics.centerx.value = Math.trunc(detectionData.x);
      metrics.centerx.compliant = this.checkFaceCenterX(detectionData.x, imageWidth) ? 'compliant' : 'noncompliant';
      if (!mirroredImage) { 
        if (detectionData.x < thresholdXmin) {
          location.tooFarLeft = true;
        } else {
          location.tooFarLeft = false;
        }
        if (detectionData.x > thresholdXmax) {
          location.tooFarRight = true;
        } else {
          location.tooFarRight = false;
        }
      } else {
        if (detectionData.x < thresholdXmin) {
          location.tooFarRight = true;
        } else {
          location.tooFarRight = false;
        }
        if (detectionData.x > thresholdXmax) {
          location.tooFarLeft = true;
        } else {
          location.tooFarLeft = false;
        }
      }
      // TODO Coaching?
      metrics.centery.value = Math.trunc(detectionData.y);
      metrics.centery.compliant = this.checkFaceCenterY(detectionData.y, imageHeight) ? 'compliant' : 'noncompliant';
      if (detectionData.y < thresholdYmin) {
        location.tooHigh = true;
      } else {
        location.tooHigh = false;
      }
      if (detectionData.y > thresholdYmax) {
        location.tooLow = true;
      } else {
        location.tooLow = false;
      }
      if (location.tooFarLeft || location.tooFarRight || location.tooHigh || location.tooLow) {
        if (!coachingUpdated) {
          coachingMsg = "Center your face";
          coachingUpdated = true;
        }
      }
    }

    return {
      coachingMsg: coachingMsg,
      metrics: metrics,
      location: location
    }
  }

  updateMetricsID(metaData, detectionData) {
    const metrics = this.getMetricsObjectID();
    let coachingMsg = '';
    let coachingUpdated = false;
    // SPOOF only provided if requested, check for presence
    metrics.iod.value = metaData.IOD;
    metrics.iod.compliant = this.checkIdIOD(metaData.IOD) ? 'compliant' : 'noncompliant';
    if (!this.checkIdIOD(metaData.IOD) && !coachingUpdated) {
      if (metaData.IOD > this.configCredential.captureThresholds.IOD) {
        // coachingMsg = "Too Big: Please move credential further from the camera.";
        // coachingUpdated = true;
      } else {
        coachingMsg = "Move Closer";
        coachingUpdated = true;
      }
    }
    if (metaData.Spoof) {
      metrics.spoof.value = (Math.round(metaData.Spoof * 1000000) / 1000000).toFixed(6);
      metrics.spoof.compliant = this.checkIdLiveness(metaData.Spoof) ? 'compliant' : 'noncompliant';
      if (!this.checkIdLiveness(metaData.Spoof) && !coachingUpdated) {
        coachingMsg = "Align Photo ID";
        coachingUpdated = true;
      }
    } else {
      metrics.spoof.value = "";
      metrics.spoof.compliant = "";
    }

    metrics.pitch.value = metaData.Pitch;
    metrics.pitch.compliant = this.checkIdPitch(metaData.Pitch) ? 'compliant' : 'noncompliant';
    if (!this.checkIdPitch(metaData.Pitch) && !coachingUpdated) {
      coachingMsg = "Align Photo ID: Your ID should be displayed level and flat";
      coachingUpdated = true;
    }
    metrics.yaw.value = metaData.Yaw;
    metrics.yaw.compliant = this.checkIdYaw(metaData.Yaw) ? 'compliant' : 'noncompliant';
    if (!this.checkIdYaw(metaData.Yaw) && !coachingUpdated) {
      coachingMsg = "Align Photo ID: Your ID should be displayed level and flat";
      coachingUpdated = true;
    }

    metrics.rotation.value = Math.trunc(detectionData.rotation);
    metrics.rotation.compliant = this.checkIdRotation(detectionData.rotation) ? 'compliant' : 'noncompliant';
    if (!this.checkIdRotation(detectionData.rotation) && !coachingUpdated) {
      coachingMsg = "Align Photo ID: Your ID should be displayed level and flat";
      coachingUpdated = true;
    }
    if (metaData.Quality) {
      metrics.quality.value = metaData.Quality;
      metrics.quality.compliant = this.checkIdQuality(metaData.Quality) ? 'compliant' : 'noncompliant';
    } else {
      metrics.quality.value = "";
      metrics.quality.compliant = "";
    }
    return {
      coachingMsg: coachingMsg,
      metrics: metrics
    }
  }
}

module.exports = rocEnrollCaptureConfig;