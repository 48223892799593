<template>
  <base-card
    :style='topPanelStyle'>
    <div class='d-flex justify-content-between'>
      <div>{{ panelname }}</div>
      <div>
        <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'black'"
        @click='togglePanel'
        style="transition: transform 0.1s ease;"
        :style="{ transform: iconChevronState === 'chevron-up' ? 'rotate(180deg)' : 'rotate(0deg)' }"/>
        <div style='width: 5px;'></div>
      </div>
    </div>
  </base-card>
  <base-card v-if='PanelVisibleState'
             :style='lowerPanelStyle'>
    <div class='w-100' style=''>
      <slot name='panelContents'></slot>
    </div>
  </base-card>
</template>

<script>
import BaseCard from '@/components/ui/BaseCard';
import {ref} from 'vue';
import RocIcon from '@/components/ui/RocIcon.vue';

export default {
  name: 'CollapsibleFilterSection',
  props: ['panelname', 'type'],
  components: {
    BaseCard,
    RocIcon
  },
  setup(props) {
    const PanelVisibleState = ref(false);
    const iconChevronState = ref('chevron-up');

    const topPanelStyle = ref('width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 0px 0px; padding: 0;');
    if (props.type === 'last_element') {
      topPanelStyle.value = 'width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 12px 12px; padding: 0;';
    }

    const lowerPanelStyle = ref('width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 0px 0px; padding: 0;');
    if (props.type === 'last_element') {
      lowerPanelStyle.value = 'width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 12px 12px; padding: 0;';
    }

    function togglePanel() {
      PanelVisibleState.value = !PanelVisibleState.value;
      if (PanelVisibleState.value) {
        topPanelStyle.value = 'width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 0px 0px; padding: 0;';
      }
      else {
        if (props.type === 'last_element') {
          topPanelStyle.value = 'width:22.5rem !important; padding: .0rem !important;border-radius: 0px 0px 12px 12px; padding: 0;';
        }
      }

      if (iconChevronState.value === 'chevron-up') {
        iconChevronState.value = 'chevron-down';
      }
      else {
        iconChevronState.value = 'chevron-up';
      }
    }

    return {
      PanelVisibleState,
      iconChevronState,
      togglePanel,
      lowerPanelStyle,
      topPanelStyle
    };
  }
};
</script>

<style scoped>

</style>