<template>
  <div class='camera-card' style="position:relative;">
    <div v-if='isLoading || (camState && !camOnline)' class='loading-spinner'>
      <roc-spinner/>
    </div>
    <div style="position: relative; opacity: 1.0;">
      <div style="display:flex; align-items: center; justify-content: center; border-radius: 6px 6px 0 0; overflow: hidden;">
        <img :src='cameraPreviewSrc' @error="missingPreview" :style='activeStyle()'/>
      </div>
      <div v-if="camState && camOnline && (procFPS || procDropped || procTimeDelta)" class="overwatch-body-xsmall" style="position: absolute; bottom: 5px; right: 5px; color: white; background-color: black; padding: 4px;">
        <div>FPS: {{procFPS}}</div>
        <div v-if="procDropped" style="color: var(--overwatch-error);">Frames Dropped: {{procDropped}}%</div>
        <div v-if="procTimeDelta" style="color: var(--overwatch-error);">Time Discrepancy: {{procTimeDelta}} minutes</div>
      </div>
    </div>
    <div class='camera-info' style="position: relative;">
      <div class="camera-info-child">
        <RocSwitch :isActive="camState" @switch-toggled="handleSwitchToggle" :disabled="isLoading" style=""/>
      </div>
      <div class="camera-info-child camera-name-field" :title="cameraName">{{ cameraName }} </div>
      <div class="camera-info-child">
        <MDBDropdown v-model='dropdownOptions' align='end'>
          <MDBDropdownToggle
              style='color:black;
                  -webkit-appearance: none;
                  -moz-appearance: none;
                  appearance: none;
                  padding: 8px 12px 8px;'
              tag='a'
              @click="dropdownOptions = !dropdownOptions">
              <RocIcon color="black" size="sm" icon="kebab"/>
          </MDBDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
            <MDBDropdownItem href="" @click.prevent>
              <div @click="editClicked()">Edit</div>
            </MDBDropdownItem>
            <hr class='dropdown-divider' v-if="isAdmin"/>
            <MDBDropdownItem href="" @click.prevent v-if="isAdmin" :disabled="camState">
              <div :style='deleteStyle()' @click="isShowingDelete=true; dropdownOptions = !dropdownOptions">Delete</div>
            </MDBDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
    </div>
    <div class='d-flex justify-content-center camera-url'>
      <div class="hide-text">{{ cameraUrl }}</div>
    </div>
  </div>
  <base-dialog v-if="isShowingDelete" :show="true" title="Delete Camera" @close="isShowingDelete=false;" :style="deleteConfirmationStyle">
    <DeleteConfirmation
      @close="isShowingDelete=false"
      @delete="deleteClicked()"
    >
      Are you sure you want to delete camera <span style="color:var(--overwatch-error)">{{cameraName}}</span>? This action cannot be undone.
    </DeleteConfirmation>
  </base-dialog>
</template>

<script>
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useStore } from "vuex";
import BaseDialog from "@/components/ui/BaseDialog";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import RocSwitch from "@/components/ui/RocSwitch";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "../ui/RocDropdownMenu.vue";

export default {
  name: 'CameraView',
  props: {
    cameraName: String,
    cameraUrl: String,
    cameraGuid: String,
    cameraState: Boolean,
    cameraOnline: Boolean,
    preview: String,
    mode: String,
  },
  emits: ['cameraStateChange', 'editCamera'],
  components: {
    MDBDropdown,
    MDBDropdownItem,
    MDBDropdownToggle,
    BaseDialog,
    DeleteConfirmation,
    RocSwitch,
    RocIcon,
    RocDropdownMenu
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(false);
    const procFPS = ref(0);
    const procDropped = ref(0);
    const procTimeDelta  = ref(0);
    const disconnectCount = ref(0);

    const isShowingDelete = ref(false);

    const isAdmin = computed(() => {
      return store.getters['auth/isAdmin'];
    });

    let socket;
    onMounted(async () => {
      socket = await store.dispatch("auth/getSocketIO", `feed=livestream&topic=${props.cameraGuid}`);
      socket.on(props.cameraGuid, (payload) => {
          cameraPreview.value = 'data:image/png;base64, ' + payload.image;
      });

      socket.on('disconnect', async (reason) => {
        camState.value = false;
        activeStyle();
        if (reason === "io server disconnect" || reason === "ping timeout") {
          console.log("socket.io disconnected.");
        }
      });

      socket.on('connect', async (reason) => {
        if(disconnectCount.value > 0) {
          await store.dispatch("cameras/loadCameras");
        }
      });
    });

    let statsSocket;
    onMounted(async () => {
      statsSocket = await store.dispatch("auth/getSocketIO", `feed=livestats&topic=${props.cameraGuid}`);
      statsSocket.on(props.cameraGuid, (payload) => {
          camOnline.value = payload.cameraStats.enabled;
          procFPS.value = payload.cameraStats.fps.toFixed(1);
          const dropPercent = (payload.cameraStats.frames_processed > 0) ? (100 * payload.cameraStats.frames_dropped / (payload.cameraStats.frames_processed + payload.cameraStats.frames_dropped)) : 0;
          if ((payload.cameraStats.frames_dropped < 50) || (dropPercent < 5.0))
            procDropped.value = 0.0; // Round down if we've only dropped a few frames
          else
            procDropped.value = dropPercent.toFixed(1);

          const timeDiscrepancyMinutes = (Date.now() - payload.cameraStats.current_time) / 60000.0;
          if (timeDiscrepancyMinutes < 5)
            procTimeDelta.value = 0.0; // Round down if we're within 5 minutes
          else
            procTimeDelta.value = timeDiscrepancyMinutes.toFixed(1);
      });
    });

    onBeforeUnmount(() => {
      socket.close();
      statsSocket.close();
    });

    const windowWidth = ref(window.innerWidth);

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const cameraPreview = ref(null);
    if(!props.preview) {
      cameraPreview.value = require('@/assets/liveview-placeholder.png');
    } else {
      cameraPreview.src = props.preview;
    }

    const camState = ref(props.cameraState);
    const camOnline = ref(props.cameraOnline);
    const dropdownOptions = ref(false);

    const cameraPreviewSrc = computed(function() {
      if(cameraPreview.src !== props.preview) {
        cameraPreview.src = props.preview;
      }
      return (cameraPreview.value ? cameraPreview.value : cameraPreview.src);
    });

    function editClicked() {
      dropdownOptions.value = false;
      context.emit('editCamera', props.cameraGuid);
    }

    async function deleteClicked() {
      if(camState.value) //camera must be stopped to delete
      {
        console.log("camera must be stopped to delete.");
        return;
      }

      isLoading.value = true;
      try {
        await store.dispatch("cameras/deleteCamera", props.cameraGuid);
      }
      catch(error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
      dropdownOptions.value = false;
    }

    function activeStyle() {
      return camState.value ? "max-width: 446px; max-height: 251px; width: auto; height: auto; overflow: hidden;"
                            : "max-width: 446px; max-height: 251px; width: auto; height: auto; opacity: 0.4; overflow: hidden;";
    }

    async function toggleCamState(refresh = false) {
      isLoading.value = true;
      try {
        const result = await store.dispatch('cameras/toggleCameraState', {
          forceRefresh: refresh,
          GUID: props.cameraGuid,
        });
        if(result && result.status === 'failed') {
          camState.value = false;
        }
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    function deleteStyle() {
      if(camState.value) {
        return "opacity: .4;";
      }
      else {
        return "";
      }
    }

    const deleteConfirmationStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      }
    });

    function missingPreview(e) {
      console.log("missing preview");
      cameraPreview.value = require('@/assets/liveview-placeholder.png');
      camState.value = false;
    }

    function handleSwitchToggle(t) {
      camState.value = t;
      toggleCamState(true);
    }

    return {
      isAdmin,
      cameraPreviewSrc,
      camState,
      camOnline,
      isLoading,
      activeStyle,
      editClicked,
      toggleCamState,
      dropdownOptions,
      deleteClicked,
      deleteStyle,
      procFPS,
      procDropped,
      procTimeDelta,
      isShowingDelete,
      deleteConfirmationStyle,
      missingPreview,
      handleSwitchToggle
    };
  }
};
</script>

<style scoped lang="scss">
.div {
  margin-left: 21px;
}

.loading-spinner {
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 1000;
}

.camera-card {
  box-sizing: border-box;
  height: auto;
  width: 448px;
  border: 1px solid var(--overwatch-neutral-500);
  border-radius: 6px;
  background-color: var(--overwatch-secondary);
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;

  display: flex;
  flex-direction: column;
}

.camera-info {
  flex: 1;
  display: flex;
  width: 100%;
  @include overwatch-body-med;
  color: #000000;
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
  align-items: center;
}

.camera-info-child {
  flex:1;
  display:flex;
  justify-content: center;
  align-items: center;
}

.camera-info-child:first-child {
  align-items: center;
}

.camera-name-field {
  flex: 2;
  text-align: center;
  @include overwatch-body-large;
  color: var(--overwatch-neutral-100);
}


.camera-url {
  font-size: 12px;
  opacity: 0.4;
  color: #000000;
  text-overflow: ellipsis;
  overflow: hidden;
}

.header {
  width: 100%;
  height: 164px;
  background: var(--overwatch-secondary);
  font-size: 24px;
  box-shadow: 0 4px 15px 0 rgba(0, 19, 58, 0.3);
}

.searchRectangle {
  float: left;
  height: 44px;
  width: 300px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  font-size: 14px;
  padding-left: 6px;
  margin-left: 2rem;
}

.addCamera {
  float: right;
  text-transform: unset !important;
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 16px;
  margin-left: 20px;
}

.searchInput {
  border: 0px;
  margin-left: 6px;
  width: 90%;
}

.searchInput:hover {
  border: 0px;
}

input.searchInput:focus {
  outline-width: 0;
}

.lds-roller-white div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white !important;
  margin: -4px 0 0 -4px;
}

.dropdown-toggle:after {
  display: none;
}

.hide-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

div:hover {
  white-space: unset;
  text-overflow: unset;
}

@media (max-width: 480px) {
  .camera-card {
    width: 100%;
    height: auto;
    margin: 0;
  }

  img {
    width: 100%;
    height: auto;
  }
}

</style>
