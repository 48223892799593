export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setCameras(state, payload) {
    state.cameras = payload;
  },
  setCameraState(state, payload) {
    const camIndx = state.cameras.findIndex(
      camera => camera.GUID === payload.GUID
    );
    if (camIndx >= 0) {
      state.cameras[camIndx] = payload;
      state.cameras[camIndx].state = payload.state;
      // re-assign label/value here for the filter multiselect
      state.cameras[camIndx].value = payload.GUID;
      state.cameras[camIndx].label = payload.name;
    }
  },
  addNewCamera(state, payload) {
    state.cameras.push(payload);
  },
  deleteCamera(state, payload) {
    const deleteItemIndex = state.cameras.findIndex(
      camera => camera.GUID === payload
    );
    if (deleteItemIndex >= 0) {
      state.cameras.splice(deleteItemIndex, 1);
    }
  },
  setEncounterFilterSelectedCameras(state, payload) {
    state.encounterFilterSelectedCameras = payload;
  },
  setIsLiveCameraPopupVisible(state, payload) {
    state.isLiveCameraPopupVisible = payload;
  },
};
