<template>
  <div class='container' style="width: 100%; border: 0px solid var(--overwatch-error); overflow-y: auto; height: 100%;">
      <!-- testing css grid -->
    <div class="mygrid hide-scrollbars" style="overflow-x:hidden;"> 
      <div v-if="type==='face_match'" class="face-title">Encountered Face</div>
      <div v-if="type==='face_match'" class="face-title">{{matchObject.firstname}} {{matchObject.lastname}} </div>

      <div v-if="type==='object_lp_match'" class="face-title">Encountered License Plate</div>
      <div v-if="type==='object_lp_match'" class="face-title">{{matchObject.fullname}}</div>

      <div v-if="type==='face_match'" class="testmatch" :style="bgStyle">Match Score <span>{{MatchScore()}}</span></div>
      <div v-if="type==='face_match'" class="face-title">
        <span>from</span> {{matchObject.watchlistName}}
      </div>

      <div v-if="type==='object_lp_match'" class="face-title"></div>
      <div v-if="type==='object_lp_match'" class="face-title">
        <span>from</span> {{matchObject.watchlistName}}
      </div>
      <div class="d-flex justify-content-center face-div">
        <auth-img class="face" :src="matchObject.probe_tn" ></auth-img>
      </div>
      <div class="d-flex justify-content-center face-div right">
        <auth-img v-if="type==='face_match'" class="face" :src="matchObject.candidate_tn"></auth-img>
        <div v-else-if="type==='object_lp_match'" style="position: relative; border: 0px solid var(--overwatch-error); margin-top: 2px; width: 90%">
          <img src="@/assets/img/blank-plate-gray.png" style="width:100%">
          <div class="plate-number center">{{matchObject.plateNumber}}</div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end additionalInfo" v-if="!isMobile">
      <WatchlistedFaceAdditionalInfo :hideTitle="true" :watchlistedFace="watchlistedFace" style="max-width: 45%;"/>
    </div>

    <div class="mobile-only" style="margin-top: 1rem;">
      <h2> CAPTURE CONTEXT</h2>
      <div>
        <auth-img class="context-image" :src="contextImageUrl"/>
      </div>
    </div>

    <div class="mobile-only" v-if="type.includes('match')" style="margin-top: 1rem;">
      <h2> METADATA </h2>
      <div>
        <ul v-if="type==='face_match'">
          <li>Gender: <span> {{matchObject.md.Gender}} </span></li>
          <li>Age: <span>{{matchObject.md.Age}}</span></li>
          <li>Glasses: <span>{{matchObject.md.Glasses}}</span></li>
          <li>Mask: <span>{{matchObject.md.MaskDetection}}</span></li>
          <li>Facial Hair: <span>{{matchObject.md.FacialHair}}</span></li>
        </ul>
        <ul v-if="type==='object_lp_match'">
          <li>Plate OCR: <span> {{matchObject.ocrPlateRead}} </span></li>
          <li>Name: <span>{{matchObject.fullname}}</span></li>
          <li>Color: <span>{{matchObject.color}}</span></li>
          <li>Make: <span>{{matchObject.make}}</span></li>
          <li>Model: <span>{{matchObject.model}}</span></li>
          <li>Year: <span>{{matchObject.year}}</span></li>
        </ul>
      </div>
    </div>
    <WatchlistedFaceAdditionalInfo v-if="isMobile" :hideTitle="false" :watchlistedFace="watchlistedFace" :useTableStyle="isMobile"/>

    <div class="d-flex justify-content-center" style="margin-top: 1rem;">
      <div class="w-100 overwatch-body-med">
        <MDBTextarea class="overwatch-body-small" label="Notes" rows="3" v-model="notes" style="resize: none;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, onMounted } from "vue";
import AuthImg from "@/components/ui/AuthImg";
import { MDBTextarea } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import WatchlistedFaceAdditionalInfo from "@/components/watchlists/WatchlistedFaceAdditionalInfo";

export default {
  name: "WatchlistMatchCard",
  props: {
    matchObject : {
      type: Object,
    }
  },
  components: {
    AuthImg,
    MDBTextarea,
    WatchlistedFaceAdditionalInfo
  },
  setup(props) {
    const isLoading = ref(false);
    const bgStyle = ref(setBackgroundColorByScore());
    const notes = ref(props.matchObject.notes);
    const store = useStore();
    const watchlistedFace = ref(null);
    const identityNotes = ref('');
    const contextImage = ref();

    const type = computed(() => {
      return props.matchObject.objectType;
    });

    const contextImageUrl = computed(()=> {
      // if contextImageMediaId is defined, use that - otherwise query by personId
      const mediaId = props.matchObject.contextImageMediaId ?? props.matchObject.personId;
      return `/rest/v1/camera/contextimage/${props.matchObject.objectType}/${mediaId}/binary`;
    });
    
    onBeforeUnmount(async ()=> {
      if (type.value === 'face_match') {
        const response = await store.dispatch("watchlists/setWatchlistedFaceMatchNotes", {
          id: props.matchObject.id,
          notes: notes.value,
          personId: props.matchObject.personId,
        });
      } else if (type.value === 'object_lp_match') {
        const response = await store.dispatch("watchlists/setWatchlistedLicensePlateMatchNotes", {
          id: props.matchObject.id,
          notes: notes.value,
          personId: props.matchObject.personId
        });
      }
    });

    function MatchScore() {
      if (props.matchObject.similarity != null) {
        return ((props.matchObject.similarity) * 100).toFixed(1);
      }
      else return null;
    }

    function setBackgroundColorByScore() {
      const similarity = MatchScore();
      let bgStyle = 'background: var(--roc-watch-similarity-90);';
      if (similarity) {
        if (similarity < 90 && similarity >= 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70);';
        }
        else if (similarity < 70) {
          bgStyle = 'background: var(--roc-watch-similarity-0);';
        }
      }
      return bgStyle;
    }

    const safariStyle = computed(() => {    
      return {
        height: '100%',
        width: '100%',
        display: 'flex',
        'align-item': 'center'
      }
    })

    const thumbnailStyle = computed(() => {
      // if (type.value === 'object_lp_match') {
      //   return {
      //     'height': '100%'
      //   };
      // }
    });

    onMounted(() => {
      if (type.value === 'face_match') {
        getWatchlistedFaceDetails(props.matchObject.watchlistedFaceId);
      }
    });

    async function getWatchlistedFaceDetails(id) {
      const responseData = await store.dispatch(
        'watchlists/getWatchlistedFace',
        { id: id }
      );
      if (responseData && responseData.status == 'success' && responseData.face) {
        watchlistedFace.value = responseData.face;
      }
    }

    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    return {
      MatchScore,
      setBackgroundColorByScore,
      bgStyle,
      notes,
      isLoading,
      watchlistedFace,
      identityNotes,
      contextImage,
      type,
      contextImageUrl,
      safariStyle,
      thumbnailStyle,
      isMobile
    };
  }
};
</script>

<style scoped lang="scss">
.container {
  display: flex; 
  flex-direction: column;
}
.mygrid {
  display: grid; 
  grid-template-columns: 50% 50%;
  grid-template-rows: minmax(min-content, max-content) minmax(min-content, max-content) minmax(min-content, max-content);
  justify-items: center;
  align-items: center;
  row-gap: 0.5rem;
}

.testmatch {
  font-size: 18px;
  border-radius: 14px;
  border: 1px solid var(--overwatch-neutral-100);
  padding: 0 10px;
  @include overwatch-body-med;
  padding: 4px 12px;
}

.face {
  width: 90%;
  height: auto;
  margin: 0;
  justify-self: center;
}
.face-title {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  letter-spacing: 0;
  text-align: center;
}

.image-quality-score {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-xsmall;
  letter-spacing: 0;
}

.watchlist-match {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
  letter-spacing: 0;
}

.match-score {
  height: 45px;
  width: 45px;
  background-color: var(--overwatch-success); /*EEEFF4*/
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-left: 20px;
  @include overwatch-body-med;
  padding-top: 8px;
  padding-left: 4px;
}

.context-image {
  width: auto;
  height: 300px;
}

h2 {
  @include overwatch-body-med;
  color: gray;
}

ul {
  padding: 0;
  list-style-type: none;
}

li:nth-child(odd) {
  background-color: lightgray;
}

.mobile-only {
  display: none;
}

.hide-scrollbars::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}
    
.hide-scrollbars {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}

.face-div {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.plate-number {
  color: var(--overwatch-neutral-100);
  font-size: 60px;
}

.center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 5px;
}

.additionalInfo {
  padding-right: 2rem;
}

/* IPAD PORTRAIT */ 
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .face {
    width: 90%;
    height: auto;
  }
}

/* MOBILE */
@media (max-width: 480px) {
  .container {
    display: block;
  }

  .mygrid {
    grid-template-rows: revert;
  }
  .mobile-only {
    display: block;
  }
  .face {
    width: 90%;
    height: auto;
  }
  .context-image {
    width: 100%;
    height: auto;
  }

  .container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .container::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }

  .plate-number {
    font-size: 28px;
  }
  .face-title {
    font-size: 14px;
  }
  .testmatch {
    font-size: 14px;
  }

  .additionalInfo {
    padding-right: 0;
  }

}
</style>