<template>
  <div>
    <div>
      <SearchHeader 
        class="stick-to-top" 
        title='Workflows'
        button-title="Add Workflow" 
        @search-filter-change='setSearchFilterText'
        @button-clicked='goToAddWorkflow'
      >
        <template v-slot:leftnav>
          Workflows
        </template>
      </SearchHeader>
      <input type="file" @change="importHandler" accept=".json" ref="fileInput" style="display: none;">
      <div class="load">
        <RocButton @click="triggerImport" :disabled="false">Import</RocButton>
      </div>
      <WorkflowDashboardCard
        v-for="workflow of filteredWorkflows"
        :workflow="workflow"
        :key="workflow._id"
        @show="showDetails(workflow)"
        @edit="editWorkflow(workflow)"
        @delete="showDeleteDialog(workflow)"
      />
    </div>
    <BaseDialog 
      title="Workflow Details"
      v-if='isShowingDetails' 
      show 
      @close="isShowingDetails=false"
    >
      <WorkflowDetail
        :workflow="selectedWorkflow"
      />
    </BaseDialog>
    <BaseDialog 
      title="Delete Workflow"
      v-if="isShowingDelete"
      show
      @close="isShowingDelete=false"
    >
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @delete="deleteWorkflow(deletingWorkflow)"
      >
        Are you sure you want to delete workflow <span style="color:var(--overwatch-error)">{{ deletingWorkflow.name }}</span>? This action cannot be undone.
      </DeleteConfirmation>
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import WorkflowDashboardCard from '@/components/workflows/WorkflowDashboardCard';
import WorkflowDetail from '@/components/workflows/WorkflowDetail';
import BaseCard from '@/components/ui/BaseCard';
import SearchHeader from '@/components/ui/SearchHeader';
import BaseDialog from '@/components/ui/BaseDialog'
import DeleteConfirmation from '@/components/settings/DeleteConfirmation';
import RocButton from '@/components/ui/RocButton.vue';

export default {
  name: 'WorkflowDashboard',
  components: {
    WorkflowDetail,
    WorkflowDashboardCard,
    BaseCard,
    SearchHeader,
    BaseDialog,
    DeleteConfirmation,
    RocButton
  },
  setup(props, context) {
    const fileInput = ref(null);
    const store = useStore();
    const router = useRouter();

    onMounted(async () => {
      await loadWorkflows();
    })

    async function loadWorkflows() {
      await store.dispatch('workflows/loadWorkflows');
    }

    const workflows = computed(() => {
      return store.getters['workflows/workflows'];
    });

    const filteredWorkflows = computed(() => {
      return workflows.value.filter(w => w.name.toLowerCase().includes(searchFilterText.value.toLowerCase()));
    })

    const isShowingAddDialog = ref(false);

    const searchFilterText = ref('');
    function setSearchFilterText(text) {
      searchFilterText.value = text;
    }

    const isShowingDelete = ref(false);
    const deletingWorkflow = ref();
    function showDeleteDialog(workflow) {
      deletingWorkflow.value = workflow;
      isShowingDelete.value = true;
    }

    function deleteWorkflow(workflow) {
      store.dispatch('workflows/deleteWorkflow', workflow);
    }

    const isShowingEditDialog = ref(false);
    
    function editWorkflow(workflow) {
      store.commit('workflows/setEditingWorkflow', workflow);
      router.push({name: 'WorkflowCRUD'});
    }

    function goToAddWorkflow() {
      store.commit('workflows/setEditingWorkflow', null);
      router.push({name: 'WorkflowCRUD'});
    }

    const selectedWorkflow = ref();
    const isShowingDetails = ref(false);
    function showDetails(workflow) {
      selectedWorkflow.value = workflow;
      isShowingDetails.value = true;
    }

    function triggerImport() {
      if (fileInput.value) {
        fileInput.value.click();
      }
    }

    function importHandler(event) {
      const file = event.target.files[0];
      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = async (e) => {
        let data;
        try {
          data = JSON.parse(e.target.result);
          console.log(data);
        } catch (error) {
          console.error("Error parsing JSON:", error);
          // Handle error (e.g., show notification to the user)
        }

        await createWorkflow(data);
      };
      reader.onerror = (error) => console.error("Error reading file:", error);
      reader.readAsText(file);
    }

    async function createWorkflow(payload) {
      try {
        const response = await store.dispatch('workflows/createWorkflow', payload);
        if (response.status === 'success') {
          console.log('Workflow saved successfully.')
        } else {
          console.log('Failed to save workflow.')
          console.log(response);
        }
      }

      catch (error) {
        console.log('Failed to import workflow to server. Check format');
        console.error(error);
      }
    }

    return {
      workflows,
      filteredWorkflows,
      isShowingAddDialog,
      setSearchFilterText,
      deleteWorkflow,
      editWorkflow,
      isShowingEditDialog,
      goToAddWorkflow,
      showDetails,
      isShowingDetails,
      selectedWorkflow,
      isShowingDelete,
      deletingWorkflow,
      showDeleteDialog,
      fileInput,
      triggerImport,
      importHandler
    }
  }
}
</script>

<style scoped>
.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.load {
  position: relative;
  padding: 0;
  margin: 12px auto 12px auto;
  width: 90%;

  display: flex;
  align-items: center;
}
</style>