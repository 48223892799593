<template>
  <div class="live-camera-feed-container">
    <div :style="darkMode ? 'background: var(--overwatch-secondary)' : 'background: var(--overwatch-neutral-100)'"
     :class="routeName.includes('/livefeedpopup') ? 'feed-card-popup' : 'feed-card'">
      <div class="inner-boundary">
        <div class="d-flex justify-content-between">
          <div>
            <div class="live-camera-feeds disable-select" @click="toggleState">
              Live Camera Feeds
              <RocIcon
              v-if="!routeName.includes('/livefeedpopup')"
              icon="downArrow"
              size="sm"
              :color="darkMode ? 'black' : 'white'"
              style="margin-left: 20px;"
              :style="{transform: state === 'collapsed' ? 'rotate(0deg)' : 'rotate(180deg)'}"/>
            </div>
          </div>
          <div v-if="!routeName.includes('/livefeedpopup') && !isMobile" class="camera-popout-icon-container" @click="showLiveCamPopup()">
              <RocIcon :color="darkMode ? 'black' : 'white'" size="sm" icon="outsideLink" />
          </div>
          <div v-if="state === 'expanded'" class="d-flex align-items-center fade-in">
            <div style="float: left; margin-left: 10px;">
              <div class='align-self-center'
                   style='margin-left: 1rem; width: 3rem; align-content: start;'>
                <div class='encounter-heading'>
                  <MDBDropdown v-model='dropOptions' align='end'>
                    <MDBDropdownToggle
                      style='color:black;
                      margin-bottom: 5px;
                      -webkit-appearance: none;
                      -moz-appearance: none;
                      appearance: none;'
                      tag='div'
                      @click='dropOptions = !dropOptions'>
                      <!-- bc of the background colors need to be double flip flopped for light/dark mode -->
                      <RocIcon :color="darkMode ? 'black' : 'white'" size="md" icon="settings" />
                    </MDBDropdownToggle>
                    <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
                      <MDBDropdownItem href="#" @click.prevent>
                        <div style="width: 300px; height: 150px;">
                          <filter-multi-select
                            v-if="displayType === 'grid'"
                            @click.stop="dropOptions = true"
                            no-results-text=""
                            placeholder-text="select cameras"
                            :available-items="cameras"
                            :currently-selected="selectedCameraGUIDs"
                            @selection-changed="updateSelectedLiveCameras"
                          />
                          <div class="select-rectangle" v-if="displayType === 'single'">
                            <filterSingleSelect
                              :availableItems="cameras"
                              :currentlySelected="selectedSingleCameraGUID"
                              valueProp="GUID"
                              @selection-changed="updatePrimarySelectedCamera"
                              placeholder-text="select camera"
                              no-results-text=""
                            />
                          </div>
                        </div>
                      </MDBDropdownItem>
                    </RocDropdownMenu>
                  </MDBDropdown>
                </div>
              </div>
            </div>
            <div class="pill" style="float: right;">
              <div :class="gridState" @click="setDisplayState('grid')">
                <RocIcon size="md" :color="darkMode ? 'black' : 'white'" icon="multiView" :style="getGridStyle()"></RocIcon>
              </div>
              <div :class="singleState"  @click="setDisplayState('single')">
                <RocIcon size="md" :color="darkMode ? 'black' : 'white'" icon="singleView" :style="getSingleStyle()"></RocIcon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="state === 'expanded'" class="video-border">
        <div v-if="displayType === 'grid'" class="d-flex flex-wrap" :style="{ 'justify-content': routeName.includes('/livefeedpopup') ? 'center' : 'start' }">
          <LiveCameraContainer v-for='camera in selectedCameras' :key='camera.GUID'
                       :camera-guid='camera.GUID'
                       :camera-name='camera.name'
                       :camera-state='camera.state'
                       :camera-online='camera.online'
                       :preview="camera.previewImage"
                       :camera-url='camera.url'
                       @camera-dclicked="handleGridCameraDoubleClicked"
                       :width="498"
                       :height="279"></LiveCameraContainer>
        </div>
        <div v-else-if="displayType === 'single' && selectedSingleCamera" class="d-flex" :style="{ 'justify-content': routeName.includes('/livefeedpopup') ? 'center' : 'start' }">
          <LiveCameraContainer :camera-guid='selectedSingleCamera.GUID'
                               :camera-name='selectedSingleCamera.name'
                               :camera-state='selectedSingleCamera.state'
                               :camera-online='selectedSingleCamera.online'
                               :preview="selectedSingleCamera.previewImage"
                               :camera-url='selectedSingleCamera.url'
                               :width="998"
                               :height="570"
                               :is-single="true"
                               @camera-dclicked="handleSingleCameraDoubleClicked"
                               :key="selectedSingleCamera.GUID">
          </LiveCameraContainer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref, watch, onMounted } from "vue";
import { MDBDropdown, MDBDropdownItem, MDBDropdownToggle } from "mdb-vue-ui-kit";
import LiveCameraContainer from "@/components/cameras/LiveCameraContainer";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import filterSingleSelect from "@/components/ui/filterSingleSelect";
import RocIcon from "@/components/ui/RocIcon";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu";

export default {
  name: "CameraLiveFeed",
  components: {
    LiveCameraContainer,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    filterMultiSelect,
    filterSingleSelect,
    RocIcon,
    RocDropdownMenu
  },
  emits: ['close-popup'],
  props: {
    closePopup: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const store = useStore();
    const router = useRouter();

    const state = ref(store.getters["encounters/cameraLiveFeedState"]);
    if(state.value === 'expanded') {
      expand();
    }
    else if(state.value === 'collapsed') {
      collapse();
    }

    const gridState = ref('left-pill-selected');
    const singleState = ref('right-pill-unselected');
    const displayType = ref(store.getters["encounters/cameraLiveFeedMode"]);
    if(displayType.value === 'single') {
      gridState.value = 'left-pill-unselected';
      singleState.value = 'right-pill-selected';
    }

    const routeName = ref(router.currentRoute.value.path);
    //watch router for changes
    watch(() => router.currentRoute, (to, from) => {
      routeName.value = to.path;
      if(routeName.value.includes('/livefeedpopup')) {
        expand();
      }
    });

    const childWindow = ref(null);
    function showLiveCamPopup() {
      store.commit("cameras/setIsLiveCameraPopupVisible", true);

      //called when router changes. needs to be called twice for some reason
      expand();
      
      //open new window w new route
      childWindow.value = window.open('/livefeedpopup', 'Live Camera Popup', 'width=1200,height=700,left=300,top=200, resizable=yes');

      //Add an event listener to the parent window so when it closes it will close the child window
      window.addEventListener('beforeunload', closeChildWindow);

      // Listen for messages from the child window
      window.addEventListener('message', (event) => {
        // Check if the message indicates that the child window has been closed
        if (event.data === 'childWindowClosed') {
          store.commit("cameras/setIsLiveCameraPopupVisible", false);
        }
      });
    }

    function closeChildWindow() {
      // Check if the child window is open before trying to close it
      if (childWindow.value && !childWindow.value.closed) {
          store.commit("cameras/setIsLiveCameraPopupVisible", false);
          childWindow.value.close();
      }
    }

    watch(() => props.closePopup, newVal => {
      context.emit('close-popup');
    });

    const isLiveCamPopupVisible = computed(() => {
      return store.getters["cameras/isLiveCameraPopupVisible"];
    });

    const windowWidth = ref(window.innerWidth);

    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const isLoading = ref(false);
    const dropOptions = ref(false);
    const selectedCameraGUIDs = ref(store.getters["encounters/liveFeedCameras"]); //for Grid View
    const selectedSingleCamera = ref();
    const selectedSingleCameraGUID = ref();

    onMounted(() => {
      // on initial load with no live cameras selected, selectedSingleCameraGUID will be undefined,
      // which is probably fine since we have none selected
      if(getActiveLiveFeeds()[0]) {
        selectedSingleCameraGUID.value = getActiveLiveFeeds()[0].GUID;
      } // for Single View
      else {
        selectedSingleCameraGUID.value = selectedCameraGUIDs.value[0];
      }
    })

    function updatePrimarySelectedCamera(c) {
      selectedSingleCameraGUID.value = c;
    }

    watch(selectedSingleCameraGUID, nv => {
      let cameraList = store.getters['cameras/cameras'];

      const index = cameraList.findIndex(cam => cam.GUID === nv);

      selectedSingleCamera.value = cameraList[index];
    });

    function toggleState() {
      if(state.value === 'collapsed') {
        expand();
      }
      else if(state.value === 'expanded') {
        collapse();
      }
    }

    function expand() {
      state.value = 'expanded';
      store.commit("encounters/setCameraLiveFeedState", state.value);
      store.commit('auth/setUserSettingsChanged', Date.now())
    }

    function collapse() {
      state.value = 'collapsed';
      store.commit("encounters/setCameraLiveFeedState", state.value);
      store.commit('auth/setUserSettingsChanged', Date.now())
    }

    loadCameras();
    async function loadCameras(refresh = false) {
      isLoading.value = true;
      try {
        await store.dispatch('cameras/loadCameras', {
          forceRefresh: refresh
        });

      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    const hasCameras = computed(function() {
      return !isLoading.value && store.getters['cameras/cameras'];
    });

    function setDisplayState(state) {
      if(state === 'grid') {
        displayType.value = 'grid';
        gridState.value = 'left-pill-selected';
        singleState.value = 'right-pill-unselected';
      }
      else if(state === 'single') {
        displayType.value = 'single'
        gridState.value = 'left-pill-unselected';
        singleState.value = 'right-pill-selected';
      }
      store.commit("encounters/setCameraLiveFeedMode", state);
      store.commit('auth/setUserSettingsChanged', Date.now())
    }

    function getIconState() {
      if(state.value === 'collapsed') {
        return 'fas fa-chevron-down';
      }
      else {
        return 'fas fa-chevron-up';
      }
    }

    function getGridStyle() {
      if(displayType.value === 'grid') {
        return 'margin-left: 4px; color: var(--overwatch-button-text) !important; border-color: var(--overwatch-accent);';
      }
      else {
        return 'margin-left: 22px; color: var(--overwatch-button-text) !important; border-color: var(--overwatch-accent);';
      }
    }

    function getSingleStyle() {
      if(displayType.value === 'single') {
        return 'margin-left: 8px; color: var(--overwatch-button-text) !important; border-color: var(--overwatch-accent)';
      }
      else {
        return 'margin-left: 8px; color: var(--overwatch-button-text) !important; border-color: var(--overwatch-accent)';
      }
    }

    const selectedCameras = computed(function() {
      return getActiveLiveFeeds();
    });

    function getActiveLiveFeeds() {
      let cameraList = store.getters['cameras/cameras'];
      let activeCameraFeedList = [];
      selectedCameraGUIDs.value.forEach((selectedCam) => {
        const index = cameraList.findIndex(cam => cam.GUID === selectedCam);
        if(index >= 0) {
          if(cameraList[index].state === true) {
            activeCameraFeedList.push(cameraList[index]);
          }
        }
      });

      return activeCameraFeedList;
    }

    const cameras = computed(function() {
      let cameraList = store.getters['cameras/cameras']; 
      cameraList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if(nameA < nameB) {
          return -1
        }
        else if(nameA > nameB) {
          return 1;
        }

        return 0;
      });

      if(cameraList.length) {
        return cameraList.filter((camera) => {
          if(camera.state === true) {
            return true;
          }
        })
      }
      else return cameraList;
    });

    function updateSelectedLiveCameras(selected) {
      selectedCameraGUIDs.value = selected.value;
      selectedSingleCameraGUID.value = selectedCameraGUIDs.value[0];
      store.commit("encounters/setLiveFeedCameras", selected.value);
      store.commit('auth/setUserSettingsChanged', Date.now())
    }

    function handleGridCameraDoubleClicked(GUID) {
      setDisplayState('single');
      selectedSingleCameraGUID.value = GUID;
    }

    function handleSingleCameraDoubleClicked() {
      setDisplayState('grid');
    }

    const darkMode = computed(() => { return store.getters['settings/getDarkMode']; });

    return {
      toggleState,
      getIconState,
      setDisplayState,
      getGridStyle,
      getSingleStyle,
      gridState,
      singleState,
      state,
      loadCameras,
      hasCameras,
      isLoading,
      cameras,
      dropOptions,
      selectedCameraGUIDs,
      updateSelectedLiveCameras,
      selectedCameras,
      displayType,
      handleGridCameraDoubleClicked,
      handleSingleCameraDoubleClicked,
      selectedSingleCameraGUID,
      selectedSingleCamera,
      updatePrimarySelectedCamera,
      showLiveCamPopup,
      isLiveCamPopupVisible,
      routeName,
      isMobile,
      darkMode
    };
  }
};
</script>

<style scoped lang="scss">

.live-camera-feed-container{
  display: flex; 
  padding: 0 !important; 
  margin: 0 !important;
}

.feed-card {
  height: fit-content;
  width: 100% !important;
  display: block;
  // margin-bottom: 3px !important;
  border-radius: 0px 0px 0px 0px !important;
  padding: 4px !important;
  margin: 0;
  border-bottom: 1px solid var(--overwatch-neutral-300);
  margin-right: 0rem !important;
  margin-left: 0px;
}

.feed-card-popup{
  position: absolute; //this didn't exist before, just fyi if issues arise with positioning
  height: 100%; //this used to be `fit-content` just fyi if issues arise
  width: 100% !important;
  display: block;
  margin-bottom: 3px !important;
  border-radius: 0px 0px 0px 0px !important;
  padding: 4px !important;
  margin: 0;
  background: #000 !important;
  border: 1px solid var(--overwatch-neutral-300) !important;
  margin-right: 0rem !important;
  margin-left: 0px;
}

.live-camera-feeds {
  height: 28px;
  color: var(--overwatch-light-secondary);
  @include overwatch-title-med;
  letter-spacing: 0;
  line-height: 30px;
}

.camera-popout-icon-container{
  display: flex; 
  margin-right: auto;
  margin-top: 7px;
  margin-left: 30px;
  align-items: center; 
  justify-content: center; 
  height: 16px; 
  width: 16px;
  cursor: pointer;
}

.inner-boundary {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 3px;
}

.mask {
  box-sizing: border-box;
  height: 34px;
  width: 126px;
  border: 1px solid var(--overwatch-accent);
  border-radius: 20px;

}

.pill {
  width: 126px;
  height: 32px;
  color: var(--overwatch-button-text);
  border-radius: 20px;
  display: flex;
}

.left-pill-selected {
  height: 30px;
  width: 63px;
  transform: scaleX(-1);
  background-color: var(--overwatch-primary);
  border-radius: 0px 20px 20px 0;
  float: left;
  align-items: center;
  justify-content: center;
  display: flex;
}

.left-pill-unselected {
  height: 30px;
  width: 63px;
  transform: scaleX(-1);
  background-color: var(--overwatch-neutral-200);
  border-radius: 0px 20px 20px 0;
  float: left;
  align-items: center;
  display: flex;
}

.right-pill-selected {
  height: 30px;
  width: 63px;
  transform: scaleX(-1);
  background-color: var(--overwatch-primary);
  border-radius: 20px 0px 0px 20px;
  float: right;
  align-items: center;
  justify-content: center;
  display: flex;
}

.right-pill-unselected {
  height: 30px;
  width: 63px;
  transform: scaleX(-1);
  background-color: var(--overwatch-neutral-200);
  border-radius: 20px 0px 0px 20px;
  float: right;
  align-items: center;
  justify-content: center;
  display: flex;
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.video-border {
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 15px;
  margin-bottom: 15px;

  overflow-y: auto;
  overscroll-behavior: contain;
}
.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: .5s;
}

::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

.select-rectangle {
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  display: flex;
  align-items: center;
  padding: var(--spacing-base);
  gap: var(--spacing-base);
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .video-border {
    min-width: 0;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
