<template>
    <div class="description">
      <h2>{{ title }}</h2>
      <slot></slot>
    </div>

    <div class="d-flex justify-content-center" style="gap: var(--spacing-s)">
      <RocButton  @click="noButton()" type="secondary" size="dialogButton">No</RocButton>
      <RocButton  @click="yesButton()" type="primary" size="dialogButton">Yes</RocButton>
    </div>
  </template>

  <script>
  import RocButton from "@/components/ui/RocButton.vue";

  export default {
    props: {
      title: String,
      message: String,
    },
    emits: ['yes', 'no', 'close'],
    components: {
      RocButton
    },
    setup(props, context) {

      function yesButton() {
        context.emit('yes');
        context.emit('close');
      }

      function noButton() {
        context.emit('no');
        context.emit('close');
      }

      return {
        yesButton,
        noButton
      }
    }
  }
  </script>

  <style scoped lang="scss">
  * {
    box-sizing: border-box;
  }

  .ButtonSecondaryDefault {
  width: 115px;
  height: 45px;
  gap: 10px;
  margin: 20px 0px 10px 30px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-neutral-300);
}
.ButtonPrimaryNormal {
  width: 115px;
  height: 45px;
  gap: 10px;
  margin: 20px 20px 0px 10px;
  padding: 12px;
  object-fit: contain;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: var(--overwatch-button-primary);
}


.ButtonText {
  height: 21px;
  flex-grow: 1;
  @include overwatch-body-small;
  text-align: center;
  color: var(--overwatch-button-text);
}

  .description {
    @include overwatch-body-med;
  }

  @media (max-width: 480px) {
    .buttons {
      display: flex;
    }
    .buttons button {
      width: 0;
      flex: 1;
    }
  }

  </style>