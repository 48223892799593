<template>
  <div class="d-flex flex-row">
    <audio preload ref="AlarmAudio">
      <source src="@/assets/audio/alert.mp3" type="audio/mpeg" />
    </audio>
    <div class="d-flex flex-row" style="width: 100%;">
      <div class="dashboard-body">
        <teleport to='#subHeader'>
          <CameraLiveFeed class="camera-live-feed" v-if="liveFeedEnabled && !isCaseSelected && isAnyCameraEnabled && !isLiveCamPopupVisible"></CameraLiveFeed>
        </teleport>
        <div v-if="noSearchResults || noTextSearchResults" class="d-flex flex-wrap justify-content-center no-data-placeholder"
          style="border: 0px solid black;">
          <div style="width: 100%; margin-bottom: 40px;">
            <RocLogo/>
          </div>
          <div>No Search Results</div>
        </div>
        <div v-else-if="noEncountersFound && !isLoading"
          class="d-flex flex-wrap justify-content-center no-data-placeholder" style="border: 0px solid black;">
          <div style="width: 100%; margin-bottom: 40px;">
            <RocLogo />
          </div>
          <div v-if="!matchesOnly">
            <div style="width: 100%">No Encounters Found</div>
            <div class="no-data-description">
              There are no encounters to display, try adjusting your search filters.
            </div>
          </div>
          <div v-else>
            <div style="width: 100%">No Matches Found</div>
            <div class="no-data-description">
              There are no matches to display, try adjusting your search filters.
            </div>
          </div>
        </div>

        <div v-if="filteredEncounters.length" class="encounters-top-bar">
          <div class="encounters-top-bar-title">{{encountersLabel}} {{ selectedCaseName }}</div>
          <div :style="isMobile ? 'display: flex; flex-direction: column; align-items: end;' : 'display: flex; align-items: center;'">
            <span class="modality-color-index" @click="isShowingModalityColorIndex = true">Modality Color Index</span>
            <RocButton type="secondary" v-if="!filterActive" class="expand-filter-btn" @click="setFilterState('true')">
              <div class="d-flex justify-content-space-evenly align-items-center">
                <RocIcon color="white" size="sm" icon="filter"/>
                <span style="margin-left: 0.5rem;">Filters</span>
              </div>
            </RocButton>
          </div>
        </div>
        <InfiniteScroll 
          class="infinite-scroll" 
          :items="filteredEncounters" 
          @refetch="loadEncounters" 
          mode="nopage"
          :is-more-data="moreData" 
          :enabled="scrollEnabled"
        >
          <template v-slot:item="{ item }" >
            <encounter-card 
              v-if="!isEncounterSuppressed(item)" 
              :key="item.id"
              :camera-name="item.cameraName"
              :candidate-thumbnail="item.candidate_tn"
              :firstname="item.firstname"
              :is-new="item.isNew"
              :label="item.label"
              :lastname="item.lastname"
              :object-type="item.objectType"
              :probe-thumbnail="item.probe_tn"
              :similarity="item.similarity"
              :template-id="item.templateId"
              :time="item.timestamp"
              :watchlist-name="item.watchlistName"
              :watchlist-id="item._watchlistId"
              :update-count="item.isNewUpdateCount"
              :person-id="item.personId"
              :md="item.md"
              :attributes="item.attributes"
              :raw-object="item"
              @show-track-details="setTrackAnalysis"
              @enroll-face="enrollFace"
              @alarm-clicked="matchClicked"
              @show-candidate-details="getWatchlistedImageDetails"
              @show-filter="setFilterState('true')"
              @enroll-lpr="enrollLPR"
            />
          </template>
        </InfiniteScroll>
        <div v-if="isLoading" style="justify-self: center; align-self: center; margin: var(--spacing-s) ">
          <roc-spinner/>
        </div>
      </div>
      <transition mode="out-in" name="filter">
        <div v-if="filterActive || keepFilterOpen" class="encounter-filter-holder">
          <encounter-filter filterState="filterActive"
            @change-filter="!keepFilterOpen ? setFilterState() : null" @reverse-search="reverseSearch" @face-filter-remove="faceFilterRemove()"
            @search-text-change="setTextSearchFilter" @alerts-only-change="setAlertsOnlyState"
            @time-filter-change="setTimeFilter" @clear="clearTimeFilter" @camera-filter-change="cameraFilterChange"
            @watchlist-filter-change="watchlistFilterChange" @tags-filter-change="tagsFilterChange"
            @analytics-filter-change="analyticsFilterChange"
            @adjudication-filter-change="adjFilterChange" @case-change="caseChange">
          </encounter-filter>
        </div>
      </transition>
    </div>
    <base-dialog :show="!!error" title="An error occurred!" @close="handleError">
      <p>{{ error }}</p>
    </base-dialog>
    <base-dialog :show="matchPopup" :title="getTitleEncounterDialog(lastSelectedMatch)" @close="matchPopup = false" :style="watchlistMatchStyle">
      <WatchlistMatchCard :matchObject="lastSelectedMatch"></WatchlistMatchCard>
    </base-dialog>
    <base-dialog :show="enrollEncounter" title="Enroll to Watchlist(s)" @close="enrollEncounter = false" :style="enrollEncounterStyle">
      <EnrollEncounter mode="enroll" :faceThumbnail="enrollmentInfo.thumbnail" :enrollFacePersonId="enrollmentInfo.personId" 
        :firstName="enrollmentInfo.firstName" :lastName="enrollmentInfo.lastName" :templateId="enrollmentInfo.templateId"/>
    </base-dialog>
    <base-dialog :show="enrollLicensePlate" title="Enroll to Watchlist(s)" @close="enrollLicensePlate = false" :style="enrollEncounterLPRStyle">
      <EnrollEncounter mode="enroll" :faceThumbnail="enrollmentInfoLPR.thumbnail" :elementWatchlistType="enrollmentInfoLPR.watchlistType" 
        :lprText="enrollmentInfoLPR.text" :lprState="enrollmentInfoLPR.state"/>
    </base-dialog>
    <base-dialog 
      :show="showWatchlistedImageDetails" 
      :title="watchlistedImageToExpand?.objectType === 'face_match' ? 'Face Candidate' : 'Candidate'" 
      @close="showWatchlistedImageDetails = false" 
      :style="enrollEncounterStyle"
    >
      <watchlisted-image-details :read-only="true" :watchlisted-face-id="watchlistedImageToExpand._id"
        :md="watchlistedImageToExpand.md" :first-name="watchlistedImageToExpand.firstname"
        :last-name="watchlistedImageToExpand.lastname" :notes="watchlistedImageToExpand.notes"
        :image-path="watchlistedImageToExpand.tn" :rawObject="watchlistedImageToExpand">
      </watchlisted-image-details>
    </base-dialog>
    <base-dialog :show="isShowingModalityColorIndex" title="Modality Color Index" @close="isShowingModalityColorIndex = false" :style="modalityColorIndexStyle">
      <ModalityColorIndex />
    </base-dialog>
  </div>
</template>

<script>
import EncounterFilter from "@/components/encounters/EncounterFilter.vue";
import InfiniteScroll from "@/components/ui/InfiniteScroll";
import EncounterCard from "@/components/encounters/EncounterCard";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import EnrollEncounter from "@/components/watchlists/EnrollEncounter";
import CameraLiveFeed from "@/components/cameras/CameraLiveFeed";
import WatchlistMatchCard from "@/components/encounters/WatchlistMatchCard";
import WatchlistedImageDetails from "@/components/watchlists/WatchlistedImageDetails";
import { Capacitor } from "@capacitor/core";
import { debounce } from "lodash";
import { get as lodashGet } from "lodash";
import RocLogo from "@/components/ui/RocLogo.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import ModalityColorIndex from "@/components/encounters/ModalityColorIndex.vue";
import RocButton from "@/components/ui/RocButton.vue";

export default {
  components: {
    WatchlistMatchCard,
    CameraLiveFeed,
    EnrollEncounter,
    EncounterFilter,
    EncounterCard,
    InfiniteScroll,
    WatchlistedImageDetails,
    RocIcon,
    ModalityColorIndex,
    RocLogo,
    RocButton
  },
  setup() {
    let socketBestshots = null;
    let socketAlarms = null;
    let socketAddons = null;
    const store = useStore();
    store.commit('encounters/setEncounters', []);
    store.commit('encounters/setCursor', null);
    //TODO - setting null only if store values are blank
    //store.commit('encounters/setEncounterDateRangeFilter', null);
    const isLoading = ref(false);
    const isInitializing = ref(false);
    const error = ref(null);
    //const onlyAlerts = ref(false);
    //const moreData = ref(store.getters['encounters/hasMoreData']);
    const scrollEnabled = ref(true);
    const showTrackAnalysis = ref(false);
    const trackAnalysisPersonId = ref(null);
    const enrollEncounter = ref(false);
    const enrollmentInfo = ref(null);
    const liveFeedEnabled = computed({
      get: () => {
        return store.getters['encounters/liveFeedEnabled'];
      },
      set: (value) => {
        store.commit('encounters/setLiveFeedEnabled', value);
      }
    });
    const AlarmAudio = ref(null);
    const noSearchResults = ref(false);
    const noEncountersFound = ref(false);
    const matchPopup = ref(false);
    const lastSelectedMatch = ref(null);

    const showWatchlistedImageDetails = ref(false);
    const watchlistedImageToExpand = ref(null);

    const windowWidth = ref(window.innerWidth);

    const enrollLicensePlate = ref(false);
    const enrollmentInfoLPR = ref(null);


    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const isLiveCamPopupVisible = computed(() => {
      return store.getters['cameras/isLiveCameraPopupVisible'];
    });
      
    onMounted(async () => {
      isLoading.value = true;
      isInitializing.value = true;
      // if we're mounting the encounters list, we're authenticated and showing ROC Watch Web
      // load some UX and config stuff here 
      store.dispatch('settings/getFaceTaggingEnabled');
      // TODO could these queries be slow?
      await store.dispatch('encounters/getAllAttributes', { type: 'face' });
      await store.dispatch('encounters/getAllAttributes', { type: 'object' });
      

      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });

      const deviceInfo = store.getters['settings/getDeviceInfo']['model'];
      const platform = Capacitor.getPlatform();

      await initData();
      
      isInitializing.value = false;
      // trigger initial load
      reloadEncounters();
    });

    /**
     * Check if any filters are set that disable live feed - date, tags, and reverse search
     */
    function isOnlyLiveFilters() {
      const shouldEnableLiveFeed =
        !hasDateTimeFilter() &&
        !hasReverseSearchFilter() &&
        !hasAttributeTagsFilter() &&
        !hasAnalyticsFilter();
      return shouldEnableLiveFeed;
    }

    async function initData() {
      await store.dispatch('cameras/loadCameras');
      const selected = store.getters['cameras/encounterFilterSelectedCameras'];
      if (!selected.length && !selectedCase.value) {
        const fetchCameras = store.getters['cameras/cameras'];
        fetchCameras.forEach(cam => {
          selected.push(cam.GUID);
        });
        store.commit(
          'cameras/setEncounterFilterSelectedCameras',
          selected
        );
      }
      await loadWatchlists();
      // await store.dispatch("cases/loadCases");
    }

    function hasAttributeTagsFilter() {
      let attributeTagsFilter = store.getters["encounters/attributesFilter"];
      if (!attributeTagsFilter || attributeTagsFilter.length === 0) {
        return false;
      }

      // we support multiple tag groups and wont be sure if some are empty and some arent
      for (let i=0; i < attributeTagsFilter.length; i++) {
        if (lodashGet(attributeTagsFilter[i], 'selectedTags', []).length > 0) {
          // we only need to find one non-empty group to determine that we do have an attribute tags filter in place
          return true;
        }
      }
      return false;
    }

    function hasDateTimeFilter() {
      const dtFilter = store.getters['encounters/getDTFilter'];
      if (!dtFilter) {
        return false;
      }
      if (!dtFilter.start && !dtFilter.end) {
        return false;
      }
      return true;
    }

    function hasAnalyticsFilter() {
      return store.getters['encounters/analyticsFilter'].length > 0;
    }

    const matchesOnly = computed(function () {
      return store.getters['encounters/matchesOnly'];
    });

    async function loadWatchlists() {
      await store.dispatch('watchlists/loadWatchlists');
      const selected =
        store.getters['watchlists/encounterFilterSelectedWatchlists'];
      if (!selected || !selected.length) {
        const fetchWatchlist = store.getters['watchlists/watchlists'];
        fetchWatchlist.forEach(watchlist => {
          selected.push(watchlist._id);
        });
        store.commit(
          'watchlists/setEncounterFilterSelectedWatchlists',
          selected
        );
      }
    };

    const moreData = computed(function () {
      return store.getters['encounters/hasMoreData'];
    });

    const searchTextFilter = ref('');

    async function wireSocketIOBestshots() {
      if (!socketBestshots) {
        const encodedUriComp = encodeURIComponent(
          JSON.stringify(
            store.getters['cameras/encounterFilterSelectedCameras']
          )
        );
        const payload = `feed=bestshots&cameras=${encodedUriComp}`;
        socketBestshots = await store.dispatch('auth/getSocketIO', payload);
        socketBestshots.on('bestshots', async payload => {
          await store.dispatch('auth/rawFetch', {
            url: payload.probe_tn,
            payload: { method: 'GET' }
          });
          store.commit('encounters/upsertEvent', payload);
          noEncountersFound.value = false;
        });

        socketBestshots.on('merge_track', async payload => {
          store.commit('encounters/deleteEncounter', payload.personId);
        });
        /*socketBestshots.on('append_video', async (payload) => {
          store.commit('encounters/bulkUpdateEncounter', payload);
        })*/
      }
    }

    async function wireSocketIOAlarms() {
      if (!socketAlarms) {
        const encodedUriComp = encodeURIComponent(
          JSON.stringify(
            store.getters['cameras/encounterFilterSelectedCameras']
          )
        );
        const payload = `feed=facematch&cameras=${encodedUriComp}`;
        socketAlarms = await store.dispatch('auth/getSocketIO', payload);
        socketAlarms.on('facematches', async payload => {
          store.commit('encounters/upsertAlarm', payload);
          const audibleAlarmEnabled = await store.dispatch(
            'watchlists/isAudibleAlarmEnabled',
            payload
          );
          if (audibleAlarmEnabled && !isEncounterSuppressed(payload)) {
            playAlarm();
          }
          noEncountersFound.value = false;
        });
      }
    }

    async function wireSocketIOAddons() {
      if (!socketAddons) {
        const encodedUriComp = encodeURIComponent(
          JSON.stringify(
            store.getters['cameras/encounterFilterSelectedCameras']
          )
        );
        const payload = `feed=additionalCameraInfo&cameras=${encodedUriComp}`;
        socketAddons = await store.dispatch('auth/getSocketIO', payload);

        socketAddons.on('append_video', async payload => {
          store.commit('encounters/bulkUpdateEncounter', payload);
        });

        socketAddons.on('update_track', async payload => {
          store.commit('encounters/updateEncounter', payload);
        });
      }
    }

    const filterActive = ref(store.getters['encounters/encounterFilterState']);

    function setFilterState(state) {
      store.commit('encounters/setEncounterFilterState', state);
      filterActive.value = state;
    }

    function setTextSearchFilter(searchStr) {
      searchTextFilter.value = searchStr;
    }

    function updateStore() {
      store.commit('encounters/setEncounterZeroTrue');
    }

    function addElement() {
      store.commit('encounters/addNewEvent');
    }

    const hasEncounters = computed(function () {
      return !isLoading.value && store.getters['encounters/encounters'];
    });

    const filteredEncounters = computed(function () {
      return searchTextFilter.value != ''
        ? store.getters['encounters/encounters'].filter(encounter => {
          let plateNumber;
          try {
            if (encounter.objectType === 'face_match' || encounter.objectType === 'object_match') {
              const fullname = encounter.firstname + ' ' + encounter.lastname;
              if (
                fullname &&
                searchTextFilter.value &&
                fullname
                  .toLowerCase()
                  .includes(searchTextFilter.value.toLowerCase())
              ) {
                return true;
              }
            }
            if (encounter.objectType === 'object_lp_match') {
              plateNumber = String(encounter.plateNumber);
              if (
                plateNumber &&
                searchTextFilter.value &&
                plateNumber
                  .toLowerCase()
                  .includes(searchTextFilter.value.toLowerCase())
              ) {
                return true;
              }
            }
            if (encounter.objectType === 'object') {
              if (encounter.md && encounter.md.Text) {
                plateNumber = String(encounter.md.Text);
                if (
                  plateNumber &&
                  searchTextFilter.value &&
                  plateNumber
                    .toLowerCase()
                    .includes(searchTextFilter.value.toLowerCase())
                ) {
                  return true;
                }
              }
            }
          } catch (err) {
            console.log('error in search filter. ', err);
            console.log('plate: ', plateNumber);
            console.log('searchFilter: ', searchTextFilter.value);
            console.log('encounter: ');
            console.log(encounter);
          }
          return false;
        })
        : store.getters['encounters/encounters'];
    });

    const keepFilterOpen = computed(() => {
      return noEncountersFound.value || !filteredEncounters.value.length;
    });

    async function loadEncounters(payload) {
      isLoading.value = true;
      let isAppend = false;
      if (payload) {
        isAppend = payload.isAppend ? true : false;
      }
      try {
        const hasResults = await store.dispatch('encounters/loadEncounters', {
          isAppend: isAppend
        });

        if (!hasResults && filteredEncounters.value.length === 0) {
          noEncountersFound.value = true;
        } else {
          noEncountersFound.value = false;
        }
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
      if (payload) {
        payload.status ? (payload.status.value = false) : 0;
      }
    }

    function handleError() {
      error.value = null;
    }

    function disconnectAllSocketIO() {
      if (socketBestshots) {
        liveFeedEnabled.value = false;
        socketBestshots.disconnect();
        socketBestshots.removeAllListeners();
        socketBestshots = null;
      }

      if (socketAlarms) {
        liveFeedEnabled.value = false;
        socketAlarms.disconnect();
        socketAlarms.removeAllListeners();
        socketAlarms = null;
      }

      if (socketAddons) {
        liveFeedEnabled.value = false;
        socketAddons.disconnect();
        socketAddons.removeAllListeners();
        socketAddons = null;
      }
    }

    function connectSocketIO() {
      if (!matchesOnly.value) {
        wireSocketIOBestshots();
      }
      wireSocketIOAlarms();
      wireSocketIOAddons();
      liveFeedEnabled.value = true;
    }

    async function reverseSearch() {
      disconnectAllSocketIO();
      const refresh = true;
      isLoading.value = true;
      scrollEnabled.value = false;
      let hasResults = false;
      try {
        if (
          store.getters['encounters/searchTemplateId'] === -1 &&
          store.getters['encounters/searchImageData'] != null
        ) {
          //dont have a templateId because this is an uploaded image, therefore process accordingly
          hasResults = await store.dispatch('encounters/searchByImageBytes', {
            forceRefresh: refresh,
            imgData: store.getters['encounters/searchImageData'],
            modality: store.getters['encounters/searchModality']
          });
        } else {
          if (store.getters['encounters/searchTemplateId']) {
            hasResults = await store.dispatch('encounters/searchByTemplateId', {
              forceRefresh: refresh,
              modality: store.getters['encounters/searchModality']
            });
          }
        }

        if (!hasResults) {
          noSearchResults.value = true;
        } else {
          noSearchResults.value = false;
        }
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    onBeforeUnmount(() => {
      disconnectAllSocketIO();
    });

    function faceFilterRemove() {
      scrollEnabled.value = true;
      noSearchResults.value = false;
      reloadEncounters();
    }

    async function setAlertsOnlyState(state) {
      reloadEncounters();
    }

    async function clearTimeFilter() {
      store.commit('encounters/setEncounterDateRangeFilter', null);
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    async function setTimeFilter(payload) {
      store.commit('encounters/setEncounterDateRangeFilter', payload);
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    function setTrackAnalysis(personId) {
      trackAnalysisPersonId.value = personId;
      showTrackAnalysis.value = true;
    }

    async function cameraFilterChange() {
      store.commit('auth/setUserSettingsChanged', Date.now())
      if (hasReverseSearchFilter()) {
        store.commit('encounters/setEncounters', []);
        store.commit('encounters/setCursor', null);
        await reverseSearch();
      } else {
        reloadEncounters();
      }
    }

    function hasReverseSearchFilter() {
      const reverseSearchTemplateId = store.getters['encounters/searchTemplateId'];
      const reverseSearchImageData = store.getters['encounters/searchImageData'];
      const isReverseSearchingTemplateId = Boolean(reverseSearchTemplateId) && reverseSearchTemplateId != -1;
      return isReverseSearchingTemplateId || Boolean(reverseSearchImageData);
    }

    async function watchlistFilterChange() {
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    async function tagsFilterChange() {
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    async function analyticsFilterChange() {
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    const isCaseSelected = ref(false);
    const selectedCase = ref();
    async function caseChange(sc) { 
      reloadEncounters();
      selectedCase.value = sc;
      isCaseSelected.value = (sc && sc.value != null);
    }

    const encountersLabel = computed(() => {
      if(liveFeedEnabled.value === false){
        return `Post-Event Encounters`
      }
      else{
        return  'Encounters'
      }
    })
    const selectedCaseName = computed(() => {
      if (selectedCase.value && selectedCase.value.value !== null) {
        return `for ${selectedCase.value.value.name}`;
      } else {
        return "";
      }
    });

    function enrollFace(enrollObj) {
      enrollEncounter.value = true;
      enrollmentInfo.value = enrollObj;
    }

    function enrollLPR(enrollObj) {
      enrollLicensePlate.value = true;
      enrollmentInfoLPR.value = enrollObj;
    }

    function playAlarm() {
      try {
        AlarmAudio.value.play();
      } catch (err) {
        console.error(err);
      }
    }

    async function adjFilterChange(adjFilter) {
      store.commit('encounters/setAdjudicationFilter', adjFilter);
      store.commit('auth/setUserSettingsChanged', Date.now())
      reloadEncounters();
    }

    function matchClicked(faceMatch) {
      matchPopup.value = true;
      lastSelectedMatch.value = faceMatch;
    }

    function getTitleEncounterDialog(encounter) {
      if (encounter && encounter.objectType === 'face') {
        return 'Encounter';
      } else if (encounter && encounter.objectType === 'face_match') {
        return 'Watchlist Match';
      } else if (encounter && encounter.objectType === 'object_lp_match') {
        return 'License Plate Match';
      } else {
        return 'Encounter';
      }
    }

    async function getWatchlistedImageDetails(watchlistedImage) {
      isLoading.value = true;
      const dispatchUrl = (watchlistedImage.objectType == 'face_match') ? 'watchlists/getWatchlistedFace' : 'watchlists/getWatchlistedImage';
      const id = (watchlistedImage.objectType === 'face_match') ? watchlistedImage.watchlistedFaceId : watchlistedImage.watchlistedImageId;
      const responseData = await store.dispatch(
        dispatchUrl,
        { id, }
      );


      if (responseData && responseData.status == 'success' && (responseData?.face || responseData?.image)) {
        watchlistedImageToExpand.value = responseData?.face ?? responseData?.image;
        showWatchlistedImageDetails.value = true;
      }
      isLoading.value = false;
    }

    const watchlistMatchStyle = computed(() => {
      if (windowWidth.value <= 1024) {
        return {
          'max-height': '100%',
          width: '100%',
          'overflow-y': 'auto',
        }
      } else {
        return {
          width: '60%',
          'max-height': '95%',
          'overflow-y': 'auto',
        }
      }
    });

    const enrollEncounterStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: '400px',
          // height: '430px',
        }
      } else {
        return {
          'min-width': '400px !important',
          'max-width': '40% !important',
          overflow: 'visible',
        }
      }
    });

    const enrollEncounterLPRStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: '90%'
        }
      } else {
        return {
          width: '700px !important'
        }
      }
    });

    const modalityColorIndexStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: '90%',
          // height: '430px',
        }
      } else {
        return {}
      }
    });

    /**
     * Debounce the encounters loading because sometimes editing filters can caused "bouncing" calls loadEncounters
     */
    const reloadEncounters = debounce(async () => {
      if (isInitializing.value) {
        return;
      }
      // clear cached data
      store.commit('encounters/setEncounters', []);
      store.commit('encounters/setCursor', null);
      // disconnect incoming live data feed
      disconnectAllSocketIO();
      // load current data, paged
      await loadEncounters();
      // depending on caller, re-connect live data feed
      if (isOnlyLiveFilters()) {
        connectSocketIO();
      } else {
        liveFeedEnabled.value = false;
      }
    }, 500);

    const isAnyCameraEnabled = computed(() => {
      let cameraList = store.getters['cameras/cameras'];
      return cameraList.some(c => c.state);
    });

    function isEncounterSuppressed(encounter) {
      if (encounter._watchlistId && !store.getters['watchlists/encounterFilterSelectedWatchlists'].includes(encounter._watchlistId)) {
        return true;
      }
      return false;
    }

    const isShowingModalityColorIndex = ref(false);

    const noTextSearchResults = computed(() => {
      return searchTextFilter.value && filteredEncounters.value.length === 0;
    });

    return {
      isLoading,
      error,
      hasEncounters,
      filteredEncounters,
      handleError,
      loadEncounters,
      filterActive,
      setFilterState,
      updateStore,
      addElement,
      reverseSearch,
      wireSocketIOBestshots,
      faceFilterRemove,
      setTextSearchFilter,
      setAlertsOnlyState,
      setTimeFilter,
      clearTimeFilter,
      moreData,
      scrollEnabled,
      showTrackAnalysis,
      trackAnalysisPersonId,
      setTrackAnalysis,
      cameraFilterChange,
      watchlistFilterChange,
      enrollEncounter,
      enrollFace,
      enrollmentInfo,
      liveFeedEnabled,
      AlarmAudio,
      playAlarm,
      noSearchResults,
      noEncountersFound,
      matchesOnly,
      adjFilterChange,
      matchClicked,
      matchPopup,
      lastSelectedMatch,
      getWatchlistedImageDetails,
      showWatchlistedImageDetails,
      watchlistedImageToExpand,
      watchlistMatchStyle,
      enrollEncounterStyle,
      isMobile,
      tagsFilterChange,
      getTitleEncounterDialog,
      caseChange,
      isCaseSelected,
      selectedCase,
      selectedCaseName,
      enrollLPR,
      enrollLicensePlate,
      enrollmentInfoLPR,
      enrollEncounterLPRStyle,
      encountersLabel,
      analyticsFilterChange,
      isAnyCameraEnabled,
      isEncounterSuppressed,
      isShowingModalityColorIndex,
      isLiveCamPopupVisible,
      keepFilterOpen,
      noTextSearchResults,
      modalityColorIndexStyle,
    };
  }
};
</script>

<style scoped lang="scss">

.dashboard-body {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: $--spacing-xl;
  padding-bottom: $--spacing-xl;
  overflow: auto;
}

.encounters-top-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: $--spacing-s;
  padding-bottom: $--spacing-s;
  padding-right: $--spacing-xl;
  position: sticky;
  top: 0;
  z-index: 96;
  background-color: var(--overwatch-background);
}

.encounters-top-bar-title {
  @include overwatch-title-large;
  color: var(--overwatch-neutral-100);
  line-height: 1;
}

.infinite-scroll {
  width: 100%;
  padding-right: $--spacing-xl;
}


.expand-filter-btn {
  @include overwatch-body-small;
  border-radius: 5px;
  text-transform: unset !important;
  color: var(--overwatch-secondary);
  background-color: var(--overwatch-neutral-300);
  margin-left: $--spacing-xl;
  padding-top: $--spacing-s;
  padding-bottom: $--spacing-s;
  padding-left: $--spacing-s;
  padding-right: $--spacing-s;
}

/*filter*/

.encounter-filter-holder {
  flex-shrink: 0;
  height: 100%;
  border-radius: 12px 12px 0px 0px !important;
  box-shadow: 4px 0 15px 0 rgba(0,19,58,0.3);
  z-index: 97;
}

.filter-enter-active {
  transition: all 0.4s ease-out;
}

.filter-leave-active {
  transition: all 0.4s ease-in;
}

.filter-enter-from,
.filter-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}

.filter-enter-to,
.filter-leave-from {
  opacity: 1;
  transform: translateX(0px);
}

.no-data-placeholder {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
  line-height: 46px;
  text-align: center;
  margin-top: 100px;
}

.no-data-description {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 25px;
  text-align: center;
  margin-top: 20px;
}

.modality-color-index {
  @include overwatch-body-small;
  text-decoration: underline;
  cursor: pointer;
  color: var(--overwatch-button-primary);
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {

  .encounter-filter-holder {
    position: fixed;
  }
}


/* MOBILE */
@media (max-width: 480px) {
  .dashboard-body {
    padding-left: $--spacing-xs;
  }
  .infinite-scroll {
    padding-right: $--spacing-xs;
  }

  .camera-live-feed {
    display: none !important;
  }


  .encounter-filter-holder {
    position: fixed;
    top: 50%;
    z-index: 100;
    transform: translate3d(0, 0, 0);
    width: 100%;
    height: 50%;
  }

  //hide scroll bar
  .encounter-filter-holder::-webkit-scrollbar {
    display: none;
  }

  /* Animations */
  .filter-enter-from,
  .filter-leave-to {
    opacity: 0;
    transform: translateX(30px);
  }


  .filter-enter-to,
  .filter-leave-from {
    opacity: 1;
    transform: translateX(0);
  }

  .expand-filter-btn {
    padding-left: $--spacing-xs;
    padding-right: $--spacing-xs;
    padding-top: $--spacing-base;
    padding-bottom: $--spacing-base;
  }
  .encounters-top-bar {
    justify-content: space-between;
    align-items: end;
    padding-top: $--spacing-xs;
    padding-bottom: $--spacing-xs;
    padding-right: $--spacing-xs;
  }
  .encounters-top-bar-title {
    @include overwatch-title-med;
    line-height: 1;
  }
  .modality-color-index {
    @include overwatch-body-xsmall;
  }
}
</style>
