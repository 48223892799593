<template>
  <div class="disable-select">
    <div :class='getCameraLayout()' @dblclick="handleDC()" @mouseenter="isMouseHovering = true" @mouseleave="isMouseHovering = false">
      <div v-show="showCameraName" class="camera-name-overlay">
        <div class="d-flex justify-content-center" style="margin-top: 6px">{{ cameraName }}</div>
      </div>
      <div v-if='isLoading' :style="getSpinnerPosition()">
        <roc-spinner/>
      </div>
      <div v-else-if="!isLoading">
        <img :src='cameraPreviewSrc' :style='activeStyle()'>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, onMounted, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default {
  name: 'CameraView',
  emits: ['camera-dclicked'],
  props: {
    cameraName: String,
    cameraUrl: String,
    cameraGuid: String,
    cameraState: Boolean,
    preview: String,
    mode: String,
    width: Number,
    height: Number,
    isSingle: Boolean,
    alwaysShowName: Boolean
  },
  components: {
  },
  setup(props, context) {
    const store = useStore();
    const isLoading = ref(true);

    const isMouseHovering = ref(false);
    const showCameraName = computed(() => {
      if (props.alwaysShowName) {
        return true;
      }
      return isMouseHovering.value;
    });

    const cameraPreview = ref(null);
    const camState = ref(props.cameraState);
    const dropdownOptions = ref(false);

    let socket;

    onMounted(async () => {
      const payload = `feed=livestream&topic=${props.cameraGuid}`;
      socket = await store.dispatch("auth/getSocketIO", payload);
      socket.on(props.cameraGuid, (payload) => {
        isLoading.value = false;
        cameraPreview.value = 'data:image/png;base64, ' + payload.image;
      });
    });

    onBeforeUnmount(() => {
      socket.close();
    });

    const cameraPreviewSrc = computed(function() {
      return cameraPreview.value;
    });

    function editClicked() {
      dropdownOptions.value = false;
      context.emit('edit-camera', props.cameraGuid);
    }

    async function deleteClicked() {
      if(camState.value) //camera must be stopped to delete
      {
        console.log("camera must be stopped to delete.");
        return;
      }


      isLoading.value = true;
      try {
        await store.dispatch("cameras/deleteCamera", props.cameraGuid);
      }
      catch(error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
      dropdownOptions.value = false;
    }

    watch(camState, () => {
      if(!camState.value) {
        activeStyle.value = `width: ${props.width}px; height: ${props.height}px; opacity: 0.4;`;
      }
    })

    watchEffect(() => {
      camState.value = props.cameraState;
    });

      // Access the current route using useRoute from vue-router
      const router = useRouter();

      // Create a ref to hold the current route
      const currentRoute = ref(router.currentRoute.value.path);

      // Watch for changes to the route
      watch(() => router.currentRoute.value.path, 
      (newRoute, oldRoute) => {
          // Update the currentRoute ref with the new route value
          currentRoute.value = newRoute;
        }
      );

    function activeStyle() {
      let style;      
      if(currentRoute.value === '/livefeedpopup'){
        style = `width: 100%; height: 100%;`
      }
      else if(currentRoute.value !== '/livefeedpopup' && camState.value) {
        style = `width: ${props.width}px; height: ${props.height}px;`;
      }
      else if(currentRoute.value !== '/livefeedpopup' && !camState.value)
      {
        style = `width: ${props.width}px; height: ${props.height}px; opacity: 0.4;`
      }
      return style;
    }

    async function toggleCamState(refresh = false) {
      isLoading.value = true;
      try {
        await store.dispatch('cameras/toggleCameraState', {
          forceRefresh: refresh,
          GUID: props.cameraGuid,
        });
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    function deleteStyle() {
      if(camState.value) {
        return "opacity: .4;";
      }
      else {
        return "";
      }
    }

    function handleDC() {
      context.emit("camera-dclicked", props.cameraGuid);
    }

    function getCameraLayout() {
      if(currentRoute.value === '/livefeedpopup' && props.isSingle){
        return `single-camera-card-popup`
      }
      if(currentRoute.value === '/livefeedpopup' && !props.isSingle){
        return 'grid-camera-card-popup';
      }
      if(props.isSingle) {
        return 'single-camera-card';
      }
      else {
        return 'grid-camera-card';
      }
    }

    function getSpinnerPosition() {
      if(props.isSingle) {
        return 'position: absolute; margin-left: 24rem; margin-top: 13rem;';
      }
      else {
        return 'position: absolute; margin-left: 12rem; margin-top: 5rem;';
      }
    }

    return {
      cameraPreviewSrc,
      camState,
      isLoading,
      activeStyle,
      editClicked,
      toggleCamState,
      dropdownOptions,
      deleteClicked,
      deleteStyle,
      handleDC,
      getCameraLayout,
      getSpinnerPosition,
      showCameraName,
      isMouseHovering
    };
  }
};
</script>

<style scoped lang="scss">
.div {
  margin-left: 21px;
}

.grid-camera-card {
  box-sizing: border-box;
  position: relative;
  height: 281px;
  width: 500px;
  border: 1px solid var(--overwatch-accent);
  background-color: #000000;
  overflow: hidden;
  display: flex;
}

.single-camera-card {
  box-sizing: border-box;
  position: relative;
  height: 500px;
  width: 900px;
  border: 1px solid var(--overwatch-accent);
  background-color: #000000;
  overflow: hidden;
  display: flex;
}

.single-camera-card-popup {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--overwatch-accent);
  background-color: #000000;
  overflow: hidden;
  display: flex;
}

.grid-camera-card-popup {
  box-sizing: border-box;
  position: relative;
  max-height: 381px;
  max-width: 600px;
  align-items: center;
  justify-content: start;
  border: 1px solid var(--overwatch-accent);
  background-color: #000000;
  overflow: hidden;
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.camera-name {
  font-size: 20px;
  color: #000000;
  margin-top: 16px;
  margin-left: 21px;
  margin-right: 21px;
}

.camera-url {
  font-size: 12px;
  opacity: 0.4;
  color: #000000;
  margin-left: 21px;
}

.header {
  width: 100%;
  height: 164px;
  background: var(--overwatch-secondary);
  font-size: 24px;
  box-shadow: 0 4px 15px 0 rgba(0, 19, 58, 0.3);
}

.searchRectangle {
  float: left;
  height: 44px;
  width: 300px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  font-size: 14px;
  padding-left: 6px;
  margin-left: 2rem;
}

.addCamera {
  float: right;
  text-transform: unset !important;
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text);
  font-size: 16px;
  margin-left: 20px;
}

.searchInput {
  border: 0px;
  margin-left: 6px;
  width: 90%;
}

.searchInput:hover {
  border: 0px;
}

input.searchInput:focus {
  outline-width: 0;
}

.lds-roller-white div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white !important;
  margin: -4px 0 0 -4px;
}

.dropdown-toggle:after {
  display: none;
}

.camera-name-overlay {
  position: absolute;
  height: 30px;
  opacity: 0.8;
  background-color: var(--overwatch-secondary);
  top: 0;
  left: 0;
  width: 100%;
  color: var(--overwatch-primary);
  @include overwatch-body-med;
}

.camera-name {

}

</style>