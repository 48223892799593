<template>
  <div class="wrapper overwatch-body-med">
    <div class="title">
      Case Management
      <RocButton
        @click="isNewCaseDialogShowing = true"
      >
        New Case
      </RocButton>
      <div style="margin-left: auto; display: flex; gap: var(--spacing-s)">
        <RocInput
          placeholder="Search Cases"
          v-model="filterText"
        />
        <MDBDropdown v-model="isShowingFilters">
          <RocDropdownToggle
            @click="isShowingFilters = !isShowingFilters"
          >
            <RocButton class="filter-button" type="white">
              <RocIcon icon="filter1"/>
            </RocButton>
          </RocDropdownToggle>
          <RocDropdownMenu aria-labelledby='dropdownMenuButton'>
            <RocDropdownItem>
              <div class="filter-dropdown">
                <div class="filters-title">
                  Filters
                </div>
                <div class="overwatch-body-large d-flex justify-content-between" @click="isShowingUserFilters = !isShowingUserFilters" style="cursor: pointer;">
                  Users 
                  <RocIcon
                    icon="downArrow"
                    style="transition: transform 0.1s ease; margin-left: auto;"
                    :style="{ transform: userFilterIcon === 'caret-up' ? 'rotate(0deg)' : 'rotate(180deg)' }"
                    />
                </div>
                <MDBCollapse v-model="isShowingUserFilters">
                  <UserFilter :users="uniqueUsers" v-model="selectedUserFilters"/>
                </MDBCollapse>
                <div class="overwatch-body-large d-flex justify-content-between" @click="isShowingPriorityFilters = !isShowingPriorityFilters" style="cursor: pointer;">
                  Priority
                  <RocIcon
                    icon="downArrow"
                    style="transition: transform 0.1s ease;"
                    :style="{ transform: priorityFilterIcon === 'caret-up' ? 'rotate(0deg)' : 'rotate(180deg)' }"
                    />
                </div>
                <MDBCollapse v-model="isShowingPriorityFilters">
                  <div style="display: flex; align-items: center; gap: var(--spacing-s);">
                    <RocCheckbox v-model="isHighPrioritySelected" />
                    <div @click="isHighPrioritySelected = !isHighPrioritySelected">
                      <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-0)" /> High
                    </div>
                  </div>
                  <div style="display: flex; align-items: center; gap: var(--spacing-s);" >
                    <RocCheckbox v-model="isMediumPrioritySelected"/>
                    <div @click="isMediumPrioritySelected = !isMediumPrioritySelected" class="overwatch-body-med">
                      <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-70)" /> Medium
                    </div>
                  </div>
                  <div style="display: flex; align-items: center; gap: var(--spacing-s);">
                    <RocCheckbox v-model="isLowPrioritySelected"/>
                    <div @click="isLowPrioritySelected = !isLowPrioritySelected" class="overwatch-body-med">
                      <RocIcon icon="priority" color="none" style="color: var(--roc-watch-similarity-90)" /> Low
                    </div>
                  </div>
                </MDBCollapse>
                <div class="overwatch-body-large" style="display: flex; justify-content: space-between;">
                  Show closed cases <RocSwitch :isActive="isClosedCasesSelected" @switch-toggled="isClosedCasesSelected = $event"/>
                </div>
              </div>
            </RocDropdownItem>
          </RocDropdownMenu>
        </MDBDropdown>
      </div>
    </div>
    <div class="cases-wrapper">
      <div class="cases-header d-flex justify-content-between">
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Priority</div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Case Number</div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Crime Classification</div>
        <div style="color: var(--overwatch-neutral-100); cursor: pointer;" class="overwatch-title-xsmall" @click="toggleDateCreated">
          <span>Date Created</span> 
          <RocIcon
          icon="downArrow"
          size="sm"
          style="transition: transform 0.1s ease; margin-left: var(--spacing-s);"
          :style="{ transform: dateCreatedIcon === 'caret-up' ? 'rotate(0deg)' : 'rotate(180deg)' }"
          />
        </div>
        <div style="color: var(--overwatch-neutral-100); cursor: pointer;" class="overwatch-title-xsmall" @click="toggleDateEdited">
          <span>Date Edited</span> 
          <RocIcon
          icon="downArrow"
          size="sm"
          style="transition: transform 0.1s ease; margin-left: var(--spacing-s);"
          :style="{ transform: dateEditedIcon === 'caret-up' ? 'rotate(0deg)' : 'rotate(180deg)' }"
          />
        </div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">User</div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Precinct #</div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Search Count</div>
        <div style="color: var(--overwatch-neutral-100);" class="overwatch-title-xsmall">Status</div>

        <!-- Spacing Placeholders -->
        <div style="flex: 0; width: fit-content">
          <RocButton style="visibility: hidden;"><span><img src="@/assets/img/icons/icons_white_16x16_view.svg"></span><span>View Case</span></RocButton>
          <img style="visibility: hidden;" src="@/assets/img/icons/View_Card_Options.svg"/>
        </div>
      </div>
      <div v-if="filteredAndSortedCases && filteredAndSortedCases.length > 0">
        <InfiniteScroll :items="filteredAndSortedCases" mode="nopage" customKey="_id">
          <template v-slot:item="{item}">
            <InvestigationCard
              :case="item"
              :caseId="item._id"
              :caseName="item.name"
              :crimeClassification="item.description"
              :dateCreated="item.createdAt"
              :dateEdited="item.updatedAt"
              :user="item.user"
              :isOpen="item.investigation.isOpen"
              :priority="item.investigation.priority"
              :precinct="item.investigation.precinct"
              @closeCase="closeCase(item, item.investigation.priority)"
              @openCase="openCase(item, item.investigation.priority)"
              @deleteCase="deleteCase(item)"
              @changePriority="p => setCasePriority(item, p, item.investigation.isOpen)"
            />
          </template>
        </InfiniteScroll>
      </div>
      <div v-else style="flex: 1; margin-top: 100px;">
        <div>
          <RocLogo />
        </div>
        <div class="no-data-bold">
          No Cases Available
        </div>
      </div>
    </div>
    <BaseDialog
      show
      title="New Case"
      v-if="isNewCaseDialogShowing"
      @close="isNewCaseDialogShowing = false;"
      style="width: 500px;"
    >
      <div class="dialog-wrapper">
        <div class="overwatch-body-med">
          FIS Case Number
          <RocInput style="margin-top: 4px;"
            placeholder="Enter the case number"
            v-model="newCaseName"
          />
        </div>
        <div class="overwatch-body-med">
          Crime Classification
          <RocInput style="margin-top: 4px;"
            placeholder="Enter a short description of the case"
            v-model="newCaseDescription"
          />
        </div>
        <div class="overwatch-body-med">
          Case Priority
          <RocSelect
          :availableOptions="priorityOptions"
          :optionLabel="'label'"
          :optionValue="'value'"
          :placeholder="'Select a priority'"
          @selection-changed="handleNewCasePriority"
        />
        </div>
        <div class="overwatch-body-med">
          Precinct
          <RocInput style="margin-top: 4px;"
            placeholder="Enter precinct number"
            v-model="newCasePrecinct"
          />
        </div>
        <div
          class="dialog-buttons"
        >
          <RocButton
            type="secondary"
            @click="isNewCaseDialogShowing=false"
          >
            Cancel
          </RocButton>
          <RocButton
            @click="goToWizard"
            :disabled="!isButtonEnabled"
          >
            Save & Search
          </RocButton>
        </div>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import RocButton from '@/components/ui/RocButton.vue';
import RocCheckbox from '@/components/ui/RocCheckbox.vue';
import RocInput from '@/components/ui/RocInput.vue';
import BaseDialog from '@/components/ui/BaseDialog.vue';
import InvestigationCard from '@/components/investigations/InvestigationCard.vue';
import InfiniteScroll from "@/components/ui/InfiniteScroll";
import UserFilter from '@/components/investigations/UserFilter';
import {
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBSwitch,
  MDBCollapse
} from 'mdb-vue-ui-kit';
import RocSwitch from '@/components/ui/RocSwitch.vue';
import RocSelect from '@/components/ui/RocSelect.vue';
import RocLogo from '@/components/ui/RocLogo.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import RocDropdownMenu from '../../components/ui/RocDropdownMenu.vue';
import RocDropdownItem from '../../components/ui/RocDropdownItem.vue';
import RocDropdownToggle from '../../components/ui/RocDropdownToggle.vue';

export default {
  name: 'InvestigativeCaseManagement',
  components: {
    RocButton,
    RocCheckbox,
    RocInput,
    BaseDialog,
    InvestigationCard,
    InfiniteScroll,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBSwitch,
    MDBCollapse,
    UserFilter,
    RocSwitch,
    RocSelect,
    RocIcon,
    RocLogo,
    RocDropdownMenu,
    RocDropdownItem,
    RocDropdownToggle
},
  setup(props, context) {
    const router = useRouter();
    const store = useStore();
    const isNewCaseDialogShowing = ref(false);

    const newCaseName = ref('');
    const newCaseDescription = ref('');
    const newCasePriority = ref('low');
    const newCasePrecinct = ref('');

    const priorityOptions = ref([
      {label: 'Low', value: 'low'},
      {label: 'Medium', value: 'medium'},
      {label: 'High', value: 'high'}
    ]);

    function handleNewCasePriority(priority) {
      newCasePriority.value = priority.value;
    }

    onMounted(async () => {
      await store.dispatch('investigations/getCases');
    })

    const cases = computed(()=>{
      return store.getters['investigations/cases'];
    });

    const filteredAndSortedCases = computed(() => {
      /** Filters */
      const filteredCases = cases.value.filter(c => {
        return c.name.toLowerCase().includes(filterText.value.toLowerCase())
          && ((c.investigation.priority === 'high' && isHighPrioritySelected.value)
            || (c.investigation.priority === 'medium' && isMediumPrioritySelected.value)
            || (c.investigation.priority === 'low' && isLowPrioritySelected.value))
          && (!c.investigation.isOpen ? isClosedCasesSelected.value : true)
          && (selectedUserFilters.value.map(u => u.email).includes(c.user.email))
      });

      /** Sorting */
      if (dateCreatedSortState.value) {
        // Date Created sort
        const state = dateCreatedSortState.value;
        filteredCases.sort((a, b) => {
          return -state * Date.parse(b.createdAt) + state * Date.parse(a.createdAt);
        })
      } else if (dateEditedSortState.value) {
        const state = dateEditedSortState.value;
        filteredCases.sort((a, b) => {
          return -state * Date.parse(b.updatedAt) + state * Date.parse(a.updatedAt);
        })

      } else {
        filteredCases.sort((a, b) => {
          return Date.parse(b.createdAt) - Date.parse(a.createdAt);
        })
      }
      return filteredCases
    })

    async function goToWizard() {

      const response = await createCase();
      const newCase = response.result;

      router.push(`/investigation/${newCase._id}`);
    }

    async function createCase() {
      const payload = {
        name: newCaseName.value,
        description: newCaseDescription.value,
        priority: newCasePriority.value,
        precinct: newCasePrecinct.value
      }
      var response = await store.dispatch("investigations/createCase", payload);

      return response;
    }

    /** TODO: For now, we pass priority in with the payloads here.
     * Should fix to only change the isOpen / priority properties at a time.
     * Usually you'd target "investigation.isOpen", but some package on node is blocking
     * setting key names with periods ('.')
     */

    async function closeCase(c, priority) {
      var response = await store.dispatch("investigations/closeCase", {
        caseId: c._id,
        priority: priority
      });
      return response;
    }

    async function openCase(c, priority) {
      var response = await store.dispatch("investigations/openCase", {
        caseId: c._id,
        priority: priority
      });
      return response;
    }


    async function setCasePriority(c, priority, isOpen) {
      await store.dispatch('investigations/setCasePriority', {
        caseId: c._id,
        priority: priority,
        isOpen: isOpen
      });
    }

    /****/

    async function deleteCase(c) {
      await store.dispatch("investigations/deleteCase", {
        caseId: c._id
      });
    }

    const filterText = ref('');

    const dateCreatedSortState = ref(0);
    function toggleDateCreated() {

      dateEditedSortState.value = 0;

      switch (dateCreatedSortState.value) {
        case 0:
          dateCreatedSortState.value = 1;
          break;
        case 1:
          dateCreatedSortState.value = -1;
          break;
        case -1:
          dateCreatedSortState.value = 0;
          break;
        default:
          dateCreatedSortState.value = 0;
      }
    }
    const dateCreatedIcon = computed(() => {
      switch (dateCreatedSortState.value) {
        case 0:
          return ''
        case 1:
          return 'caret-up';
        case -1:
          return 'caret-down';
        default:
          return '';
      }
    });

    const dateEditedSortState = ref(0);
    function toggleDateEdited() {
      dateCreatedSortState.value = 0;

      switch (dateEditedSortState.value) {
        case 0:
          dateEditedSortState.value = 1;
          break;
        case 1:
          dateEditedSortState.value = -1;
          break;
        case -1:
          dateEditedSortState.value = 0;
          break;
        default:
          dateEditedSortState.value = 0;
      }
    }
    const dateEditedIcon = computed(() => {
      switch (dateEditedSortState.value) {
        case 0:
          return ''
        case 1:
          return 'caret-up';
        case -1:
          return 'caret-down';
        default:
          return '';
      }
    });

    const isShowingFilters = ref(false);
    const isShowingUserFilters = ref(false);
    const isShowingPriorityFilters = ref(false);

    watch(isShowingFilters, nv => {
      if (!nv) {
        isShowingUserFilters.value = false;
        isShowingPriorityFilters.value = false;
      }
    });

    const userFilterIcon = computed(() => {
      if (isShowingUserFilters.value) {
        return 'caret-up'
      } else {
        return 'caret-down'
      }
    });
    const priorityFilterIcon = computed(() => {
      if (isShowingPriorityFilters.value) {
        return 'caret-up'
      } else {
        return 'caret-down'
      }
    });

    const isHighPrioritySelected = ref(true);
    const isMediumPrioritySelected = ref(true);
    const isLowPrioritySelected = ref(true);

    const isClosedCasesSelected = ref(true);

    const uniqueUsers = computed(() => {
      const output = [];
      const uniqueUserEmails = new Set();

      for (let c of cases.value) {
        if (!uniqueUserEmails.has(c.user.email)) {
          uniqueUserEmails.add(c.user.email);
          output.push(c.user)
        }
      }
      return output;
    });

    const selectedUserFilters = ref([]);

    /** All user filters should be selected on mount. */
    watch(uniqueUsers, nv => {
      selectedUserFilters.value = [...nv];
    });

    const isButtonEnabled = computed(() => {
      return newCaseName.value.length > 0 &&
        newCasePriority.value
    });

    return {
      isNewCaseDialogShowing,
      newCaseName,
      newCaseDescription,
      newCasePriority,
      newCasePrecinct,
      goToWizard,
      cases,
      closeCase,
      openCase,
      deleteCase,
      filterText,
      priorityOptions,
      handleNewCasePriority,
      setCasePriority,
      dateCreatedSortState,
      dateEditedSortState,
      toggleDateCreated,
      toggleDateEdited,
      dateCreatedIcon,
      dateEditedIcon,
      isShowingFilters,
      isShowingUserFilters,
      isShowingPriorityFilters,
      userFilterIcon,
      priorityFilterIcon,
      isHighPrioritySelected,
      isMediumPrioritySelected,
      isLowPrioritySelected,
      isClosedCasesSelected,
      uniqueUsers,
      selectedUserFilters,
      filteredAndSortedCases,
      isButtonEnabled
    }
  }
}

</script>

<style scoped lang="scss">
.wrapper {
  padding: var(--spacing-xl);
  background-color: var(--overwatch-background);
}

.title {
  display: flex;
  align-items: center;
  gap: var(--spacing-xl);
  @include overwatch-title-large;
}

.filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 50%;
}

.cases-wrapper{
  width: 100%;
  height: 100%;
  margin-top: 12px;
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
}

.cases-header {
  width:100%;
  height: 43px;
  padding: 0px 24px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.05);
  background: var(--overwatch-button-primary-20);
  border: 1px solid var(--overwatch-button-primary);
  border-radius: 5px 5px 0px 0px;
  align-items: center;
  user-select: none;
}

.cases-header > * {
  flex: 1;
  @include overwatch-title-xsmall;
}

.no-data-bold {
  display: flex;
  justify-content: center;
  font-size: 30px;
  margin-top: 30px;
}

.search-caret{
  transform: rotate(-180deg);
  margin-left:10px;
}

.dialog-wrapper { 
  display: flex;
  flex-direction: column;
  gap: var(--spacing-m);
}

.dialog-buttons {
  display: flex;
  gap: var(--spacing-s);
  justify-content: flex-end;
}

.filter-dropdown {
  padding: var(--spacing-l);
  width: 350px;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-l);
  @include overwatch-body-large;
}

.filters-title{
  @include overwatch-title-med;
}

</style>
