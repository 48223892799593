<template>
  <div class="wrapper">
    <!-- John Smith -->
    <div class="overwatch-title-med">
      {{ cluster?.name }}
    </div>

    <div class="video-player">
      <video ref="videoRef"
        controls
        autoplay
        :src="videoStreamEndpoint"
        :key="globalVideoPlaybackEncounter"
        @play="errorMessage=''"
        @error="handleError"
      >
      </video>
      <div>
        {{ errorMessage }}
      </div>
      <div>
        <div>
          <!-- 'play', 'pause', 'forward10', 'rewind10', 'rewind', 'fastForward', 'next', 'prev' -->
          <!-- <VideoControls
            @play="play"
            @pause="pause"
            @forward10="skip10(1)"
            @rewind10="skip10(-1)"
            @fastForward="fastPlayback(1)"
            @rewind="facePlayback(-1)"
            @next="skipToNextEncounter"
            @prev="skipToPreviousEncounter"
          /> -->
        </div>
      </div>
    </div>
    <!-- video player -->

    <div>

    </div>
    <!-- Identities appearing in this video list -->
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import RocIcon from '@/components/ui/RocIcon';
import VideoControls from '@/components/clusters/VideoControls.vue';

export default {
  name: 'ClusterEncounterVideo',
  components: {
    RocIcon,
    VideoControls
  },
  setup(props, context) {
    const store = useStore();

    const videoRef = ref(null);

    const globalVideoPlaybackEncounter = computed(() => {
      return store.getters['clusters/videoPlaybackEncounter'];
    });

    const cluster = computed(() => {
      const cluster = store.getters['clusters/clusters'].find(c => c.encounters.map(e=>e._id).includes(globalVideoPlaybackEncounter.value._id));
      return cluster;
    });

    const currentTime = ref();
    function updateCurrentTime() {
      currentTime.value = videoRef.value.currentTime;
    }

    // Encounters sorted by start of their track
    const sortedEncounters = computed(() => {
      return cluster.value.encounters.sort((a,b) => {
        encounterStartTimestamp(b) - encounterStartTimestamp(a);
      })
    });

    const lastEncounterIndex = ref(-1);
    const nextEncounterIndex = ref(0);

    watch(currentTime, nv => {
      // TODO: Find a better way to calculate next / last encounters.
      if (
        nv > encounterStartTimestamp(sortedEncounters.value[nextEncounterIndex.value]) ||
        nv < encounterStartTimestamp(sortedEncounters.value[lastEncounterIndex.value])
      ) {
        let found = false;
        for (let i = 0; i < sortedEncounters.value.length; i++) {
          if (nv > sortedEncounters.value[i]) {
            lastEncounterIndex.value = i;
            found = true;
            break;
          }
        }
        if (!found) {
          lastEncounterIndex.value = -1;
        }
        nextEncounterIndex.value = lastEncounterIndex.value + 1;
      }
    });

    const videoStreamEndpoint = computed(() => {
      const videoId = globalVideoPlaybackEncounter.value.track_info.videoMediaId;
      return `/rest/v1/media/video/${videoId}/stream`;
    })

    function play() {
      videoRef.value.play();
    }

    function pause() {
      videoRef.value.pause();
    }

    function skipToNextEncounter() {
      if (nextEncounterIndex.value >= 0 && nextEncounterIndex.value < sortedEncounters.value.length) {
        updateByAbsoluteTime(encounterStartTimestamp(sortedEncounters.value[nextEncounterIndex.value]));
      }
    }

    function skipToPreviousEncounter() {
      if (lastEncounterIndex.value >= 0 && lastEncounterIndex.value < sortedEncounters.value.length) {
        updateByAbsoluteTime(encounterStartTimestamp(sortedEncounters.value[lastEncounterIndex.value]));
      }
    }

    function skip10(direction) {
      videoRef.value.currentTime = videoRef.value.currentTime + (direction * 10);
    }

    function fastPlayback(direction) {

    }

    // The encounterTimestamp vs video start timestamp gives the time in milliseconds elapsed,
    // divided by 1000 to convert to seconds.
    function updateByAbsoluteTime(timestamp) {
      const newVideoTime = timestampToVideoTime(timestamp);
      videoRef.value.currentTime = newVideoTime;
    }

    function timestampToVideoTime(timestamp) {
      // TODO: query backend for video object
      // return Math.abs((timestamp - videoObject.value.startMS) / 1000);
      return 0;
    }

    function encounterStartTimestamp(encounter) {
      if (!encounter) {
        return ''
      }
      return encounter.track_info.start_timestamp;
    }

    const errorMessage = ref('');
    function handleError(e) {
      const errorCode = parseInt(e.target.error.message.split(":")[0]);
      switch (errorCode) {
        case 404:
          errorMessage.value = 'Video playback error. Context video does not exist.';
          break;
        default:
          errorMessage.value = 'Video playback error. Unknown error.';
      }
    }

    return {
      globalVideoPlaybackEncounter,
      cluster,
      videoRef,
      play,
      pause,
      skipToNextEncounter,
      skipToPreviousEncounter,
      skip10,
      fastPlayback,
      videoStreamEndpoint,
      currentTime,
      updateCurrentTime,
      handleError,
      errorMessage
    }
  }
}
</script>

<style scoped>
.video-player {
  height: fit-content;
  width: 100%;
  border: solid 1px var(--roc-global-accent);

}

</style>
