<template>
  <div>
    <Multiselect
      :allow-absent="true"
      v-model="selectedOptions"
      :mode="mode"
      :placeholder="placeholderText"
      :close-on-select="closeOnSelect"
      :searchable="true"
      :create-option="true"
      :options="allAvailableAttributes"
      :canClear="false"
      :caret="false"
      :disabled="!isEnabled"
      @open="toggleActive(true)"
      @close="toggleActive(false)"
      @click="!readOnly ? enableSelf() : null"
      @option="enrollUnknownEncounter"
      ref="multiselectRef"
      :class="{
        'enabled': isEnabled,
        'disabled': !isEnabled,
        'multiselect': true
      }"
    >
      <template v-slot:tag="{ option, handleTagRemove }">
        <!-- shows tags in the regular format if not Plus Badge -->
        <div v-if="option.label !== 'Plus Badge'" class="multiselect-tag">
          <span 
            :title="(option.label && option.label.length > 22) ? option.label : null"
          >
            {{ option.label }}
          </span>          
          <span
            v-if="isEnabled"
            class="multiselect-tag-remove"
            @click.stop="handleTagRemove(option, $event)"
          >
            <span class="multiselect-tag-remove-icon"></span>
          </span>
        </div>
        <!-- render plus badge icon -->
        <div v-else class="multiselect-tag" style="border: none; background-color: unset; padding-left: 4px;">
          <RocIcon size="md" color="primary" icon="addButton" style="cursor: pointer;"/>
        </div>
      </template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from '@vueform/multiselect';
import { useStore } from 'vuex';
import { ref, computed, nextTick, watch } from 'vue';
import RocIcon from '@/components/ui/RocIcon.vue';

export default {
  name: 'TagsMultiSelect',
  emits: ['toggle-tags-active', 'enroll-face'],
  props: {
    mode: {
      type: String,
      default: 'tags'
    },
    closeOnSelect: {
      type: Boolean,
      default: true
    },
    searchable: Boolean,
    createOption: Boolean,
    isTagInputActive: Boolean,
    selectedTags: Array,
    encounterTitle: String,
    modality: String,
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect,
    RocIcon
  },
  setup(props, context) {
    const dynamicStyle = ref(null);
    const multiselectRef = ref(null);
    const store = useStore();
    const selectedOptions = ref(Array.isArray(props.selectedTags) ? [...props.selectedTags, 'Plus Badge'] : ['Plus Badge']);
    const placeholderText = computed(() => {
      if (!selectedOptions.value) {
        return "";
      } else {
        return '+ Tag';
      }
    });
    const isEnabled = ref(false);
    // store initial selection list, update if selection changes or props change
    const selectedOptionsBase = ref(props.selectedTags);

    // if the incoming prop changes, update our working ref
    // this applies to an active face track - it should always update the latest attributes, and the final set once track ends
    // NOTE if a user tries to tag an active face track, it will overwrite if new templates come in
    watch(() => props.selectedTags, () => {
      selectedOptionsBase.value = props.selectedTags;
      selectedOptions.value = Array.isArray(props.selectedTags) ? [...props.selectedTags, 'Plus Badge'] : ['Plus Badge'];
    });

    // watch(() => props.readOnly, () => {
    //   console.log(props.readOnly);
    //   isEnabled.value = !props.readOnly;
    // });

    function enableSelf() {
      isEnabled.value = true;
      nextTick(() => {
        multiselectRef.value.open();
        multiselectRef.value.focus();
      })
    }

    function disableSelf() {
      isEnabled.value = false;
    }

    const allAvailableAttributes = computed(function () {
      let attributes = [];
      if (props.modality === 'object' || props.modality === 'object_lp_match') {
        attributes = store.getters['encounters/getAllObjectAttributes'] ?? [];
      } else if (props.modality === 'face' || props.modality === 'face_match') {
        attributes = store.getters['encounters/getAllFaceAttributes'] ?? [];
      }

      //remove any duplicate tags for available options
      attributes = [...new Set(attributes)];
      return attributes;
    });

    function enrollUnknownEncounter(tagValue) {
      //when the @option event is emitted from the multiselect when enter is pressed,
      // it returns (query, $select) where query is the new tag value 

      //if first character in new tag string is an @ symbol and encounter is an unknown face
      if (store.getters['settings/faceTaggingEnabled'] && 
            tagValue.includes("@", 0) && props.modality === 'face') {
        //emit value to encounter card and remove @ symbol in value
        let formattedTagValue = tagValue.substring(1)

        let fullName = formattedTagValue.split(' ');
        let parsedNameObject = nameParser(fullName)
        parsedNameObject.attributeTag = tagValue;
        
        context.emit('enroll-face', parsedNameObject);
        // selectedOptions.value.remove(tagValue);
        disableSelf();
      }
    };

    function nameParser(names) {
      let output = {};

      //if multiple names, make anything that's not the last name a part of the first
      if (names.length > 2) {
        output = {
          firstname: names[0] + ' ' + names.slice(1, -1).join(' '),
          //middlenames: names.slice(1, -1).join(' '),
          lastname: names[names.length - 1]
        };
      //if just one name is entered
      } else if (names.length < 2) {
        output = {
          firstname: names[0],
          //middlenames: '',
          lastname: ''
        };
      //if two names are entered
      } else {
        output = {
          firstname: names[0],
          //middlenames: '',
          lastname: names[names.length - 1]
        };
      }
      return output
    };

    async function toggleActive(active) {
      //if multiselect is closed, disable input
      if(!active) {
        isEnabled.value = false;
        //when multiselect closes, add plus badge back to selected options
        selectedOptions.value = Array.isArray(selectedOptions.value) ? [...selectedOptions.value, 'Plus Badge'] : ['Plus Badge']
      }

      if(active){
        //when multiselect opens, remove plus badge from selected options
        selectedOptions.value = selectedOptions.value.filter(item => item !== 'Plus Badge');
      }

      let emitObj = {
        isActive: active,
        tagInputValues: null
      };

      const selectedTags = selectedOptions.value.filter(item => item !== 'Plus Badge');
      if(!_.isEmpty(_.xor(selectedOptionsBase.value, selectedTags))) {
        //don't send if props passed into multiselect and input field are equal
        emitObj.tagInputValues = selectedTags;
        selectedOptionsBase.value = selectedTags;
      }

      context.emit('toggle-tags-active', emitObj);
    };

    return {
      dynamicStyle,
      enableSelf,
      allAvailableAttributes,
      selectedOptions,
      placeholderText,
      toggleActive,
      multiselectRef,
      isEnabled,
      enrollUnknownEncounter
    };
  }
};
</script>

<style scoped lang="scss">
.multiselect :deep(.multiselect-tag) {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary); // Font color
  background-color: var(--overwatch-button-primary-20);

  border: solid 1px var(--overwatch-button-primary); // Tag border

  padding: $--spacing-base $--spacing-s;
}

.multiselect :deep(.multiselect-tag span) {
  max-width: 25ch; 
  overflow: hidden; 
  text-overflow: ellipsis;
}

.multiselect :deep(.multiselect-tags) {
  max-height: 80px;
  overflow-y: auto;
}

.multiselect :deep(.multiselect-dropdown){
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  border: none;
  bottom: -1px; //this moves the dropdown position
  color: var(--overwatch-neutral-100) !important;
}

.enabled {
  --ms-max-height: 200px;

  --ms-tag-bg: #eff3f9;
  --ms-tag-color: #00000;
  --ms-tag-radius: 9999px;
  --ms-tag-font-size: 10px;
  --ms-tag-font-family: var(--font-family);
  --ms-dropdown-bg: var(--overwatch-secondary);

  --ms-border-color-active: var(--overwatch-neutral-300);
  // --ms-tag-background: #FFFFFF;
  --ms-option-color-pointed: var(--overwatch-neutral-100);
  --ms-option-color-selected: var(--overwatch-button-text);
  --ms-option-bg-selected: var(--overwatch-button-primary);
  --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
  --ms-option-bg-pointed: var(--overwatch-button-primary-20);

  --ms-spinner-color: var(--overwatch-primary);
  --ms-ring-color: none;
  --ms-group-label-bg-selected: var(--overwatch-button-primary);
  --ms-group-label-bg-selected-pointed: var(--overwatch-button-primary);
  --ms-px: 10px;
  background-color: var(--overwatch-neutral-500);
  --ms-ring-width: 0px;
}

.enabled .multiselect-tag {
  padding-right: 2px;
}

.disabled {
  --ms-tag-bg: #eff3f9;
  --ms-tag-color: #00000;
  --ms-tag-radius: 9999px;
  --ms-max-height: 200px;
  --ms-tag-font-size: 10px;
  --ms-tag-font-family: var(--font-family);
  --ms-dropdown-bg: var(--overwatch-secondary);
  --ms-border-color: var(--overwatch-neutral-300);
  --ms-tag-background: var(--overwatch-button-primary-20);
  --ms-option-bg-selected: var(--overwatch-button-primary);
  --ms-option-bg-selected-pointed: var(--overwatch-button-primary);
  --ms-option-bg-pointed: var(--overwatch-button-primary-20);
  --ms-spinner-color: var(--overwatch-primary);
  --ms-ring-color: var(--overwatch-button-primary);
  --ms-group-label-bg-selected: var(--overwatch-button-primary);
  --ms-group-label-bg-selected-pointed: var(--overwatch-button-primary);
  --ms-border-width: 0px;
  --ms-bg-disabled: var(--overwatch-secondary);
  --ms-tag-bg-disabled: var(--overwatch-neutral-300);
  --ms-tag-color-disabled: #00000;
  cursor: pointer;
}

.multiselect-tag-remove {
  margin: 0;
}

.icon-container {
  height: 100%;
}

@media (max-width: 480px) {
  .multiselect :deep(.multiselect-tag) {
    @include overwatch-body-xsmall;
  }
}
</style>
