import "mdb-vue-ui-kit/css/mdb.min.css";
import { createApp } from "vue";
import router from "./router.js";
import store from "./store/index.js";
import App from "./App.vue";
import BaseCard from "./components/ui/BaseCard.vue";
import BaseButton from "./components/ui/BaseButton.vue";
import BaseBadge from "./components/ui/BaseBadge.vue";
import BaseSpinner from "./components/ui/BaseSpinner.vue";
import RocSpinner from "./components/ui/RocSpinner.vue";
import BaseDialog from "./components/ui/BaseDialog.vue";
import AuthImg from "./components/ui/AuthImg.vue";
import { ObserveVisibility } from "vue-observe-visibility";
import Toaster from "@meforma/vue-toaster";
import "@/styles/rocenroll-global.css";
import VueKonva from 'vue-konva';
import "@/styles/rocwatch-global.css";
import * as Sentry from "@sentry/vue";
import { CaptureConsole } from "@sentry/integrations";

// reset auth flag here, the subsequent isLoggedIn action will set it to current status
// without this, there seems to be a state where router thinks its authenticated (from an expired auth) before isLoggedIn updates state to false
store.commit('auth/setIsAuthenticated', false);
// this loads white labeling up front if configured
store.dispatch('auth/loadPreAuthConfig').then(() => {
  store.dispatch("auth/isLoggedIn").then(async (isloggedIn) => {
    console.debug("main isLoggedIn: ", isloggedIn);
    const app = createApp(App);

    Sentry.init({
      app,
      enabled: process.env.VUE_APP_SENTRY_ENABLED === "true",
      release: `rocenroll@${process.env.VUE_APP_VERSION}`,
      environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
      dsn: process.env.VUE_APP_SENTRY_DSN,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new CaptureConsole({
          levels: ["error"],
        }),
        new Sentry.Replay(),
      ],
      // tracePropagationTargets: all requests to localhost and same origin by default
      enableTracing: true,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      trackComponents: true,
    });

    app.use(router);
    app.use(store);
    app.use(Toaster);
    app.use(VueKonva);
    app.directive('observe-visibility', {
      beforeMount: (el, binding, vnode) => {
        vnode.context = binding.instance;
        ObserveVisibility.bind(el, binding, vnode);
      },
      update: ObserveVisibility.update,
      unmounted: ObserveVisibility.unbind,
    });

    app.component('auth-img', AuthImg);
    app.component('base-card', BaseCard);
    app.component('base-button', BaseButton);
    app.component('base-badge', BaseBadge);
    app.component('base-spinner', BaseSpinner);
    app.component('roc-spinner', RocSpinner);
    app.component('base-dialog', BaseDialog);

    app.mount('#app');
  });
});