
<script setup>
import { ref, onMounted, computed, defineEmits, defineProps } from 'vue';
import CaptureZonesEditor from '../../components/cameras/CaptureZonesEditor.vue';

// Send a message to the parent window when the child window is closed
window.addEventListener('beforeunload', () => {
    window.opener.postMessage('childWindowClosed', '*');
});

</script>

<template>
    <div class="capture-zones">
        <CaptureZonesEditor />
    </div>
</template>


<style scoped>
/* Your styles here */
</style>
