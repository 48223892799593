<template>
  <div class="disable-select">
    <div class="d-flex justify-content-start" style="width: 100%;">
      <div v-if="!isHidden('firstname')" class="d-flex align-items-center" style="flex: 1; margin-right: 10px;">
        <input :disabled="readonly || isDisabled('firstname')" v-model.lazy="firstname" :class="mode == 'adder' ? 'inputAdder' : 'inputAdded'" :placeholder="mode == 'adder' ? 'First Name' : ''" type="text"/>
      </div>
      <div v-if="!isHidden('lastname')" class="d-flex align-items-center" style="flex: 1; margin-right: 10px;">
        <input :disabled="readonly || isDisabled('lastname')" v-model.lazy="lastname" :class="mode == 'adder' ? 'inputAdder' : 'inputAdded'" :placeholder="mode == 'adder' ? 'Last Name' : ''" type="text"/>
      </div>
      <div v-if="!isHidden('email')" class="d-flex align-items-center" style="flex: 1;  margin-right: 10px;">
        <input :disabled="readonly || isDisabled('email')" v-model.lazy="email" :class="mode == 'adder' ? 'inputAdder' : 'inputAdded'" :placeholder="mode == 'adder' ? 'Email' : ''" type="email"/>
      </div>
      <div v-if="!isHidden('phoneNumber')" class="d-flex align-items-center" style="flex: 1;">
        <input :disabled="readonly || isDisabled('phoneNumber')" v-model.lazy="phoneNumber" :class="mode == 'adder' ? 'inputAdder' : 'inputAdded'" :placeholder="mode == 'adder' ? 'Phone Number' : ''" type="tel"/>
      </div>
      <div v-if="mode != 'adder'" class="d-flex flex-row align-items-center justify-content-end" style="flex: .4; cursor: pointer;">
        <RocIcon icon="edit" size="xs" style="margin-left: 0;" @click="(readonly ? '' : collapsed = !collapsed)"/>
        <RocIcon icon="trash" color="red" size="xs" style="margin-left: 10px;" @click="(readonly ? '' : removeClicked())"/>
        <RocIcon v-if="!isValid" icon="error" size="xs" color="red" style="margin-left: 10px;"/>
      </div>
    </div>
    <div v-if="!collapsed || mode == 'adder'">
      <div style="width: 100%; margin-top: 10px;">
        <textarea 
          :disabled="readonly" 
          v-model.lazy="notes" 
          :placeholder="mode == 'adder' ? 'Notes' : ''" 
          rows="2" 
          type="text" 
          :class="mode == 'adder' ? 'textAreaAdder' : 'textAreaAdded'">
        </textarea>
      </div>
    </div>
    <div class="d-flex">
      <div v-if="mode === 'adder'" class="addRemoveBtn" @click="isAddable() ? entitlementAdded() : ''">+ Add</div>
    </div>
  </div>
</template>

<script>
import { MDBInput, MDBTextarea } from "mdb-vue-ui-kit";
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import RocIcon from "../ui/RocIcon.vue";
// import { debounce } from "lodash";

export default {
  name: "EntitlementImportEntry",
  emits: ['delete', 'add', 'updated'],
  props: {
    refIndex: {
      type: Number,
    },
    mode: {
      type: String,
    },
    firstname: {
      type: String,
    },
    lastname: {
      type: String,
    },
    email: {
      type: String,
    },
    phoneNumber: {
      type: String,
    },
    notes: {
      type: String,
    },
    readonly: {
      type: Boolean
    }
  },
  components: {
    MDBInput,
    MDBTextarea,
    RocIcon
  },
  setup(props, context) {
    const store = useStore();
    const firstname = ref(props.firstname ? props.firstname : '');
    const lastname = ref(props.lastname ? props.lastname : '');
    const email = ref(props.email ? props.email : '');
    const phoneNumber = ref(props.phoneNumber ? props.phoneNumber : '');
    const notes = ref(props.notes ? props.notes : '');
    const collapsed = ref(true);

    const phoneRegex = computed(() =>{
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      return _.get(enrollConfig, 'entitlements.phoneRegex', ''); // default to no matching
    });
    const emailRegex = computed(() =>{
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      return _.get(enrollConfig, 'entitlements.emailRegex', ''); // default to no matching
    });

    function toJSON() {
      return {
        firstname: firstname.value,
        lastname: lastname.value,
        email: email.value,
        phoneNumber: phoneNumber.value,
        notes: notes.value,
      }
    }

    function entitlementAdded() {
      context.emit('add', toJSON());

      // Clear values after adding
      firstname.value = '';
      lastname.value = '';
      email.value = '';
      phoneNumber.value = '';
      notes.value = '';
    }

    function removeClicked() {
      context.emit("delete", props.refIndex);
    }

    // watch properties for change, debounce/emit the change to parent every half second
    // this is to avoid emitting every keystroke
    // v-model.lazy doesn't seem to work with MDBInput
    // watch(name, debounce(() => {
    //     context.emit("updated", props.refIndex, 'name', name.value);
    //   }, 500)
    // );
    // watch(email, debounce(() => {
    //     context.emit("updated", props.refIndex, 'email', email.value);
    //   }, 500)
    // );
    // watch(phoneNumber, debounce(() => {
    //     context.emit("updated", props.refIndex, 'phoneNumber', phoneNumber.value);
    //   }, 500)
    // );
    // watch(notes, debounce(() => {
    //     context.emit("updated", props.refIndex, 'notes', notes.value);
    //   }, 500)
    // );

    watch(firstname, () => { 
      context.emit("updated", props.refIndex, 'firstname', firstname.value);
    });
    watch(lastname, () => { 
      context.emit("updated", props.refIndex, 'lastname', lastname.value);
     });
    watch(email, () => { 
      context.emit("updated", props.refIndex, 'email', email.value) ;
    });
    watch(phoneNumber, () => { 
      context.emit("updated", props.refIndex, 'phoneNumber', phoneNumber.value);
    });
    watch(notes, () => { 
      context.emit("updated", props.refIndex, 'notes', notes.value);
    });

    const isValid = computed(() => {
      const adderOk = (props.mode != 'adder');
      const firstnameOk = isRequired('firstname') ? firstname.value.length > 0 : true;
      const lastnameOk = isRequired('lastname') ? lastname.value.length > 0 : true;
      const emailOk = isRequired('email') ? (email.value.length > 0 && testEmail(email.value)) : true;
      const phoneNumberOk = isRequired('phoneNumber') ? (phoneNumber.value.length > 0 && testPhoneNumber(phoneNumber.value)) : true;
      return adderOk && firstnameOk && lastnameOk && emailOk && phoneNumberOk;
    });

    function testEmail(value) {
      if (emailRegex.value && emailRegex.value.length > 0) {
        return new RegExp(emailRegex.value).test(value);
      } else {
        return true;
      }
    }

    function testEmail(value) {
      if (emailRegex.value && emailRegex.value.length > 0) {
        return new RegExp(emailRegex.value).test(value);
      } else {
        return true;
      }
    }

    function testPhoneNumber(value) {
      if (phoneRegex.value && phoneRegex.value.length > 0) {
        return new RegExp(phoneRegex.value).test(value);
      } else {
        return true;
      }
    }

    function isRequired(field) {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      const requiredFields = _.get(enrollConfig, 'entitlements.requiredFields', []); // default to not required
      return requiredFields.includes(field);
    }
    function isDisabled(field) {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      const disabledFields = _.get(enrollConfig, 'entitlements.disabledFields', []); // default to not disabled
      return disabledFields.includes(field);
    }
    function isHidden(field) {
      const enrollConfig = store.getters['rocenroll/enrollConfig'];
      const hiddenFields = _.get(enrollConfig, 'entitlements.hiddenFields', []); // default to not hidden
      return hiddenFields.includes(field);
    }
    function isAddable() {
      const adderOk = (props.mode === 'adder');
      const firstnameOk = isRequired('firstname') ? firstname.value.length > 0 : true;
      const lastnameOk = isRequired('lastname') ? lastname.value.length > 0 : true;
      const emailOk = isRequired('email') ? email.value.length > 0 : true;
      const phoneNumberOk = isRequired('phoneNumber') ? phoneNumber.value.length > 0 : true;
      return adderOk && firstnameOk && lastnameOk && emailOk && phoneNumberOk;
    };   

    return {
      firstname,
      lastname,
      email,
      phoneNumber,
      notes,
      toJSON,
      removeClicked,
      entitlementAdded,
      collapsed,
      isAddable,
      isValid,
      isDisabled,
      isHidden
    };
  }
};
</script>

<style scoped>

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.addRemoveBtn {
  font-size: 14px; 
  margin-left: 2px; 
  margin-left: 10px; 
  cursor: pointer;
  width: auto;
}

.inputAdded {
  width: 100%;
  font-size: 12px;
  background: white;
  border: none;
  padding: 5px;
}
.inputAdder {
  width: 100%;
  font-size: 12px; 
  background: var(--overwatch-neutral-500);
  border: var(--overwatch-neutral-300);
  padding: 5px;
}

.textAreaAdded {
  width: 100%;
  font-size: 12px; 
  background: var(--overwatch-neutral-500);
  border: var(--overwatch-neutral-300);
  padding: 5px;
}

.textAreaAdder {
  width: 100%;
  font-size: 12px; 
  resize: none;
  background: var(--overwatch-neutral-500);
  border: var(--overwatch-neutral-300);
  padding: 5px;
}
</style>