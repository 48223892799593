<template>
  <div v-if='isCameraCRUD' style="position: absolute; margin-left: 23rem; margin-top: 20rem; z-index: 2;">
    <roc-spinner/>
  </div>
  <div class='header overwatch-body-large'>
    <div class='camera-name-container align-self-center'>
      <div class='inputTitle'>Camera Name</div>
      <div class='searchRectangle' style="border-radius: 5px;">
        <div class='align-self-center' style='margin-top: 9px;'>
          <input class='searchInput overwatch-body-med' label='Example label' type='text' v-model="cameraName" />
          <!--Search By Camera Name-->
        </div>
      </div>
    </div>
    <div class='video-source-container align-self-center'>
      <div class='inputTitle'>Video Source</div>
      <div class='searchRectangle searchRectangle-video-source' style="border-radius: 5px;">
        <div class='align-self-center align-items-center overwatch-body-med' style='margin-top: 9px;'>
          <RocIcon size="md" icon="cameraOn" color="black"/>
          <input class='searchInput' label='Example label' type='text' v-model="cameraUrl" @blur="gotBlur()"/>
          <!--Search By Camera Name-->
        </div>
      </div>
    </div>
  </div>
  <div class='d-flex flow justify-content-center'>
    <div id='advancedSettings' class="settingsRectangle" v-if="isAdvancedEditorDisplayed" :style="'height:' + advancedEditorHeight + '; border: unset;'">
      <JsonEditor v-model="advancedEditorValue"
                  v-if="isAdvancedEditorDisplayed"
                  @change="isButtonDisabled=false;"
                  class="json-editor"
                  :style="{'filter': darkMode ? 'invert(1)' : 'invert(0)'}"
                  style="height: 100%; width: 100%;"/>
      <div id="advancedSettingsButtons" class="d-flex justify-content-end" style="margin-top: var(--spacing-l); gap: var(--spacing-s)">
        <RocButton @click="cancelAdvancedSettings" type="secondary">Cancel</RocButton>
        <RocButton @click="okAdvancedSettings">OK</RocButton>
      </div>
    </div>
    <div id='settings' class='settingsRectangle align-content-start' v-if="!isAdvancedEditorDisplayed">
      <div class='toggle-list'>
        <div class='settingsHeader' style='width: 100%; margin:0 !important; padding: 4px !important;'>SETTINGS</div>
        <div class="settingsSection">
          <div class='d-flex justify-content-between'>
            <div class="settingsSectionHeader">Enabled</div>
            <RocSwitch id='enabled' :isActive="enabled" @switch-toggled="enabled = $event"/>
          </div>
          <div class="settingsSectionDesc">Camera is on.</div>
        </div>
        <!-- <div class="settingsSection">
          <div class='d-flex justify-content-between'>
            <div class="settingsSectionHeader">Motion-activated Analysis</div>
            <div><MDBSwitch id='motionActivated' v-model="motionActivated"/></div>
          </div>
          <div class="settingsSectionDesc">Limit analysis to frames with motion.</div>
        </div> -->
        <div class="settingsSection">
          <div class='d-flex justify-content-between'>
            <div class="settingsSectionHeader">Face Analysis</div>
            <RocSwitch id='faceAnalytics' :isActive="faceAnalytics" @switch-toggled="faceAnalytics = $event"/>
          </div>
          <div class="settingsSectionDesc">Detects and Matches faces in real-time.</div>
        </div>
        <div class="settingsSection">
          <div class="settingsSectionHeader">Object Analysis</div>
          <filterMultiSelect style="margin:8px 0px 2px;"
            mode="tags" :close-on-select="false" no-results-text=""
            placeholder-text="Select Objects" :available-items="analytics"
            :currently-selected="selectedAnalytics" @selection-changed="updateSelectedAnalytics"
          />
          <div class="settingsSectionDesc">Detects objects in real-time.</div>
        </div>
        <div class="settingsSection">
          <div class='d-flex justify-content-between'>
            <div class="settingsSectionHeader">Record Video</div>
            <RocSwitch id='enableRecorder' :isActive="enableRecorder" @switch-toggled="enableRecorder = $event"/>
          </div>
          <div class="settingsSectionDesc">Records video for playback.</div>
        </div>
        <div class="settingsSection">
          <div class='d-flex justify-content-between'>
            <div class="settingsSectionHeader">Match Threshold Override</div>
            <RocSwitch id='enableMatchThresholdOverride' :isActive="enableMatchThresholdOverride" @switch-toggled="enableMatchThresholdOverride = $event"/>
          </div>
          <RocRange v-model="matchThresholdSlider" :disabled="!enableMatchThresholdOverride"/>
          <div class="settingsSectionDesc">Allows for Camera Level Match Threshold for Face</div>
        </div>
        <div class="settingsSection">
          <div class="settingsSectionHeader">User Groups</div>
          <filterMultiSelect style="margin:8px 0px 2px;"
            mode="tags" :close-on-select="false"
            placeholder-text="Select User Groups" :available-items="allUserGroups"
            :currently-selected="selectedUserGroupIds" @selection-changed="updateSelectedUserGroups" :enableClear="false"
          />
          <div class="settingsSectionDesc">Assign Camera to User Group(s).</div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <div class='stream-preview'>
        <div v-if='isLoadingStreamPreview'>
          <roc-spinner/>
        </div>
        <img :src='cameraPreviewSrc' @error="missingPreview"/>
      </div>
      <div class="capture-zones" v-if="isCaptureZonesEnabled">
          <div style="margin-right: var(--spacing-s)">Capture Zones</div>
          <div style="margin-right: var(--spacing-base)" class="disabled-bubble"></div>
          <div style="color: var(--overwatch-neutral-200)">Disabled</div>
          <div style="margin-left: auto">
          <RocIcon color="primary" size="sm" icon="outsideLink"
          style="margin-right: var(--spacing-m); cursor: pointer"
          @click="openCaptureZonesEditor()"/>
          <RocPopper arrow hover placement="bottom" :popperType="'tooltip'" :locked="true" class="popper">
            <RocIcon size="sm" color="primary" icon="tooltip" />
                <template #content>
                  <div class="d-flex flex-column" style="max-width: 250px">
                    <div>Click to open the camera in a new window and edit Capture Zones</div>
                    <div
                    @click="isShowingLearnMoreText = true"
                    style="cursor: pointer; text-decoration: underline; margin-top: var(--spacing-s)">
                    Learn More
                  </div>
                  </div>
                </template>
          </RocPopper>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="!isAdvancedEditorDisplayed" class='btn-container d-flex justify-content-end overwatch-body-med' style="gap: var(--spacing-s)">
    <RocButton v-if="mode==='edit' && !isAdvancedEditorDisplayed" @click="isAdvancedEditorDisplayed=!isAdvancedEditorDisplayed" type="secondary"> {{ isAdvancedEditorDisplayed ? 'General' : 'Advanced'}} </RocButton>
    <RocButton class='addCameraButton' @click="executeCRUDCommand" :disabled="isButtonDisabled">{{getButtonText()}}</RocButton>
  </div>
  <base-dialog
  style="width: 420px"
  :show="isShowingLearnMoreText"
  @close="isShowingLearnMoreText = false">
  <div class="d-flex flex-column" style="margin-top: -12px">
    <div class="overwatch-title-small d-flex justify-content-center">Capture Zones</div>
    <div style="margin-top: var(--spacing-s)">
      Capture zones are user defined areas that are included/excluded from analytic data processing. Use cases include honing in on areas with stable activity, excluding unwanted noise, ect.
    </div>
  </div>
  </base-dialog>
</template>

<script>
import JsonEditor from "json-editor-vue3";
import { get as lodashGet, set as lodashSet, isEqual, merge as LodashMerge } from "lodash";
import filterMultiSelect from "@/components/ui/filterMultiSelect";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton.vue";
import RocSwitch from "@/components/ui/RocSwitch.vue";
import RocRange from "@/components/ui/RocRange.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocPopper from "@/components/ui/RocPopper.vue";
import CaptureZonesPopup from "@/pages/cameras/CaptureZonesPopup.vue";

export default {
  name: 'CameraCRUD',
  emits: ["close"],
  components: {
    JsonEditor,
    filterMultiSelect,
    RocButton,
    RocSwitch,
    RocRange,
    RocIcon,
    RocPopper,
    CaptureZonesPopup
  },
  props: {
    mode: String,
    cameraId: String
  },
  setup(props, context) {
    const store = useStore();
    const cameraName = ref(null);
    const cameraUrl = ref(null);
    const enabled = ref(true);
    const faceAnalytics = ref(true);
    const selectedUserGroupIds = ref([]);
    // const motionActivated = ref(false);
    const enableRecorder = ref(true);
    const cameraPreview = ref(null);
    let rawPreview;// = ref(null);
    let previewUpdateRequired = false;
    const isCameraCRUD = ref(false);
    const isLoadingStreamPreview = ref(false);
    const editCam = ref(store.getters['cameras/findByGUID'](props.cameraId));
    const isButtonDisabled = ref(true);
    const matchThresholdSlider = ref(80);
    const enableMatchThresholdOverride = ref(false);
    const isShowingLearnMoreText = ref(false);

    const isAdvancedEditorDisplayed = ref(false);
    const advancedEditorHeight = computed(() => {
      const rectangle = document.getElementById('settings')
      return rectangle.offsetHeight + 'px';
    });

    //Flag to enable/disable capture zones editor, remove once feature is implemented
    const isCaptureZonesEnabled = ref(false);
    const childWindow = ref(null);
    function openCaptureZonesEditor() {
      const params = {cameraGuid: props.cameraId, cameraName: cameraName.value};
      const queryString = Object.keys(params)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(params[key]))
      .join('&');

      //open new window w new route
      childWindow.value = window.open(`/capturezoneseditor?${queryString}`, 'Capture Zones', 'width=1200,height=700,left=300,top=200, resizable=yes');

      //Add an event listener to the parent window so when it closes it will close the child window
      window.addEventListener('beforeunload', closeChildWindow);

      // Listen for messages from the child window
      window.addEventListener('message', (event) => {
      // Check if the message indicates that the child window has been closed === 'childWindowClosed'
        if (event.data ) {
        //remove event listener
          window.removeEventListener('beforeunload', closeChildWindow);
        }
      });
    }

    function closeChildWindow() {
      // Check if the child window is open before trying to close it
      if (childWindow.value && !childWindow.value.closed) {
          childWindow.value.close();
      }
    }

    const editCamCopy = computed(() => {
        const editCamContent = editCam.value ? JSON.parse(JSON.stringify(editCam.value)) : {};
        // We don't want users to mess with these fields.
        // Hidden from editor for safety.
        const hiddenFields = ['previewImage', '_id', 'GUID',
          'state', 'label', 'createdUTC', 'name', '__v', 'url', 'value', 'userGroups'];

        for (let field of hiddenFields) {
          delete editCamContent[field];
        }

        return editCamContent;
    });
    const advancedEditorValue = ref(editCamCopy.value);

    const analytics = ref([
      'License Plate',
      'OCR In-The-Wild',
      'Vehicle',
      'Pedestrian',
      'Airplane',
      'Boat',
      'Gun',
      'Tattoo'
    ]);

    const originalSelectedAnalytics = ref([]);
    const originalSelectedAlgs = ref([]);

    const selectedAnalytics = ref([]);
    function updateSelectedAnalytics(selectedReps) {
      selectedAnalytics.value = selectedReps.value;
	    isButtonDisabled.value = false;
    }

    const analyticsMap = {
      'License Plate': 'lpr',
      'OCR In-The-Wild': 'ocr',
      'Vehicle': 'vehicle',
      'Pedestrian': 'pedestrian',
      'Airplane': 'airplane',
      'Boat': 'boat',
      'Gun': 'gun',
      'Tattoo': 'tattoo'
    }

    cameraPreview.value = require('@/assets/camera-settings-background.png');
    const cameraPreviewSrc = computed(function() {
      return (cameraPreview.value ? cameraPreview.value : cameraPreview.src);
    });

    if(props.mode === 'edit') {
      if(editCam.value) {
        cameraName.value = editCam.value.name;
        cameraUrl.value = editCam.value.url;
        enabled.value = editCam.value.enabled;
        selectedUserGroupIds.value = editCam.value.userGroups;

        const analyticsBackend = getAnalyticsBackend(editCam.value);

        const allAnalyticIds = getAllAID(editCam.value);
        if (allAnalyticIds) {
          setAnalyticsByAID(allAnalyticIds);
        } else {
          console.log('No analytics backend found for camera', editCam.value);
          faceAnalytics.value = false;
          selectedAnalytics.value = [];
        }

        setRecorderComponentsByCamera(editCam.value);

        if(editCam.value.previewImage) {
          cameraPreview.value = editCam.value.previewImage;
        }
        else
        {
          const connectionPayload = {url: cameraUrl.value};
          getCameraStreamPreview(connectionPayload);
        }

        if(editCam.value.matchThresholdOverride) {
          enableMatchThresholdOverride.value = editCam.value.matchThresholdOverride.enabled;
          matchThresholdSlider.value = editCam.value.matchThresholdOverride.threshold * 100;
        }
      }
    }

    function setRecorderComponentsByCamera(camera) {
      try {
        const persistentRecorderConfig = lodashGet(camera, 'vs_config.roc.tracker.video-recordings.persistent-recording', null);
        if(persistentRecorderConfig) {
          if(persistentRecorderConfig.enabled) {
            enableRecorder.value = true;
          }
          else {
            enableRecorder.value = false;
          }
        }
      }

      catch(err) {
        console.log('Error setting recorder components by camera: ', err);
        enableRecorder.value = false;
      }
    }

    function persistentRecorderEnabled(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.video-recordings.persistent-recording.enabled', false);
    }

    function cameraHasFaceAnalytics(camera) {
      const analyticsId = getCameraAlgorithmId(camera);
      if(analyticsId) {
        return AIDHasFaceAnalytics(analyticsId);
      }
      return false;
    }

    function getCameraAlgorithmId(camera) {
      const analytics_backends = getAnalyticsBackend(camera); //lodashGet(camera, 'vs_config.roc.tracker.analytics-backends[0]', []);
      if(analytics_backends) {
        return analytics_backends["algorithm-id"];
      }

      return null
    }

    function getAnalyticsBackend(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.analytics-backends[0]', null);
    }

    function getRecorderConfig(camera) {
      return lodashGet(camera, 'vs_config.roc.tracker.video-recordings', null);
    }

    function setRecorderProperties(recorderConfig, enabled = false) {
      lodashSet(recorderConfig, 'enabled', enabled);
      lodashSet(recorderConfig, 'persistent-recording.enabled', enabled);
    }

    function setRecorderConfig(camera, recorderConfig) {
      lodashSet(camera, 'vs_config.roc.tracker.video-recordings', recorderConfig);
    }

    function setCameraAlgorithmId(camera, analyticsId) {
      const result = lodashSet(camera, 'vs_config.roc.tracker.analytics-backends[0].algorithm-id', analyticsId);
    }

    function setCameraAnalyticsBackend(camera, analyticsBackend) {
      //const result = lodashSet(camera, 'vs_config.roc.tracker.analytics-backends', analyticsBackend);
      return analyticsBackend;
    }

    function arrayIncludesArrayOfValues(analyticsId, analyticsDefinition) {
      try {
        if(analyticsId.some(id => analyticsDefinition.includes(id))) {
          return true;
        }
      }

      catch(err) {
        console.log(err);
      }

      return false;
    }

    function AIDHasFaceAnalytics(analyticsId) {
      if(arrayIncludesArrayOfValues(analyticsId,["ROC_FACE_DETECTION","ROC_FACE_LEGACY_DETECTION"])) {
        return true;
      }
      return false;
    }

    function setAnalyticsByAID(analyticsIds) {
      faceAnalytics.value = analyticsIds.includes('ROC_FACE_DETECTION') ? true : false;

      selectedAnalytics.value = [
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_LICENSE_PLATE_DETECTION']) ? ['License Plate'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TEXT_DETECTION']) ? ['OCR In-The-Wild'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_CAR_DETECTION']) ? ['Vehicle'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_PERSON_DETECTION']) ? ['Pedestrian'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_AIRPLANE_DETECTION']) ? ['Airplane'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_BOAT_DETECTION']) ? ['Boat'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_GUN_DETECTION']) ? ['Gun'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TATTOO_DETECTION']) ? ['Tattoo'] : [],
      ]

      //these are helper values for analytics handling/editing in UI and Advanced Editor
      originalSelectedAnalytics.value = selectedAnalytics.value;
      originalSelectedAnalytics.value.push(faceAnalytics.value ? 'Face' : [])

      originalSelectedAlgs.value = [
        ...analyticsIds.includes('ROC_FACE_DETECTION') ? ['ROC_FACE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_LICENSE_PLATE_DETECTION']) ? ['ROC_LICENSE_PLATE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TEXT_DETECTION']) ? ['ROC_TEXT_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_CAR_DETECTION']) ? ['ROC_CAR_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_PERSON_DETECTION']) ? ['ROC_PERSON_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_AIRPLANE_DETECTION']) ? ['ROC_AIRPLANE_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_BOAT_DETECTION']) ? ['ROC_BOAT_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_GUN_DETECTION']) ? ['ROC_GUN_DETECTION'] : [],
        ... arrayIncludesArrayOfValues(analyticsIds, ['ROC_TATTOO_DETECTION']) ? ['ROC_TATTOO_DETECTION'] : [],
      ]
    }

    function getAllAID(camera) {
      const backends = lodashGet(camera, 'vs_config.roc.tracker.analytics-backends', null);
      const analyticIds = [];
      for (let backend of backends) {
        analyticIds.push( ...backend['algorithm-id'] );
      }
      return analyticIds;
    }

    function getButtonText() {
      let buttonCaption;

      if(props.mode === 'add') {
        buttonCaption = 'Add Camera';
      }
      else if(props.mode === 'edit') {
        buttonCaption = 'Update';
      }

      return buttonCaption;
    }

    async function executeCRUDCommand() {
      if(props.mode === 'add') {
        const newDoc = await getCamera();
        if(Object.keys(newDoc).length !== 0) {
          isButtonDisabled.value = true;
          isCameraCRUD.value = true;
          const result = await store.dispatch("cameras/addCamera", newDoc);
          store.commit('cameras/setEncounterFilterSelectedCameras', []);
          isCameraCRUD.value = false;
          isButtonDisabled.value = false;
          if(result === 0) {
            context.emit("close");
          }
        }
      }
      else if(props.mode === 'edit') {
        let payload = { GUID: props.cameraId };

        let advancedDelta = getAdvancedSettingChanges();
        let normalDelta = await getCameraChanges();

        //Evan - used to be {...advancedDelta, ...normalDelta} - this prevented new changes from being applied
        //because the last param in merging objects using the spread operator gets priority over which value goes into final object
        //so this would have the original value overwrite any advanced editor values, and advanced editor should have priority

        payload.updates = {};
        LodashMerge(payload.updates, normalDelta, advancedDelta)

        isButtonDisabled.value = true;
        isCameraCRUD.value = true;
        const result = await store.dispatch("cameras/updateCameraByUUID", payload);
        store.commit('cameras/setEncounterFilterSelectedCameras', []);
        isCameraCRUD.value = false;
        isButtonDisabled.value = false;

        if(result === 0) {
          context.emit("close");
        }
      }
    }

    function cancelAdvancedSettings() {
      /**
       * Reset the advanced settings form and toggle Displayed.
       */
      advancedEditorValue.value = editCamCopy.value;
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
    }

    function okAdvancedSettings() {
      /**
       * Maintain the form.
       */
      isAdvancedEditorDisplayed.value = !isAdvancedEditorDisplayed.value;
    }

    function getAdvancedSettingChanges() {
      /**
       * Obtain setting changes made in advanced setting editor.
       * Recursively compare key values of advancedEditorValue with editCam.
       */
      let updateDoc = {};

      for (let [key, value] of Object.entries(advancedEditorValue.value)) {
        if(!editCam.value[key]) {
          // new key, add full entry to updatedDoc
          updateDoc[key] = advancedEditorValue.value[key];
        } else {
          getAdvancedSettingChangesHelper(updateDoc, key, advancedEditorValue.value[key], editCam.value[key]);
        }
      }
      return updateDoc;
    }

    function getAdvancedSettingChangesHelper(update, key, advancedEditorVal, editCamVal) {
      /**
       * Recursion helper.
       *
       * @param {Object} update             - updateDoc
       * @param {String} key                - Current key
       * @param          advancedEditorVal  - advancedEditorValue object value for given key
       * @param          editCamVal         - editCam object value for given key
       *
       * @return {Boolean}      - Return whether children have at least one delta.
       */

       // This fixes a bug in the advanced camera editor where sometimes editing the vs_config object removes the tracker object
       // If the array contains objects, continue recursion as normal. If the array contains values, add it to updateDoc.
       if(Array.isArray(advancedEditorVal)) {
        for(let i = 0; i < advancedEditorVal.length; i++) {
          if(typeof(advancedEditorVal[i] !== typeof({}))) {
            if (!isEqual(advancedEditorVal, editCamVal)) {
              update[key] = advancedEditorVal;
              return true;
            }
            else {
              return false;
            }
          }
        }
      }

      if (typeof(advancedEditorVal) !== typeof({})) {
        if (!isEqual(advancedEditorVal, editCamVal)) {
          update[key] = advancedEditorVal;
          return true;
        } else {
          return false;
        }
      }

      update[key] = {};

      let hasDelta = [];
      for (let [nextKey, nextValue] of Object.entries(advancedEditorVal)) {
        let result = getAdvancedSettingChangesHelper(update[key], nextKey, advancedEditorVal[nextKey], editCamVal[nextKey]);
        hasDelta.push(result);
      }
      if (hasDelta.some(e=>e)) {    // If at least one delta (one true) among children
        return true;
      } else {
        delete update[key];
        return false;               // If not at least one delta, delete key
      }
    }

    const selectedAnalyticsDiff = computed(() => {
      let difference = selectedAnalytics.value.filter(function (item) {
        return !originalSelectedAnalytics.value.includes(item);
      })
      if(faceAnalytics.value && !originalSelectedAnalytics.value.includes('Face')){
        difference.push('Face');
      }
      return difference;
    })

    async function getCameraChanges() {
      let updateDoc = {};

      if(cameraName.value !== editCam.value.name) {
        updateDoc.name = cameraName.value;
      }
      if(cameraUrl.value !== editCam.value.url) {
        updateDoc.url = cameraUrl.value;
      }
      if(enabled.value !== editCam.value.enabled) {
        updateDoc.enabled = enabled.value;
      }
      updateDoc.userGroups = selectedUserGroupIds.value;

      let backendNewlySet = false;

      /*
      Evan - update `analytics-backend`in advanced editor with values from UI.
      If there were already analytics saved from existing cam settings,
      leave them alone so custom settings for these analytics that have already been applied can stay.
      */

      let advancedArray = [];

      let selectedAlgs =
        [
          ...faceAnalytics.value ? ['ROC_FACE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('License Plate') ? ['ROC_LICENSE_PLATE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('OCR In-The-Wild') ? ['ROC_TEXT_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Vehicle') ? ['ROC_CAR_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Pedestrian') ? ['ROC_PERSON_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Airplane') ? ['ROC_AIRPLANE_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Boat') ? ['ROC_BOAT_DETECTION'] : [],
          ...selectedAnalytics.value.includes('Gun') ? ['ROC_GUN_DETECTION'] : [],
        ]

      //filter `editCam` for values that were previously saved so custom settings do not get overwritten during edit
      let trackerVar = lodashGet(editCam.value, 'vs_config.roc.tracker.analytics-backends', null);
      for (let i = 0; i < trackerVar.length; i++) {
        //if currently selected analytics and analytics selected from previous session match, add them to `advancedArray`
        if(trackerVar[i]['algorithm-id'].some(id => originalSelectedAlgs.value.includes(id) && selectedAlgs.includes(id))) {
          //push original value instead of default algorithm modality
          advancedArray.push(trackerVar[i])
        }
      }

      //if there analytics added that weren't here in the previous session, pull the default algorithm into `analytics-backend`
      if (selectedAnalyticsDiff.value && selectedAnalyticsDiff.value.length > 0) {
        let x = setCameraAnalyticsBackend(advancedArray,
          [
            ...selectedAnalyticsDiff.value.includes('Face') ? [await getDefaultAlgorithmIdByModality('face')] : [],
            ...selectedAnalyticsDiff.value.includes('License Plate') ? [await getDefaultAlgorithmIdByModality('lpr')] : [],
            ...selectedAnalyticsDiff.value.includes('OCR In-The-Wild') ? [await getDefaultAlgorithmIdByModality('ocr')] : [],
            ...selectedAnalyticsDiff.value.includes('Vehicle') ? [await getDefaultAlgorithmIdByModality('vehicle')] : [],
            ...selectedAnalyticsDiff.value.includes('Pedestrian') ? [await getDefaultAlgorithmIdByModality('pedestrian')] : [],
            ...selectedAnalyticsDiff.value.includes('Airplane') ? [await getDefaultAlgorithmIdByModality('airplane')] : [],
            ...selectedAnalyticsDiff.value.includes('Boat') ? [await getDefaultAlgorithmIdByModality('boat')] : [],
            ...selectedAnalyticsDiff.value.includes('Gun') ? [await getDefaultAlgorithmIdByModality('gun')] : [],
          ]
        );

        for (let i = 0; i < x.length; i++) {
          advancedArray.push(x[i])
        }
      }

      //set the advanced editor value with values collected from above
      lodashSet(advancedEditorValue.value, 'vs_config.roc.tracker.analytics-backends', advancedArray);

      if(enableRecorder.value !== persistentRecorderEnabled(editCam.value)) {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", "face");
        if(result.status === 'success') {
          const defaultConfig = result.value;
          let emulateCamera = {};
          lodashSet(emulateCamera, 'vs_config', result.value);
          const recorderConfig = getRecorderConfig(emulateCamera);
          if(recorderConfig) {
            if(enableRecorder.value) {
              setRecorderProperties(recorderConfig, true);
              setRecorderConfig(updateDoc, recorderConfig);
            }
            else {
              setRecorderProperties(recorderConfig, false);
              setRecorderConfig(updateDoc, recorderConfig);
            }
          }
        }
      }

      if(enableMatchThresholdOverride.value !== editCam.value.matchThresholdOverride.enabled || matchThresholdSlider.value !== (editCam.value.matchThresholdOverride.threshold * 100)) {
        updateDoc.matchThresholdOverride = {};
        updateDoc.matchThresholdOverride.enabled = enableMatchThresholdOverride.value;
        updateDoc.matchThresholdOverride.threshold = matchThresholdSlider.value / 100;
      }

      return updateDoc;
    }

    async function getDefaultAlgorithmIdByModality(modality) {
      try {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", modality);
        if(result.status === 'success') {
          if(result.value.roc) {
            let emulateCamera = {};
            lodashSet(emulateCamera, 'vs_config.roc', result.value.roc)
            return getAnalyticsBackend(emulateCamera);
          }
          else {
            return null;
          }
        }
      }

      catch(err) {
        console.log(err);
      }

      return null;
    }

    async function getCamera() {
      let newDoc = {};
      let vsConfig;
      newDoc.name = cameraName.value;
      newDoc.url = cameraUrl.value;
      newDoc.enabled = enabled.value;
      newDoc.userGroups = selectedUserGroupIds.value;

      /**
       * Sean: We really only need one vs_config but multiple backends, but I added multiple vs_config presets in the
       * database since that was the pattern before. We'll only use one vs_config, but extract the multiple backends from
       * the other configs
       */

      if(faceAnalytics.value) {
        const result = await store.dispatch("cameras/getVSConfigDefaultByModality", "face");
        if(result.status === 'success') {
          vsConfig = result.value;
        }
      }

      for (let analytic of selectedAnalytics.value) {
        if (!vsConfig) {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", analyticsMap[analytic]);
          if(result.status === 'success') {
            vsConfig = result.value;
          }
        } else {
          const result = await store.dispatch("cameras/getVSConfigDefaultByModality", analyticsMap[analytic]);
          // get just the analytics backend of this result.
          const analyticsBackend = result.value.roc.tracker['analytics-backends'][0];

          vsConfig.roc.tracker['analytics-backends'].push(
            analyticsBackend
          );
        }
      }

      if(enableRecorder.value == false) {
        vsConfig.roc.tracker["video-recordings"].enabled = false;
        vsConfig.roc.tracker["video-recordings"]["persistent-recording"].enabled = false;
      }
      newDoc.vs_config = vsConfig;

      newDoc.previewImage = rawPreview;
      newDoc.matchThresholdOverride = {
        enabled: enableMatchThresholdOverride.value,
        threshold: (matchThresholdSlider.value / 100),
      }

      return newDoc;
    }

    async function gotBlur() {
      if(previewUpdateRequired) {
        const connectionPayload = {url: cameraUrl.value};
        await getCameraStreamPreview(connectionPayload);
      }
    }

    async function getCameraStreamPreview(connectionString) {
      isLoadingStreamPreview.value = true;
      try {
        const connectionTestResults = await store.dispatch("cameras/testCameraConnectionString", connectionString);
        if (connectionTestResults.status === 'success') {
          rawPreview = connectionTestResults.preview;
          cameraPreview.value = 'data:image/jpg;base64, ' + connectionTestResults.preview;
        }
        else
        {
          cameraPreview.value = require('@/assets/no-camera-preview.jpg');
        }
        previewUpdateRequired = false;
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoadingStreamPreview.value = false;
    }

    async function checkFormState() {
      if(props.mode === 'edit') {
        const updateDoc = await getCameraChanges();
        if (Object.keys(updateDoc).length !== 0) {
          isButtonDisabled.value = false;
        } else {
          isButtonDisabled.value = true;
        }
      } else {
        isButtonDisabled.value = false;
      }
    }

    watch([
      enabled, cameraName, cameraUrl, faceAnalytics, selectedAnalytics,
      enableRecorder, enableMatchThresholdOverride, matchThresholdSlider, selectedUserGroupIds
    ], () => {
      if (!cameraUrl.value || !cameraName.value || selectedUserGroupIds.value.length == 0 || (!faceAnalytics.value && selectedAnalytics.value.length == 0)) {
        isButtonDisabled.value = true;
      } else {
        checkFormState();
      }
    });

    watch(cameraUrl, () => {
      if (cameraUrl.value) {
        previewUpdateRequired = true;
      }
    });

    function missingPreview(e) {
      //e.target.value = require('@/assets/camera-settings-background.png');
      cameraPreview.value = require('@/assets/camera-settings-background.png');
    }

    const allUserGroups = computed(() => {
      return store.getters['settings/userGroups'];
    });
    initUserGroups();
    function initUserGroups() {
      try {
        // iterate available usergroups to check if selected or readonly
        for (let i=0; i < allUserGroups.value.length; i++) {
          allUserGroups.value[i].value = allUserGroups.value[i]._id;
          // if usergroup is system and user not in system group, mark it disabled
          if (allUserGroups.value[i].isSysAdmin && !userGroupContainsCurrentUser(allUserGroups.value[i])) {
            allUserGroups.value[i].disabled = true;
          } else {
            allUserGroups.value[i].disabled = false;
          }
          if (props.mode === 'add') {
            // if creating, auto select all available usergroups
            selectedUserGroupIds.value.push(allUserGroups.value[i].value);
          }
        }
      } catch (err) {
        console.log(err);
      }
    }

    // check if currently logged in user is a part of provided usergroup
    function userGroupContainsCurrentUser(userGroup) {
      return userGroup.users.find((user) => {
        if (user.email === store.getters['auth/email']) {
          return true;
        }
      });
    }

    function updateSelectedUserGroups(selectedUserGroups) {
      selectedUserGroupIds.value = selectedUserGroups.value;
    }

    const darkMode = computed(() => store.getters['settings/getDarkMode']);

    return {
      cameraName,
      cameraUrl,
      enabled,
      faceAnalytics,
      enableRecorder,
      gotBlur,
      cameraPreviewSrc,
      isLoadingStreamPreview,
      isCameraCRUD,
      getButtonText,
      executeCRUDCommand,
      isButtonDisabled,
      matchThresholdSlider,
      enableMatchThresholdOverride,
      isAdvancedEditorDisplayed,
      editCamCopy,
      advancedEditorHeight,
      checkFormState,
      advancedEditorValue,
      cancelAdvancedSettings,
      okAdvancedSettings,
      analytics,
      selectedAnalytics,
      updateSelectedAnalytics,
      missingPreview,
      allUserGroups,
      selectedUserGroupIds,
      updateSelectedUserGroups,
      darkMode,
      isShowingLearnMoreText,
      openCaptureZonesEditor,
      isCaptureZonesEnabled
    };
  }
};
</script>

<style scoped lang="scss">
.header {
  display: flex;
  justify-content: space-between;
  width: 880px;
  margin: 0 auto;
  height: 100px;
  @include overwatch-body-large;
}

.camera-name-container {
  min-width: 22rem;
}

.video-source-container {
  min-width: 30rem;
}

.searchRectangle {
  float: left;
  height: 44px;
  width: 100%;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
  background-color: var(--overwatch-neutral-500);
  font-size: 14px;
}

.searchRectangle-video-source {
  width: 500px;
  padding-left: .5rem;
}

.toggle-list {
  float: left;
  width: 366px;
}

.stream-preview {
  position: relative;
  float: right;
  width: 32rem;
  height: auto;
}

.stream-preview div {
  position: absolute;

  /* center spinner */
  height: auto;
  width: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stream-preview img {
  max-width: 512px;
  height: auto;
}

.capture-zones {
  display: flex;
  align-items: center;
  background-color: var(--overwatch-background);
  border: 1px solid var(--overwatch-neutral-400);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: var(--spacing-s);
}

.settingsRectangle {
  min-width: 55rem;
  border: 1px solid var(--overwatch-neutral-300);
  @include overwatch-body-med;

}

.settingsHeader {
  @include overwatch-title-xsmall;
  letter-spacing: 0;
  line-height: 18px;
  text-align: center;
  background: var(--overwatch-neutral-500);
}

.inputTitle {
  float: top;
  @include overwatch-body-large;
  margin-bottom: 4px;
}

.addCamera {
  text-transform: unset !important;
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  @include overwatch-body-med;
  margin-left: 20px;
}

.addCameraButton {
  background: var(--overwatch-primary);

  @include overwatch-body-med;
}

.searchInput {
  border: 0px;
  margin-left: 6px;
  width: 90%;
}

.searchInput:hover {
  border: 0px;
}

input.searchInput:focus {
  outline-width: 0;
}

.settingsSection {
  margin: 1rem;
}

.settingsSectionHeader {
  height: 20px;
  width: 299px;
  @include overwatch-title-small;
  line-height: 21px;
}

.settingsSectionDesc {
  width: 343px;
  @include overwatch-body-med;
}


#advancedSettings {
  display: flex;
  flex-flow: column;
}

.btn-container {
  width: 880px;
  margin-top: var(--spacing-l);
  transform: translate(2px, 0);
}

.json-editor :deep(.json-editor-vue),
.json-editor :deep(.json-editor-vue) * {
  font-family: consolas, menlo, monaco, "Ubuntu Mono", source-code-pro, monospace;
  flex: 1;
}

.active-bubble {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 4px 0 var(--overwatch-light-success);
  background-image: linear-gradient(to bottom, #e7f0e6 0%, #7ec778 39%, var(--overwatch-light-success) 100%);
}

.disabled-bubble{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-image: radial-gradient(circle at 50% 50%, #c9c9c9, #c5c5c5 0%, #b8b8b8 32%, var(--overwatch-light-neutral-300) 71%);
}

/* IPAD PORTRAIT and MOBILE */
@media (max-width: 810px) {

  .header {
    flex-direction: column;
    height: auto;
    margin-bottom: 1rem;
    width: 100%;
  }
  .settingsRectangle {
    all: revert;
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .camera-name-container {
    all: revert;
    width: 100%;
  }
  .video-source-container {
    all: revert;
    width: 100%;
  }

  .searchRectangle, .inputTitle {
    margin-left: 0;
    width: 100%;
  }

  .toggle-list {
    all: revert;
    width: 100%;
  }

  .stream-preview {
    width: 100%;
  }

  .stream-preview img {
    width: 100%;
    max-width: revert;
    height: auto;
  }

  .btn-container {
    all: revert;
    float: right;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
}

</style>
