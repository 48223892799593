<template>
  <div class='container' style="width: 100%; border: 0px solid var(--overwatch-error); overflow-y: scroll; height: 100%;">
    <div class="d-flex justify-content-start">
      <div class="watchlist-match align-content-center">
        Watchlist Match
      </div>
    </div>
      <!-- testing css grid -->
    <div class="mygrid" style="margin-top: 20px;">
      <div class="face-title">Encountered Face</div>
      <div class="face-title">{{facematch.firstname}} {{facematch.lastname}} </div>

      <div class="testmatch" :style="bgStyle">Match Score <span>{{matchScore}}</span></div>
      <div class="face-title">
        <span>from</span> {{facematch.watchlistName}}
      </div>

      <auth-img class="face" :src="facematch.probe_tn"></auth-img>
      <auth-img class="face" :src="facematch.candidate_tn"></auth-img>
    </div>

    <div>
      <h2> CAPTURE CONTEXT </h2>
      <div class="d-flex justify-content-center">
        <auth-img class="context-image" :src="facematch.context_image"/>
      </div>
    </div>

    <div>
      <h2> METADATA </h2>
      <div>
        <ul>
          <li>Gender: <span> {{facematch.md.Gender}} </span></li>
          <li>Age: <span>{{facematch.md.Age}}</span></li>
          <li>Glasses: <span>{{facematch.md.Glasses}}</span></li>
          <li>Mask: <span>{{facematch.md.MaskDetection}}</span></li>
          <li>Facial Hair: <span>{{facematch.md.FacialHair}}</span></li>
        </ul>
      </div>
    </div>

    <div class="d-flex justify-content-center" style="margin-top: 20px;">
      <div class="w-100">
        <MDBTextarea label="Notes" rows="3" v-model="notes" style="resize: none;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount, ref, watchEffect } from "vue";
import AuthImg from "@/components/ui/AuthImg";
import { MDBTextarea } from "mdb-vue-ui-kit";
import { useStore } from "vuex";

export default {
  name: "EncounterWatchlistMatch",
  props: {
    id: String,
  },
  components: {
    AuthImg,
    MDBTextarea
  },
  setup(props) {
    const isLoading = ref(false);
    const store = useStore();
    const watchlistedFace = ref(null);
    const identityNotes = ref('');

    const contextImage = ref();
    const facematch = ref({md:{}});

    watchEffect(async ()=>{
      await getWatchlistFaceMatch(props.id);
    })

    const notes = ref(facematch.notes);
    const bgStyle = ref(setBackgroundColorByScore());

    async function getWatchlistFaceMatch(id) {
      isLoading.value = true;
      const url = `/rest/v1/face/encounters/facematch/${id}`

      const httpPayload = {
        method: 'GET'
      };

      const responseJSON  = await store.dispatch("auth/fetchJSON", {url: url, payload: httpPayload}, {root: true});
      if(responseJSON.status === 'success') {
          facematch.value = responseJSON.encounter;
      }
      isLoading.value = false;
    }

    onBeforeUnmount(async ()=> {
      //await getWatchlistedFaceDetails(facematch.watchlistedFaceId);
      await store.dispatch("watchlists/setWatchlistedFaceMatchNotes", {
        id: facematch.id,
        notes: notes.value,
        personId: facematch.personId,
      });
    });

    const matchScore = computed( function() {
      if (facematch.value.similarity != null) {
        return ((facematch.value.similarity) * 100).toFixed(1);
      }
      else return null;
    });

    function setBackgroundColorByScore() {
      const similarity = matchScore;
      let bgStyle = 'background: var(--roc-watch-similarity-90);';
      if (similarity) {
        if (similarity < 90 && similarity >= 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70);';
        }
        else if (similarity < 70) {
          bgStyle = 'background: var(--roc-watch-similarity-0);';
        }
      }
      return bgStyle;
    }


    return {
      matchScore,
      setBackgroundColorByScore,
      bgStyle,
      notes,
      isLoading,
      watchlistedFace,
      identityNotes,
      contextImage,
      facematch,
      getWatchlistFaceMatch
    };
  }
};
</script>

<style scoped lang="scss">
.mygrid {
  display: grid; 
  grid-template-columns: 50% 50%;
  justify-items: center;
  /* align-items: center; */
}

.testmatch {
  box-sizing: border-box;
  border-radius: 14px;
  border: 1px solid var(--overwatch-neutral-100);
  padding: 0 10px;
  
  margin-bottom: 10px;
}

.face {
  height: 300px; 
  width: auto;

  margin: 0 auto;
}
.face-title {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
}

.image-quality-score {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-xsmall;
}

.watchlist-match {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
}

.match-score {
  height: 45px;
  width: 45px;
  background-color: var(--overwatch-success); /*EEEFF4*/
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  margin-left: 20px;
  font-size: 16px;
  padding-top: 8px;
  padding-left: 4px;
}

.context-image {
  width: auto;
  height: 300px;
}

h2 {
  @include overwatch-body-med;
  color: gray;

  margin-top: 40px;
}

ul {
  padding: 0;
  list-style-type: none;
}

li:nth-child(odd) {
  background-color: lightgray;
}


/* MOBILE */
@media (max-width: 480px) {
  .face {
    width: 100%;
    height: auto;
  }
  .context-image {
    width: 100%;
    height: auto;
  }

  .container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  }
  .container::-webkit-scrollbar { 
      display: none;  /* Safari and Chrome */
  }

}
</style>
