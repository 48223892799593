<template>
  <div class="content">
    <div style="padding-left: var(--spacing-s); margin-left: 50px; margin-top: 50px;">
      <RocPill leftText="Users" rightText="User Groups" @pill-toggled="router.push('/settings/user-groups')"></RocPill>
    </div>
    <search-header button-title='Filter' disableText="Maximum number of users created" :use-dropdown="true" 
    @search-filter-change='setSearchFilter' searchTextPlaceHolder="Search Users" :transparentBackground=true :keepButtonTitle="true"
    >
      <template v-slot:leftnav>
        <div class="title">
          <div style="display: flex; align-items: center;">
            Users <span class="group-amount">{{ users.length }}</span>
          </div>
          <RocButton
            size="dialogButton"
            type="primary"
            :disabled="!canAddUser"
            @click="showAddUser"
          >
          Create
          </RocButton>
      </div>
      </template>
      <template v-slot:dropdown-slot>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterAdmin" @click.stop="addFilter('Admin',filterAdmin)"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Admin</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filterPowerUser" @click.stop="addFilter('Power',filterPowerUser)"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Power User</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="viewOnly" @click.stop="addFilter('View',viewOnly)"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">View Only</span>
          </div>
        </MDBDropdownItem>
        <MDBDropdownItem href="" @click.prevent="() =>{}">
          <div class="d-flex flex-row">
            <RocCheckbox v-model="enrollOnly" @click.stop="addFilter('Enroll',enrollOnly)"/>
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">Enroll Only</span>
          </div>
        </MDBDropdownItem>
      </template>
    </search-header>
  <div class="users">
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div class="camera-container" v-if="users.length">
      <div class="align-self-center selectAll">
        <RocCheckbox v-model="selectedAll"/> 
        <label class="align-self-center">Select All</label>
        <RocButton style="box-shadow: none;" v-if="selectedAll || selectedUsers.length > 0" type='tertiary' size="sm" @click="isShowingDeleteUser = !isShowingDeleteUser"> 
          <div style="color: var(--overwatch-error);">
            <RocIcon 
              icon="trash"
              color="red"
              size="sm"
            />
            <span style="text-decoration: underline; padding-left: var(--spacing-base);">Delete Selected</span>
          </div>
        </RocButton>
      </div>
      <div class='d-flex justify-content-center'>
        <div class="d-flex header flex-column">
          <div class="d-flex justify-content-between" style="height: 100%; padding-left: 100px;">
              <div class="align-self-center" style="flex: 1; ">
                Name
              </div>
              <div class="align-self-center" style="flex: 1;">
                Email
              </div>
              <div class="align-self-center" style="flex: 2;">
                User Group
              </div>
              <div class="align-self-center" style="flex: 1.5;">
                <div class="d-flex justify-content-between align-items-center">
                  Role
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class='d-flex justify-content-center' v-for="user in displayRows" :key="user._id">
        <UserCard :user="user" :selected="selectedAll" @toggle-user-state="toggleEnabledState" :userRole="getUserRole(user._userAccessId)"
        @edit-user="showEditUser(user)" @delete-user="showDeleteUser(user)" @selected-user="selectUser" 
        />
      </div>
    </div>
    <base-dialog v-if="isShowingAddUser" :show='true' title='New User' @close='isShowingAddUser = false;' :style="userCRUDStyle">
      <UserCRUD @close="isShowingAddUser = false;" @save="reload"/>
    </base-dialog>
    <base-dialog v-if="isShowingEditUser" :show='true' title='Edit User' @close='isShowingEditUser = sfalse;' :style="userCRUDStyle">
      <UserCRUD @close="isShowingEditUser = false;" @save="reload" :user="currentEditedUser"/>
    </base-dialog>
    <base-dialog v-if="isShowingDeleteUser" :show="true" title="Delete User(s)" @close="closeDeleteHandler" :style="userDeleteStyle">
      <DeleteConfirmation
        @close="closeDeleteHandler"
        @delete="deleteHandler(currentEditedUser ? currentEditedUser : 'BulkDelete')"
        message="Yes"
      >
      <div class='d-flex justify-content-start'>
        <RocIcon icon="trash" color="red" size="md" style="margin-right: 5px;"/>
        <div v-if="currentEditedUser">Are you sure you want to delete user 
          <div style="text-decoration: underline;">{{currentEditedUser.firstname}} {{currentEditedUser.lastname}}</div>
        </div>
          <div v-else-if="selectedAll"> Are you sure you want to delete all users? </div>
          <div v-else> 
            Are you sure you want to delete &nbsp;
              <div style="text-decoration: underline;">
               {{ getSelectedUsers()}}
              </div>
          </div>
      </div>
      </DeleteConfirmation>
    </base-dialog>
    <RocToast v-if="displayToast" :message=message @autoClose="() => {displayToast = !displayToast}"/>
  </div>
  <RocPageSelector class="stick-to-bottom" :currPage=currPage :lastPage=lastPage @goToPage="changePage"/>
  </div>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import SearchHeader from "@/components/ui/SearchHeader";
import UserCard from "@/components/settings/UserCard";
import UserCRUD from "@/components/settings/UserCRUD";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import BaseDialog from "@/components/ui/BaseDialog";
import RocButton from "@/components/ui/RocButton.vue";
import RocCheckbox from '@/components/ui/RocCheckbox.vue';
import RocPageSelector from "@/components/ui/RocPageSelector.vue";
import { MDBDropdownItem } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocPill from "@/components/ui/RocPill.vue";
import RocToast from "@/components/ui/RocToast.vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "UsersList",
  components: {
    BaseDialog,
    SearchHeader,
    UserCard,
    UserCRUD,
    DeleteConfirmation,
    UserCRUD,
    RocButton,
    RocCheckbox,
    RocPageSelector,
    RocIcon,
    MDBDropdownItem,
    RocPill,
    RocToast,
  },
  setup() {
    const isLoading = ref(false);
    const isShowingAddUser = ref(false);
    const isShowingEditUser = ref(false);
    const isShowingDeleteUser = ref(false);
    const currentEditedUser = ref(null);        // The user being edited or deleted
    const users = ref([]);
    const store = useStore();
    const searchFilterText = ref('');
    const canAddUser = ref(false);
    const currPage = ref(1);
    const lastPage = ref(0);
    const itemsPerPage = 6;
    const selectedAll = ref(false);
    const showfilterOptions = ref(false);
    let selectedUsers = ref([]);
    const router = useRouter();
    const route = useRoute();
    //filter vars
    const filterAdmin = ref(false);
    const filterPowerUser = ref(false);
    const viewOnly = ref(false);
    const enrollOnly = ref(false);
    const roles = ref([]);
    const displayToast = ref(false);
    let filterArr = ref([]);
    const message = ref(" ");

    onMounted(async () => {
      await reload(); 
    });

    function setSearchFilter(filterText) {
      searchFilterText.value = filterText;
    }

    function addFilter(val,add){
      if(!add){
        filterArr.value.push(val);
      }
      else {
        filterArr.value = filterArr.value.filter((currVal)=> {
          if(currVal === val){
            return false;
          } else return true;
        })
      }
    }

    function showAddUser() {
      isShowingAddUser.value = true;
    }

    function changePage(value) {
      currPage.value = value;
    }

    loadUserAccess();
    async function loadUserAccess() {
      const response = await store.dispatch('settings/getUserAccess');
      if (response && response.userAccess) {
        roles.value = response.userAccess;
      }
    }

    function getUserRole(accessID) {
      for (let i=0; i < roles.value.length; i++) {
        if (roles.value[i]._id === accessID)
        {
          return roles.value[i].label;
        }
      }
    }

    const displayRows = computed(function() {
      //first row is where to start displaying, and rows is the last row.
      let lastRow = (currPage.value * (itemsPerPage));
      let firstRow = (lastRow - itemsPerPage );
      let tempUserArray = [];
      if((searchFilterText.value != '') || (filterArr.value.length > 0)){ //for some reason this never is true when the array is full. and you remove an item.... lol;
        tempUserArray = filterUsers();
      } 
      else {
        tempUserArray = users.value;
      }
      //-1 since array starts at 0
      return tempUserArray.filter((user,index) => {
        if(index <= (lastRow - 1) && index >= firstRow) 
          return true
      })
    });

    async function loadUsers() {
      isLoading.value = true;

      try  {
        const userResponse = await store.dispatch('settings/getUsers');
        if(userResponse.status === 'success') {
          users.value = userResponse.users;
          lastPage.value = Math.ceil(users.value.length / (itemsPerPage));
        }
      }

      catch(err) {
        console.log(err);
      }
      isLoading.value = false;
    }

    function filterUsers (){
      const usersArray = users.value;
      if((searchFilterText.value != '') && (filterArr.value.length > 0)){
        return usersArray.filter((user) => {
          const combinedName = user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase();
          if(combinedName.includes(searchFilterText.value.toLowerCase()) && filterArr.value.includes(getUserRole(user._userAccessId).split(" ")[0])) {
          return true;
        }
        })
      } else if((searchFilterText.value.trim() === '') && (filterArr.value.length > 0)){
        return usersArray.filter((user) => {
          if(filterArr.value.includes(getUserRole(user._userAccessId).split(" ")[0])){
            return true;
          } else return false;
        })
      }
      else {
        return usersArray.filter((user) => {
          const combinedName = user.firstname.toLowerCase() + ' ' + user.lastname.toLowerCase();
          if(combinedName.includes(searchFilterText.value.toLowerCase())) {
            return true;
          }
        })
      }
    }

    async function toggleEnabledState(user) {
      const userUpdate = {
        email: user.email,
        enabled: !user.enabled,
      }
      await updateUser(user, userUpdate);
    }

    async function disableUser(user) {
      const userUpdate = {
        email: user.email,
        enabled: false,
      }
      await updateUser(user, userUpdate);
    }

    async function updateUser(user, updatedUser) {
      try  {
        const response = await store.dispatch('settings/updateUser', updatedUser);
        if(response.status === 'success') {
          Object.assign(user, response.user);
        }
      }

      catch(err) {
        console.log(err);
      }
    }

    async function deleteUser(user) {
      const payload = {
        email: user.email
      };
      try  {
       return await store.dispatch('settings/deleteUser', payload);
      }
      catch(err) {
        console.log(err);
      }
    }

    async function deleteHandler(user) {
      if(user === "BulkDelete"){
        const deletionArr = [];
        const usersToDelete  = selectedAll.value ? users.value : selectedUsers.value;
        let usersDeleted = 0;
          for(const u of usersToDelete){
            if(u.email !== store.getters['auth/email'])
            {
              deletionArr.push(deleteUser(u).then(res => { 
                if(res.status === 'success')
                  usersDeleted++;
              }));
            }
          }
          await Promise.all(deletionArr).then(() =>
          {
            message.value = usersDeleted + " users deleted"
          });
        await reload();
        selectedUsers.value.splice(0);
      } else {
        message.value = user.firstname + ' ' + user.lastname + ' Has been deleted'
        await deleteUser(user);
        currentEditedUser.value = null;
        await reload();
      }
      displayToast.value = true;
    }

    function deleteUsers(){
      isShowingDeleteUser.value = true;
    }

    async function showEditUser(user) {
      currentEditedUser.value = user;
      isShowingEditUser.value = true;
    }

    function showDeleteUser(user) {
      currentEditedUser.value = user;
      isShowingDeleteUser.value = true;
    }

    function closeDeleteHandler(){
      isShowingDeleteUser.value = false;
      currentEditedUser.value = null;
    }


    function selectUser(userObj) {
      if(userObj.value === false){
        selectedUsers.value.push({email: userObj.user.email, userName: userObj.user.firstname + ' ' + userObj.user.lastname});
      }
      else {
        selectedUsers.value = selectedUsers.value.filter((user)=> {
          if(user.email === userObj.user.email){
            return false;
          }
          else {
            return true;
          }
        })
      }
    }

    // Dialog styles
    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const userCRUDStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          'width': '100%',
          'max-height': '100%'
        }
      } else {
        return {
          'width': '700px',
          'max-height': '100%'
        }
      }
    });

    const userDeleteStyle = computed(() => {
      if (windowWidth.value <= 480) {
        return {
          width: '100%'
        }
      } else {
        return {
          width: '500px'
        }
      }
    });

    async function reload() {
      canAddUser.value = await store.dispatch('settings/canAddUser');
      await store.dispatch('settings/loadUserGroups');
      await loadUsers();
    }

    function getSelectedUsers(){
      let count = 0;
       const displayedUserList = selectedUsers.value.map((user) => {
        count ++;
        if(count < 3)
        {
          return user.userName ;
        }
      });

      if(count > 2)
        return  displayedUserList[0] + ', ' + displayedUserList[1] + ', ' + (count - 2) + ' other(s)';
      else if(count == 2)
        return  displayedUserList[0] + ', ' + displayedUserList[1];
      else if(count == 1) 
        return displayedUserList[0];

    }
    return {
      canAddUser,
      isLoading,
      isShowingAddUser,
      isShowingEditUser,
      isShowingDeleteUser,
      currentEditedUser,
      setSearchFilter,
      showAddUser,
      showEditUser,
      showDeleteUser,
      selectUser,
      users,
      reload,
      toggleEnabledState,
      filterUsers,
      searchFilterText,
      deleteUser,
      disableUser,
      deleteHandler,
      currPage,
      lastPage,
      changePage,
      displayRows,
      userCRUDStyle,
      userDeleteStyle,
      selectedAll,
      selectedUsers,
      showfilterOptions,
      filterPowerUser,
      filterAdmin,
      viewOnly,
      enrollOnly,
      addFilter,
      getUserRole,
      deleteUsers,
      displayToast,
      message,
      router,
      route,
      getSelectedUsers,
      closeDeleteHandler
    }
  }
};
</script>

<style scoped lang="scss">

.selectAll {
  display: flex;
  gap: var(--spacing-s);
  padding-bottom: var(--spacing-m);
}

.content{
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 0;
}

.users {
  min-width: 35rem;
  flex: 1 0 auto;
}

.stick-to-bottom {
  flex-shrink: auto;
}

.title{
  gap: var(--spacing-l);
  padding-left: var(--spacing-base);
  display: flex;
}

.header {
  width: 100% !important;
  min-width: 25rem;
  display: block;
  padding: 16px 20px 16px !important;
  margin: 0;
  background: var(--overwatch-button-primary-20);
  margin-right: 0rem !important;
  border: 1px solid var(--overwatch-button-primary);
  border-radius:var(--spacing-base) var(--spacing-base) 0 0;
  @include overwatch-title-xsmall;
}

.filter-options {
  background: var(--overwatch-neutral-500);
  box-shadow: 0 1px 5px 0 rgba(0, 19, 58, 0.3);
}

.camera-container {
  max-width: 100%;
  margin-left: 55px;
  margin-right: 65px;
  margin-top: 20px;
  min-width: 450px;
  position: relative;
}

.group-amount {
  color:var(--overwatch-neutral-200); 
  padding-left: var(--spacing-s);
  @include overwatch-body-large;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.go-to {
  margin-left: 30px; 
  @include overwatch-body-med;
  color: var(--overwatch-neutral-100);
}

@media (max-width: 480px) {
  .users {
    min-width: 0;
    padding-left: var(--spacing-s);
  }
  .camera-container {
    margin: 0;
    min-width: 650px;
    overflow-x: scroll;
  }

  .title{ 
    padding-left: var(--spacing-s);
    padding-bottom: var( --spacing-s);
    display: flow;
  }

}
</style>
