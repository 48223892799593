<template>
  <div class='camera-list'>
    <base-dialog :show='showDialog' :title='crudTitle' @close='handleError' :style="cameraCRUDStyle">
      <camera-crud :mode="cameraCRUDMode" :camera-id="cameraEditGUID" @close="showDialog = false;"></camera-crud>
    </base-dialog>
    <search-header class="stick-to-top" button-title="Add Camera" :disableButton="!canAddCamera" disableText="Maximum number of cameras created" title='Cameras' @search-filter-change='setSearchFilter'
    @button-clicked='addCameraClicked()'>
      <template v-slot:leftnav>
        Cameras
      </template>
    </search-header>
    <div v-if='isLoading'>
      <roc-spinner/>
    </div>
    <div class='camera-container' v-if='hasCameras'>
      <camera-view v-for='camera in cameras' :key='camera.GUID'
                    :camera-guid='camera.GUID'
                    :camera-name='camera.name'
                    :camera-state='camera.state'
                    :camera-online='camera.online'
                    :preview="camera.previewImage"
                    @camera-state-change="cameraStateChange()"
                    @edit-camera="editCameraClicked"></camera-view>
    </div>
  </div>
</template>

<script>
import SearchHeader from "@/components/ui/SearchHeader";
import CameraView from "@/components/cameras/CameraView";
import CameraCrud from "@/components/cameras/CameraCRUD";

import { useStore } from "vuex";
import { computed, onBeforeUnmount, onMounted, ref, watch } from "vue";

export default {
  name: 'CamerasList',
  components: {
    SearchHeader,
    CameraView,
    CameraCrud
  },
  setup() {
    const store = useStore();
    const isLoading = ref(false);
    const searchFilterText = ref('');
    const error = ref(null);
    const showDialog = ref(false);
    const windowWidth = ref(window.innerWidth);
    const canAddCamera = ref(false);

    let socket;
    onMounted(async () => {
      const payload = 'feed=cameras&topic=all';
      socket = await store.dispatch("auth/getSocketIO", payload);
      socket.on('all', (payload) => {
        store.commit('cameras/setCameraState', payload);
      });

      canAddCamera.value = await store.dispatch('settings/canAddCamera');
    });

    onMounted(() => {
      window.addEventListener('resize', ()=>{windowWidth.value = window.innerWidth;});
    });

    onBeforeUnmount(() => {
      socket.close();
    });

    const cameraCRUDMode = ref('edit');
    const cameraEditGUID = ref(null);
    const crudTitle = ref('Add Camera');

    loadCameras();

    async function loadCameras(refresh = false) {
      isLoading.value = true;
      try {
        await store.dispatch('cameras/loadCameras', {
          forceRefresh: refresh
        });

      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      isLoading.value = false;
    }

    function addCameraClicked() {
      showDialog.value = !showDialog.value;
      cameraCRUDMode.value = 'add';
      crudTitle.value = 'Add Camera';
    }

    function editCameraClicked(cameraGUID) {
      cameraCRUDMode.value = 'edit';
      cameraEditGUID.value = cameraGUID;
      showDialog.value = !showDialog.value;
      crudTitle.value = 'Edit Camera';
    }

    function cameraStateChange() {
    }

    function setSearchFilter(filterText) {
      searchFilterText.value = filterText;
    }

    const cameras = computed(function() {
      let cameraList = store.getters['cameras/cameras'];

      cameraList.sort((a, b) => {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        if(nameA < nameB) {
          return -1
        }
        else if(nameA > nameB) {
          return 1;
        }

        return 0;
      });

      return searchFilterText.value != '' ? cameraList.filter((camera) => {
        if (camera.name.toLowerCase().includes(searchFilterText.value.toLowerCase())) {
          return true;
        }
      }) : cameraList;
    });

    const hasCameras = computed(function() {
      return !isLoading.value && store.getters['cameras/cameras'];
    });

    async function handleError() {
      error.value = null;
      showDialog.value = false;
      canAddCamera.value = await store.dispatch('settings/canAddCamera');
    }

    const cameraCRUDStyle = computed(() => {
      if (windowWidth.value <= 810) {
        // 810px to include iPad s
        return {
          height: '100%',
          width: '100%',
          'overflow-y': 'auto',
        }
      } else {
        return {
          width: '950px'
        }
      }
    });

    return {
      canAddCamera,
      isLoading,
      cameras,
      hasCameras,
      setSearchFilter,
      error,
      handleError,
      showDialog,
      cameraStateChange,
      addCameraClicked,
      cameraCRUDMode,
      cameraEditGUID,
      editCameraClicked,
      crudTitle,
      cameraCRUDStyle

    };
  }
};
</script>

<style scoped>

.camera-list {
  min-width: 55rem;
}

.camera-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: 2rem;
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

/* IPAD PORTRAIT */
@media only screen and (max-width: 810px) and (orientation: portrait) {
  .camera-list {
    min-width: 0;
  }
}

/* MOBILE */
@media (max-width: 480px) {
  .camera-list {
    min-width: 0;
    width: 100%;
    height: 100%;
  }

  .camera-container {
    margin: 0;
    height: 100%;
  }
  .search-header {
    width: 100%;
  }

}

</style>
