<template>
  <MDBDropdownItem tag="button">
    <slot></slot>
  </MDBDropdownItem>
</template>

<script>
import {MDBDropdownItem} from 'mdb-vue-ui-kit';

export default {
  name: 'RocDropdownItem',
  components: {
    MDBDropdownItem
  }
}
</script>

<style scoped>

</style>