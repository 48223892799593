export default {
  cameras(state) {
    return state.cameras;
  },
  shouldUpdate() {
    return true;
  },
  findByGUID: state => guid => {
    return state.cameras.find(camera => camera.GUID === guid);
  },
  encounterFilterSelectedCameras(state) {
    return state.encounterFilterSelectedCameras;
  },
  isLiveCameraPopupVisible(state) {
    return state.isLiveCameraPopupVisible;
  },
};
