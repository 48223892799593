<template>
  <div tabindex="1" id="mydiv" ref="mydiv" class="center-screen header" style="max-height: 100%;">
    <div style="padding: 16px; cursor: move; z-index: 10;" id="mydivheader" ref="mydivheader">
      <div class='d-flex justify-content-between'>
        <div style="width: 30px;"></div>
        <div v-if="watchlistType === 'entitlements'" class='standard-text'>Create Enroll Invites</div>
        <div v-else class='standard-text'>
          {{title}}<span v-if="mode === 'create'"> - {{watchlistType}}</span>
        </div>
        <div v-if="mode === 'create'">
          <MDBBtn color='var(--overwach-primary)' floating style='box-shadow: none !important;' @click='hideComponent'>
            <RocIcon icon='minimize' size="sm"/>
          </MDBBtn>
        </div>
        <div v-else>
          <MDBBtn color='var(--overwach-primary)' floating style='box-shadow: none !important;' @click='closeComponent'>
            <RocIcon icon='exit' size="sm"/>
          </MDBBtn>
        </div>
      </div>
    </div>
    <div class="d-flex flex-column" style="height: 100%; overflow-y: auto; overflow-x: hidden; padding-right: 10px;" v-if="watchlistType != 'entitlements'">
      <div v-if='isWatchlistCRUD' style="position: absolute; margin-left: 28rem; margin-top: 32.4rem;">
        <roc-spinner/>
      </div>
      <!--<transition mode='in-out' name='details'>-->
      <div v-show="!isEnrolling && mode === 'create'">
        <div style="margin-bottom: 0rem;">
          <div class='inputTitle'>Name</div>
          <div class='textInputArea large-area' style="border-radius: 5px; padding: 6px">
            <div style='margin-top: 2px;'>
              <input class='searchInputName' type='text' v-model.trim="name"/>
            </div>
          </div>
        </div>
        <div class='d-flex flex-column' style="padding-top: 6px;">
          <div class='inputTitle'>{{showColorPicker ? 'Watchlist Color' : 'Watchlist Description'}}</div>
          <div class="d-flex flex-row align-items-center">
            <div v-if="!showColorPicker" style="width: 100%;">
              <div class='textInputArea large-area'>
                <div style='margin-top: 2px;'>
                  <textarea rows="2" style="margin: 12px;" class='searchInput' type='text' v-model.trim="desc"/>
                </div>
              </div>
            </div>
            <WatchlistColorPicker :selectedColor="watchlistColor" @color-picked="setWatchlistColor" @showing-picker="showColorPicker = true"/>
          </div>
        </div>
      </div>
      <div v-if="!isEnrolling && watchlistType === 'face'" style="padding-top: 4px;">
        <div class='inputTitle'>Enrollment Notes</div>
        <div class='textInputArea large-area'>
          <div class='align-self-center' >
            <textarea rows="2" style="margin: 12px;" class='searchInput' type='text' v-model.trim="notesFaceDefault"
              placeholder="Add notes for all enrolled faces"/>
          </div>
        </div>
      </div>
      <div v-if="!isEnrolling && mode === 'create'" style="padding-top: 6px;">
        <div class='inputTitle'>User Groups</div>
        <filterMultiSelect 
          mode="tags" :close-on-select="false"
          placeholder-text="Select User Groups" :available-items="allUserGroups" 
          :currently-selected="selectedUserGroupIds" @selection-changed="updateSelectedUserGroups" :enableClear="false"
        />
      </div>
      <!--</transition>-->
      <div v-if="watchlistType === 'face' || watchlistType === 'tattoo'" style="flex:1; margin-top: 10px; flex-grow: 1;">
        <div style="display: flex; flex-direction: column; height: 100%; width: 100%">
          <div v-show="isEnrolling" style="border: 1px solid var(--overwatch-neutral-300); border-radius: 6px; width:95%; margin-left: 16px;">
            <div class="d-flex flex-wrap justify-content-start previewContainer" style="height: 118px;margin-left: 18px">
              <div>
                <auth-img v-for='enrollment in enrollmentPreviews' :key="enrollment.id" :src='enrollment.url' style="max-height: 55px; width: auto;"/>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div style="width: 94.5%;">
                <MDBProgress :height="20">
                  <MDBProgressBar :value="enrollmentProgress" :max="totalFilesSelected">
                    {{ Math.round((enrollmentProgress / totalFilesSelected) * 100) }}%
                  </MDBProgressBar>
                </MDBProgress>
              </div>
            </div>
            <div class='d-flex justify-content-between progressDetails' style="width: 100%; margin-top: 2px;">
              <div></div>
              <div>{{ enrollmentProgress }} / {{ totalFilesSelected }}</div>
              <div></div>
            </div>
          </div>
          <div class="d-flex">
            <div class="d-flex flex-grow-1">
              <RocButton v-if="mode === 'create' && noFiles" :disabled="!canCreate" @click="enrollEmpty('face')" style="margin-bottom: var(--spacing-xs);">
                Create Without Content
              </RocButton>
            </div>
            <div v-if="!isEnrolling" class='d-flex align-items-center checkboxTitle' title="This will run in the background to create matches on previous encounters">
              <RocCheckbox ref="reverseMatchCheckbox" v-model="reverseMatch" :formCheck="false"/>
              <div style="cursor:pointer; margin-left: var(--spacing-base)" @click="reverseMatch = !reverseMatch">Enable Reverse Match</div>
            </div>
          </div>
          <WatchlistFileSelector 
            @enroll="triggerEnrollment"  
            @close="reset" 
            :button-title="btnTitle" 
            :button-disabled="buttonToggle || (mode === 'create' && !canCreate && btnTitle != 'Close')" 
            style="flex:1;"
          />
        </div>
      </div>
      <div v-else-if="watchlistType === 'plate'" style="display: flex; flex-direction: column; width: 95%; margin-left: 16px; margin-top: 10px; border: 0px solid black; flex: 1">
        <div class="d-flex" style="margin-bottom: 12px;">
          <div class="d-flex flex-grow-1 align-items-end">
            <div class="vehicles-text">Vehicles</div>
          </div>
          <div class="d-flex align-items-center">
            <RocButton v-if="mode === 'create' && vehicles.length === 0"
              @click="enrollEmpty('text')" 
              :disabled="buttonToggle || !canCreate" 
              style="width: 100%; margin-bottom: var(--spacing-xs);">
              Create Without Content
            </RocButton>
          </div>
        </div>
        <div class="justify-content-start flex-wrap added-plates-container" 
          style="width: 100%; max-height: 350px; min-height: 100px; flex: 1; overflow: auto; overflow-x: hidden; border: 1px solid gray; padding: 10px">
          <watchlisted-license-plate-c-r-u-d 
            v-for="(vehicle, i) in vehicles" 
            :key="vehicle.plateNumber" 
            @delete="removePlateCRUD" 
            :refIndex="i" 
            mode="viewonly" 
            :p_collapsed="true"
            :p_plate-number="vehicle.plateNumber"
            :p_fullname="vehicle.fullname"
            :p_color="vehicle.color"
            :p_make="vehicle.make"
            :p_model="vehicle.model"
            :p_year="vehicle.year"
            :p_notes="vehicle.notes">
          </watchlisted-license-plate-c-r-u-d>
          <!--<div style="width: 100px; height: 100px; border: 1px solid var(--overwatch-error);" @click="doSomething"></div>-->
        </div>
        <div style="margin-top: 10px;">
          <watchlisted-license-plate-c-r-u-d mode="adder" @add="addPlate">
          </watchlisted-license-plate-c-r-u-d>
        </div>
        <div style="text-align: right; border: 0px solid black; width: 100%; padding-bottom: 10px">
          <RocButton ref="enrollButton" @click="enrollText" :disabled="buttonToggle || (mode === 'create' && !canCreate && btnTitle != 'Close')">{{ btnTitle }}</RocButton>
        </div>
      </div>
    </div>
    <EntitlementImporter v-else @close="closeComponent"></EntitlementImporter>
  </div>
</template>

<script setup>
  import { computed, onMounted, ref, watch } from "vue";
  import { MDBBtn, MDBProgress, MDBProgressBar } from "mdb-vue-ui-kit";
  import { useStore } from "vuex";
  import WatchlistFileSelector from "@/components/watchlists/WatchlistFileSelector";
  import AuthImg from "@/components/ui/AuthImg";
  import WatchlistedLicensePlateCRUD from "@/components/watchlists/WatchlistedLicensePlateCRUD";
  import EntitlementImporter from "@/components/events/EntitlementImporter";
  import WatchlistColorPicker from "@/components/watchlists/WatchlistColorPicker";
  import filterMultiSelect from "@/components/ui/filterMultiSelect";
  import RocCheckbox from "@/components/ui/RocCheckbox";
  import RocIcon from "@/components/ui/RocIcon";
  import RocButton from "@/components/ui/RocButton";

  const emits = defineEmits(["close", "newEnrollment"]);
  const store = useStore();
  const isWatchlistCRUD = ref(false);
  const name = ref(null);
  const desc = ref(null);
  const mydiv = ref(null);
  const mydivheader = ref(null);
  const isEnrolling = ref(false);
  const enrollmentProgress = ref(0);
  const totalFilesSelected = ref(0);
  const enrollmentPreviews = ref([]);
  const enrollmentResults = ref([]);
  const btnTitle = ref('Start Enrollment');
  const buttonToggle = ref(false);
  const title = ref('Watchlist Creator');
  const vehicles = ref([]);
  const notesFaceDefault = ref(null);
  const mode = computed(() => { return store.getters["watchlists/importerMode"]; });
  const id = computed(() => { return store.getters["watchlists/importerWatchlistId"]; });
  const reverseMatch = ref(false);
  const watchlistColor = ref("#FCFCFC");
  const showColorPicker = ref(false);
  const canCreate = ref(false);

  function addPlate(plate) {
    vehicles.value.push(plate);
  }

  function removePlateCRUD(index) {
    vehicles.value.splice(index, 1);
  }

  const watchlistType = computed(function () {
    return store.getters["watchlists/importerType"];
  });

  updateTitle();
  function updateTitle() {
    const importerMode = store.getters['watchlists/importerMode'];
    if(importerMode === 'create'){ title.value = 'Watchlist Creator'; return; }

    if(watchlistType.value === 'face' && importerMode === 'add') {
      title.value = "Add Faces";
    } else if(watchlistType.value === 'tattoo' && importerMode === 'add'){
      title.value = 'Add Tattoos'
    } else if(importerMode === 'add_text') {
      title.value = "Add Plate";
    }
  }

  watch([mode, watchlistType], () => {
    updateTitle();
  });


  async function enrollText() {
    if(btnTitle.value === 'Close') {
      reset();
      return;
    }

    if(mode.value === 'create') {
      if(name.value === "" || name.value === null) {
        alert("Please enter a name for your watchlist!")
        return
      }
      isEnrolling.value = true;
      btnTitle.value = "In Progress";
      buttonToggle.value = true;
      try {
        await store.dispatch('watchlists/createWatchlist', {
          info : {
            name: name.value,
            description: desc.value,
            enabled: true,
            type: 'text',
            color: watchlistColor.value,
            userGroups: selectedUserGroupIds.value
          },
          licensePlates: vehicles.value,
        });
        store.commit('watchlists/setEncounterFilterSelectedWatchlists', []);
      }

      catch(e) {
        console.log("Error: ", e);
      }

      btnTitle.value = "Close";
      buttonToggle.value = false;
      isEnrolling.value = false;
    }
    else if( mode.value === 'add_text') {
      isEnrolling.value = true;
      btnTitle.value = "In Progress";
      buttonToggle.value = true;
      try  {
        await store.dispatch('watchlists/enrollPlates', {
          watchlistId: id.value,
          licensePlates: vehicles.value,
        });
        store.commit('watchlists/setEncounterFilterSelectedWatchlists', []);
      }

      catch(e) {
        console.log("Error: ", e);
      }

      btnTitle.value = "Close";
      buttonToggle.value = false;
      isEnrolling.value = false;
      setTimeout(() => {
        reset()
      }, 1000)
    }
  }

  const noFiles = computed(function () {
    return store.getters["watchlists/noFiles"]
  })

  async function enrollEmpty(type) {
    if(name.value === "" || name.value === null) {
      alert("Please enter a name for your watchlist!")
      return
    }
    try {
      await store.dispatch('watchlists/createWatchlist', {
        info: {
          name: name.value,
          description: desc.value,
          enabled: true,
          type: type,
          color: watchlistColor.value,
          userGroups: selectedUserGroupIds.value
        }
      });
      store.commit('watchlists/setEncounterFilterSelectedWatchlists', []);
      reset();
    }
    catch(error) {
      error.value = error.message || "Something went wrong!";
    }
  }

  async function triggerEnrollment(payload) {
    // iterate files payload to set default notes if none were added
    for (let i=0; i < payload.files.value.length; i++) {
      if(!payload.files.value[i].notes && notesFaceDefault.value) {
        payload.files.value[i].notes = notesFaceDefault.value;
      }
    }
    if(mode.value === 'create') {
      if(name.value === "" || name.value === null) {
        alert("Please enter a name for your watchlist!")
        return
      }
      try {
        payload.page.value = 1;
        isEnrolling.value = true;
        btnTitle.value = "In Progress";
        buttonToggle.value = true;
        totalFilesSelected.value = payload.files.value.length;
        await store.dispatch('watchlists/createWatchlist', {
          info : {
            name: name.value,
            description: desc.value,
            enabled: true,
            type: watchlistType.value,
            color: watchlistColor.value,
            userGroups: selectedUserGroupIds.value
          },
          files: payload.files,
          progress: enrollmentProgress,
          enrollmentPreviews: enrollmentPreviews,
          page: payload.page,
          reverseMatch: reverseMatch.value
        });
        store.commit('watchlists/setEncounterFilterSelectedWatchlists', []);
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
        console.error(error);
      }
      btnTitle.value = "Close";
      buttonToggle.value = false;
    }
    else if(mode.value === 'add') {
      try {
        store.commit('watchlists/setNoFiles', true)
        payload.page.value = 1;
        isEnrolling.value = true;
        btnTitle.value = "In Progress";
        buttonToggle.value = true;
        totalFilesSelected.value = payload.files.value.length;
        const dispatchType = watchlistType.value === 'face' ? 'watchlists/enrollFacesToWatchlist' : 'watchlists/enrollTattoosToWatchlist';
        await store.dispatch(dispatchType, {
          watchlistId: id.value,
          files: payload.files,
          progress: enrollmentProgress,
          enrollmentPreviews: enrollmentPreviews,
          page: payload.page,
          enrollmentResults: enrollmentResults,
          reverseMatch: reverseMatch.value
        });
      } catch (error) {
        error.value = error.message || 'Something went wrong!';
      }
      btnTitle.value = "Close";
      buttonToggle.value = false;
    }
  }

  watch(enrollmentResults.value, () => {
    if(enrollmentResults.value.length) {
      emits("newEnrollment", enrollmentResults.value.pop());
    }
  })

  function hideComponent() {
    store.dispatch("watchlists/toggleWatchlistImporter", false);
   // document.removeEventListener('keydown', handleEscapePress,true);
  }

  function closeComponent() {
    emits("close");
  }

  function reset() {
    store.commit('watchlists/setNoFiles', true);
    name.value = "";
    desc.value = "";
    notesFaceDefault.value = "";
    isEnrolling.value = false;
    btnTitle.value = "Start Enrollment";
    enrollmentPreviews.value = [];
    vehicles.value = [];
    enrollmentProgress.value = 0;
    if(mode.value === 'create') {
      hideComponent();
    }
    else {
      closeComponent();
    }
  }
  const windowWidth = ref(window.innerWidth);
  onMounted(() => {
    // the DOM element will be assigned to the ref after initial render
    //crudParent.value.appendChild(ComponentApp);

    dragElement(mydiv.value);
    window.addEventListener('resize', () => {
      windowWidth.value = window.innerWidth;
    });
  })

  /* Stop body from scrolling when dialog is up. */
  const showWatchlistImport = computed(function() {
    return store.getters['watchlists/importerVisible'];
  });

  watch(showWatchlistImport, () => {
    if (showWatchlistImport.value) {
      dialogSetup();
    } else {
      cleanup();
    }
  });

  function dialogSetup() {
    let body = document.querySelector('body');
    body.classList.add("dialog-open"); 

    // Listen for refresh. Run cleanup on refresh so that body is scrollable.
    window.addEventListener('beforeunload', cleanup);
  }

  function cleanup() {
    window.removeEventListener('beforeunload', cleanup);

    let body = document.querySelector('body');
    body.classList.remove("dialog-open");
  }

  function dragElement(elmnt) {
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
      e = e || window.event;
      e.preventDefault();
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      document.onmouseup = closeDragElement;
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
      e = e || window.event;
      e.preventDefault();
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;
      pos3 = e.clientX;
      pos4 = e.clientY;
      // set the element's new position:
      elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
      elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null;
      document.onmousemove = null;
    }
  }

  function setWatchlistColor(colorCode) {
    watchlistColor.value = colorCode;
    showColorPicker.value = false;
  }

  const selectedUserGroupIds = ref([]);
  const allUserGroups = computed(() => {
    return store.getters['settings/userGroups'];
  });
  watch(allUserGroups, () => {
    initUserGroups();
  });
  async function initUserGroups() {
    try {
      selectedUserGroupIds.value = [];
      // iterate available usergroups to check if selected or readonly
      for (let i=0; i < allUserGroups.value.length; i++) {
        allUserGroups.value[i].value = allUserGroups.value[i]._id;
        // if usergroup is system and user not in system group, mark it disabled
        if (allUserGroups.value[i].isSysAdmin && !userGroupContainsCurrentUser(allUserGroups.value[i])) {
          allUserGroups.value[i].disabled = true;
        } else {
          allUserGroups.value[i].disabled = false;
        }
        if (mode.value === 'create') {
          // if creating, auto select all available usergroups
          selectedUserGroupIds.value.push(allUserGroups.value[i].value);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  // check if currently logged in user is a part of provided usergroup
  function userGroupContainsCurrentUser(userGroup) {
    return userGroup.users.find((user) => {
      if (user.email === store.getters['auth/email']) {
        return true;
      }
    });
  }
  
  function updateSelectedUserGroups(selectedUserGroups) {
    selectedUserGroupIds.value = selectedUserGroups.value;
  }

  watch([name, selectedUserGroupIds], () => {
    if (name.value && selectedUserGroupIds.value.length > 0) {
      canCreate.value = true;
    } else {
      canCreate.value = false;
    }
  });

</script>

<style>
  /* Stop body from scrolling. */
  body.dialog-open {
    overflow: hidden;
  }
</style>

<style scoped lang="scss">

#mydiv {
  display: flex;
  flex-direction: column;
  min-width: 50rem; 
  z-index: 999; 
  position: absolute; 
  border: 1px solid var(--overwatch-neutral-300); 
  background: var(--overwatch-secondary);
  text-align: center;
  padding: 12px;
  height: fit-content;
  /* min-height: 95vh; */
  max-height: 95vh !important;
}

.header {
  width: 50rem;
  height: 164px;
  @include overwatch-title-large;
}

.textInputArea {

  width: 98%;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  @include overwatch-body-med;
  border-radius: 5px;
}

.large-area {
  height: auto;
  resize: none;
}

.inputTitle {
  @include overwatch-body-large;
  margin-bottom: 4px;
  margin-top: 12px;
  text-align: start;
}

.checkboxTitle {
  @include overwatch-body-med;
  text-align: start;
  margin-bottom: 5px;
}

.searchInput {
  border: 0px;
  margin-left: 2px;
  width: 98%;
  @include overwatch-body-med;
}

.searchInputName {
  border: 0px;
  margin-left: 2px;
  width: 98%;
  @include overwatch-body-med;
  padding: 6px;
}

.searchInput::placeholder {
  @include overwatch-body-med;
  color: var(--overwatch-neutral-300);
}

.searchInput:hover {
  border: 0px;
}

input.searchInput:focus {
  outline-width: 0;
}

.center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  padding: 2px;
}

textarea {
  resize: none;
}

textarea::placeholder{
  color: var(--overwatch-neutral-300);
}

.progressDetails {
  height: 20px;
  width: 299px;
  @include overwatch-body-xsmall;
  line-height: 21px;
}

.previewContainer{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  overflow: hidden;
  margin-top: 1px;
  margin-bottom: 1px;
  max-width: 100%;
}

img {
  margin: 2px;
  border-radius: 4px;
}

/*details*/
.details-enter-active {
  transition: all 0.3s ease-out;
}

.details-leave-active {
  transition: all 0.3s ease-in;
}

.details-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.details-enter-to,
.details-leave-from {
  opacity: 1;
  transform: translateY(50%);
}

#mydivheader {
  padding: 10px;
  cursor: move;
  z-index: 10;
}

.vehicles-text {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-xsmall;
  font-weight: 600;
}

/* MOBILE */
@media (max-width: 480px) {
  #mydiv {
    position: fixed;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    left: 50%;

    min-width: revert;
    min-height: revert;

    box-sizing: border-box;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px;

    /* iphone */
    padding-top: env(safe-area-inset-top);
  }
}

</style>
