<template>
  <div class="case-management-page">
    <div class="overwatch-title-large title">
      Mission Dashboard
      <RocButton size="mega" @click="showImporter">
        Create
      </RocButton>
    </div>

    <div class="sorting-filters">
      <div class="active-mission">
        <!-- <div>
          Active Mission
        </div>
        <RocIcon
        color="blue"
          size="sm"
          icon="tooltip"
        />
        <RocSingleSelect
          class="mission-select"
          customSearchSelectMode
          :availableItems="missionOptions"
          placeholderText="Active Mission"
        /> -->
      </div>
      <RocDashboardFilters
        @text-filter-changed="textFilterChanged"
        @sort-changed="sortChanged"
        @filter-changed="filterChanged"
      />
    </div>

    <RocTable templateColumns="repeat(6, 1fr)" class='datatable'>
      <template #header>
        <div>
          <span>Name</span>
          <span># Cameras</span>
          <span># Files</span>
          <span>Progress</span>
          <span># Watchlists</span>
          <span>Clustering</span>
        </div>
      </template>
      <template #entries>
        <CaseCard class="centralize" v-for="(c, i) in filteredCases"
          :key="c"
          :case="c"
          @edit-case="handleEditCase(c)"
          :trigger="triggers[i]"
        />
      </template>
    </RocTable>

    <BaseDialog
      v-if="isShowingEditCase"
      show
      @close="isShowingEditCase = false"
      title="Edit Case"
    >
      <CaseEditDetails :case="editingCase"
        @close="isShowingEditCase = false"
        @delete-camera="triggerCaseCardUpdate(filteredCases.findIndex(c => c._id === editingCase._id))"
      />
    </BaseDialog>

  </div>
</template>

<script>
import { ref, computed, reactive, watch, onMounted } from 'vue';
import { useStore } from 'vuex';

import RocButton from '@/components/ui/RocButton';
import BaseDialog from "@/components/ui/BaseDialog";
import CaseEditDetails from "@/components/cases/CaseEditDetails";
import RocTable from '@/components/ui/RocTable';
import CaseCard from '@/components/cases/CaseCard.vue';
import RocSingleSelect from '@/components/ui/RocSingleSelect.vue';
import RocIcon from '@/components/ui/RocIcon.vue';
import RocDashboardFilters from '@/components/ui/RocDashboardFilters.vue';

export default {
  name: 'MissionDashboard',
  components: {
    RocButton,
    BaseDialog,
    CaseEditDetails,
    RocTable,
    CaseCard,
    RocSingleSelect,
    RocIcon,
    RocDashboardFilters
},
  setup(props, context) {
    const store = useStore();

    const cases = computed(() => {
      return store.getters['cases/cases'];
    });

    const missionOptions = [
      {name: 'Mission1'},
      {name: 'Mission2'},
      {name: 'Mission3'},
      {name: 'Mission4'}
    ];

    function showImporter() {
      store.commit('cases/setEditingCase', null);
      store.commit('cases/setImporterVisible', true);
    }

    const editingCase = ref();
    const isShowingEditCase = ref(false);
    function handleEditCase(c) {
      editingCase.value = c;
      isShowingEditCase.value = true;
    }

    const triggers = ref([]);
    watch(cases, nv => {
      triggers.value = nv.map(() => 0);
    })
    function triggerCaseCardUpdate(i) {
      triggers.value[i]++;
    }

    onMounted(async () => {
      await getCaseStatuses();

      if (store.getters['watchlists/watchlists'].length === 0) {
        await store.dispatch('watchlists/loadWatchlists');
      }
    });

    /** Filters */

    const searchFilterText = ref('');
    function textFilterChanged(text) {
      searchFilterText.value = text;
    }

    const currentlySelectedSort = ref('');
    function sortChanged(sort) {
      currentlySelectedSort.value = sort;
    }

    const currentlySelectedFilter = ref('');
    async function filterChanged(filter) {
      currentlySelectedFilter.value = filter;

      // Calling get case statuses here so that
      // we can receive case statuses before
      // filtering on them
      await getCaseStatuses();
    }

    const filteredCases = computed(() => {
      var currentCases = cases.value;

      /**
       * Sorting:
       * - newest
       * - oldest
       * - alpha
       * - reverse-alpha
       */
      var sorter;
      switch (currentlySelectedSort.value) {
        case 'newest':
          sorter = (a,b) => {
            return a.createdAt > b.createdAt ? -1 : 1
          };
          break;
        case 'oldest':
          sorter = (a,b) => {
            return b.createdAt > a.createdAt ? -1 : 1;
          };
          break;
        case 'alpha':
          sorter = (a,b) => {
            return b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1;
          };
          break;
        case 'reverse-alpha':
          sorter = (a,b) => {
            return a.name.toLowerCase() > b.name.toLowerCase() ? -1 : 1
          };
          break;
        default:
          sorter = null;
      }
      if (sorter) {
        currentCases.sort(sorter);
      } else {
        currentCases.sort();
      }


      /**
       * Filtering:
       * - all
       * - complete
       * - in progress
       */
      var filterer;
      switch (currentlySelectedFilter.value) {
        case 'all':
          break;
        case 'complete':
          filterer = (c) => {
            return isCaseFinished(c._id)
          };
          break;
        case 'in progress':
          filterer = (c) => {
            return !isCaseFinished(c._id)
          };
      }
      if (filterer) {
        currentCases = currentCases.filter(filterer);
      }

      /**
       * Text filtering
       */
      return searchFilterText.value && currentCases ?
        currentCases.filter(c => c.name.toLowerCase().includes(searchFilterText.value.toLowerCase()))
        : currentCases;
    });

    const caseStatuses = reactive({});
    async function getCaseStatuses() {
      // Get media statuses for all cases.
      // Store in caseStatuses object.
      for (let c of cases.value) {
        const response = await store.dispatch("cases/getCaseStatus", {caseId: c._id});
        if (response.status === 'success') {
          caseStatuses[c._id] = response.result;
        }
      }
    }

    function isCaseFinished(id) {
      // Count the media statuses of the given case.
      // If media that are completed or errored out equal entire media count,
      // consider it finished.
      const media = caseStatuses[id] ? caseStatuses[id] : [];

      var completeCount = 0;
      var mediaCount = 0;
      for (let m of media) {
        mediaCount++;
        if (m.status === 'completed' || m.status === 'error') {
          completeCount++;
        }
      }

      return completeCount === mediaCount;
    }

    return {
      cases,
      filteredCases,
      showImporter,
      triggers,
      triggerCaseCardUpdate,
      handleEditCase,
      missionOptions,
      textFilterChanged,
      sortChanged,
      filterChanged,
      getCaseStatuses,
      isShowingEditCase,
      editingCase
    }

  }
}

</script>

<style scoped lang="scss">
.case-management-page {
  padding: var(--spacing-xl) var(--spacing-xxl);
  background-color: var(--roc-global-light-background);

}

.title {
  display: flex;
  gap: var(--spacing-xl);
  align-items: center;
}


.sorting-filters {
  display: flex;
  height: 45px;
  width: 100%;
  justify-content: space-between;
  margin-top: $--spacing-s;
}

.active-mission {
  display: flex;
  align-items: center;
  min-height: 100%;
  height: auto;
  gap: $--spacing-s;
}

.active-mission .mission-select {
  height: 100%;
  width: 350px;
}

.datatable {
  margin-top: $--spacing-s;
}
.dashboard {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(6, 1fr);
  justify-items: stretch;
  margin-top: var(--spacing-s);
}

.dashboard * span:first-child {
  padding-left: var(--spacing-l);
}

.dashboard * span:last-child {
  padding-right: var(--spacing-l);
}
</style>