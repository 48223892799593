<script setup>
import { defineProps, ref } from 'vue';
import RocIcon from "@/components/ui/RocIcon";

const props = defineProps({
    files: {
        type: Boolean,
        default: false,
        note: "Allow file upload"
    },
    folders: {
        type: Boolean,
        default: false,
        notes: "Allow folder upload"
    },
    oneFile: {
      type: Boolean,
      default: false,
    }

});

const emits = defineEmits(['onDrop']);

    const filesIndividual = ref();
    const filesDirectory = ref();

    function onDrop(e){
      const files = e.dataTransfer?.files;
      emits('onDrop',[...files]);
    }

    function selectIndividual() {
      filesIndividual.value.click();
    }

    function selectDirectory() {
      filesDirectory.value.click();
    }

    function selectFile(e) {
      const files = e.target.files;
      emits('onDrop',[...files]);
    }

</script>

<template>
      <div class="rectangle-copy"
        @drop.prevent="onDrop" @dragover.prevent="onDragOver" @dragleave.prevent="offDragOver">
        <div  class="choose-files-or-drag">
            <div>
                <RocIcon icon="upload" color="primary"></RocIcon>
                <input ref="filesIndividual" type="file" style="display: none" @change="selectFile($event)" multiple/>
                <input ref="filesDirectory" webkitdirectory mozdirectory msdirectory odirectory directory multiple type="file" style="display: none" @change="selectFile($event)"/>
            </div>
            <div class="overwatch-title-xsmall">Drag & Drop or 
              <a v-if="files" @click.prevent="selectIndividual" style="color: var(--overwatch-primary); cursor: pointer;">Choose Files</a>
              <a v-if="oneFile" @click.prevent="selectIndividual" style="color: var(--overwatch-primary); cursor: pointer;">Choose File</a>
              <a v-if="folders" @click.prevent="selectDirectory" style="color: var(--overwatch-primary); cursor: pointer;">or Directory</a>
              to upload
            </div>
            <div class="imgTypes overwatch-title-xsmall">.JPG,.PNG,.GIF,.SVG</div>
        </div>
      </div>
</template>

<style scoped lang="scss">

.rectangle-copy {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: 3px dashed var(--overwatch-light-button-primary-20);
  background-color: var(--overwatch-light-button-primary-20);
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose-files-or-drag {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-large;
  line-height: 35px;
  text-align: center;
  
  align-items: center;
}

.imgTypes {
  color: var(--overwatch-light-accent);
}


</style>
