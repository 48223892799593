export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setClusters(state, payload) {
    state.clusters = payload;
  },
  concatClusters(state, payload) {
    state.clusters = state.clusters.concat(payload);
  },
  setPage(state, payload) {
    state.page = payload;
  },
  replaceCluster(state, payload) {
    const index = state.clusters.findIndex(c => {
      return c._id === payload._id
    });

    if (index >= 0) {
      state.clusters.splice(index, 1, payload);
    }
  },
  removeClusterById(state, payload) {
    const index = state.clusters.findIndex(c => {
      return c._id === payload
    });

    if (index >= 0) {
      state.clusters.splice(index, 1);
    }
  },
  setVideoPlaybackEncounter(state, payload) {
    state.videoPlaybackEncounter = payload;
  },
  setAttributeFilters(state, payload) {
    state.attributeFilters = payload;
  },
  setCameraFilters(state, payload) {
    state.cameraFilters = payload;
  },
  setWatchlistMatchFilter(state, payload) {
    state.watchlistMatchFilter = payload;
  },
  setDtRangeFilter(state, payload) {
    state.dtRangeFilter = payload;
  },
  setMission(state, payload) {
    state.mission = payload;
  },
  setAllFaceAttributes(state, payload) {
    state.allFaceAttributes = payload;
  },
  setNameFilter(state, payload) {
    state.nameFilter = payload;
  },
  setSorting(state, payload) {
    state.sorting = payload;
  },
  setMoreToFetch(state, payload) {
    state.moreToFetch = payload;
  },
  setAbortController(state, payload) {
    state.abortController = payload;
  },
  setAssociatesTargetCluster(state, payload) {
    state.associatesTargetCluster = payload;
  },
  setAssociatesTimeRangeFilter(state, payload) {
    state.associatesTimeRangeFilter = payload;
  },
  setAssociatesSameFrameFilter(state, payload) {
    state.associatesSameFrameFilter = payload;
  },
  setAssociatesFilterPopupFields(state, payload) {
    state.associatesFilterPopupFields = payload;
  }
}