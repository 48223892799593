<template>
  <div class="d-flex flex-column" style="  border-radius: 5px; border: solid 1px var(--overwatch-neutral-300); padding: 15px; margin-bottom: 10px;">
    <div class="fieldGroup">
      <div style="font-size: 12px; margin-bottom: var(--spacing-m); ">
        <span>Plate Number</span>
        <RocInput v-model="plateNumber" :disabled="isViewOnly" :placeholder="'Plate Number'" style='background: var(--overwatch-neutral-500);'
          type='text'/>
      </div>
      <div style="font-size: 12px;  margin-bottom: var(--spacing-m);">
        <span>Full Name</span>
        <RocInput v-model="fullname" :disabled="isViewOnly" :placeholder='"Name of the vehicle"' style='background: var(--overwatch-neutral-500);'
          type='text' />
      </div>
      <div style="font-size: 12px;  margin-bottom: var(--spacing-m);">
        <span>Color</span>
          <RocInput v-model="color" :disabled="isViewOnly" :placeholder='"Color of the vehicle"' style='background: var(--overwatch-neutral-500);'
            type='text'/>
      </div>
      <div v-if="isViewOnly || isEnrollEncounter">
        <div v-if="collapsed">
          <RocIcon icon="downArrow" size="sm" style="margin-left: 5px;" @click="collapsed = false;"></RocIcon>
        </div>
        <div v-else>
          <RocIcon icon="downArrow" size="sm" style="margin-left: 5px; transform: rotate(180deg)" @click="collapsed = true;"></RocIcon>
        </div>
      </div>
    </div>
    <div v-if="!collapsed" class="fieldGroup">
      <div style="font-size: 12px;  margin-bottom: var(--spacing-m);">
        <span>Make</span>
        <RocInput v-model="make" :disabled="isViewOnly" :placeholder='"Make of the vehicle"' style='background: var(--overwatch-neutral-500);'
          type='text'/>
      </div>
      <div style="font-size: 12px;  margin-bottom: var(--spacing-m);">
        <span>Model</span>
        <RocInput v-model="model" :disabled="isViewOnly" :placeholder='"Model of the vehicle"' style='background: var(--overwatch-neutral-500);'
          type='text' />
      </div>
      <div style="font-size: 12px;  margin-bottom: var(--spacing-m);">
        <span>Year</span>
        <RocInput v-model="year" :disabled="isViewOnly" :placeholder='"Year of the vehicle"' style='background: var(--overwatch-neutral-500);'
          type='text'/>
      </div>
      <div v-if="isViewOnly" style="visibility:hidden;"> 
        <div>
          <RocIcon icon="downArrow" size="sm" style="margin-left: 5px;" @click="collapsed = false;"></RocIcon>
        </div>  
      </div>
    </div>
    <div v-if="!collapsed" class="d-flex justify-content-start" style="width: 100%; margin-top: 10px; gap: 10px">
      <div style="font-size: 12px; width: 100%">
        <p> Notes</p>
        <RocTextArea 
        v-model="notes" 
        :disabled="isViewOnly" 
        rows='3' 
        :placeholder='"Add note for this vehicle"' 
        style='background: var(--overwatch-neutral-500); resize: none;'/>
      </div>
      <div v-if="isViewOnly" style="visibility:hidden;"> 
        <div>
          <RocIcon icon="downArrow" size="sm" style="margin-left: 5px;" @click="collapsed = false;"></RocIcon>
        </div>  
      </div>
    </div>
    <div v-if="isViewOnly" class="d-flex justify-content-start disable-select" style="font-size: 14px; margin-left: 2px; margin-top: 5px; margin-left: 10px; cursor: pointer;" @click="removeClicked">remove</div>
    <div v-else-if="isAdder" class="d-flex justify-content-end" style="gap: var(--spacing-m)">
      <RocButton class="d-flex justify-content-end" style="margin-top: 10px;" :type="'primary'" @click="plateAdded"> Add Vehicle </RocButton>
    </div>
  </div>
  <!--<hr style="width: 98%;"/>-->
</template>

<script>
import { ref, computed, watch } from "vue";
import { debounce } from "lodash";
import RocInput from "@/components/ui/RocInput.vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocTextArea from '@/components/ui/RocTextArea.vue';
import RocIcon from '@/components/ui/RocIcon.vue';

export default {
  name: "WatchlistedLicensePlateCRUD",
  emits: ['delete', 'add'],
  props: {
    refIndex: {
      type: Number,
    },
    mode: {
      type: String,
    },
    p_plateNumber: {
      type: String,
    },
    p_fullname: {
      type: String,
    },
    p_color: {
      type: String,
    },
    p_make: {
      type: String,
    },
    p_model: {
      type: String,
    },
    p_year: {
      type: String,
    },
    p_collapsed: {
      type: Boolean,
    },
    p_notes: {
      type: String,
    },
  },
  components: {
    RocInput,
    RocTextArea,
    RocIcon,
    RocButton
  },
  setup(props, context) {
    const plateNumber = ref(props.p_plateNumber);
    const fullname = ref(props.p_fullname);
    const color = ref(props.p_color);
    const make = ref(props.p_make);
    const model = ref(props.p_model);
    const year = ref(props.p_year);
    const notes = ref(props.p_notes);
    const collapsed = ref(props.p_collapsed);

    function getParentStyle() {
      let style = "width: 98%; margin-top: 10px;";
      if(isAdder.value) {
        //style += 'background-color: #DBDBE2;';
      }
      return style;
    }

    function toJSON() {
      return {
        plateNumber: plateNumber.value,
        fullname: fullname.value,
        color: color.value,
        make: make.value,
        model: model.value,
        year: year.value,
        notes: notes.value
      }
    }

    function plateAdded() {
      context.emit('add', toJSON());

      // Clear values after adding
      plateNumber.value = '';
      fullname.value = '';
      color.value = '';
      make.value = '';
      model.value = '';
      year.value = '';
      notes.value = '';
    }

    function removeClicked() {
      context.emit("delete", props.refIndex);
    }

    const isViewOnly = computed(() => {
      return props.mode === 'viewonly';
    });
    const isAdder = computed(() => {
      return props.mode === 'adder';
    });
    const isEnrollEncounter = computed(() => {
      return props.mode === 'enrollEncounter';
    });

    // watch changes to data, debounced (to avoid too many emits while typing)
    // this is only used when enrolling a plate from an encounter, which updates the data in place
    watch([plateNumber, fullname, color, make, model, year, notes], 
      debounce(() => {
        if (isEnrollEncounter.value) {
          context.emit('add', toJSON());
        }
      }, 500)
    );

    return {
      plateNumber,
      fullname,
      color,
      make,
      model,
      year,
      toJSON,
      removeClicked,
      isViewOnly,
      plateAdded,
      getParentStyle,
      collapsed,
      notes,
      isAdder,
      isEnrollEncounter
    };
  }
};
</script>

<style scoped lang="scss">

.plate-card {
  height: 130px;
  width: 98%;
  background-color: var(--overwatch-neutral-300);
}

.searchRectangle {
  height: 35px;
  width: 400px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
 @include overwatch-body-small;
  margin-right: 10px;
}

.textInputArea {
  height: 70px;
  width: 400px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  @include overwatch-body-small;
  margin-left: 1rem;
  margin-right: 1rem;
  resize: none;
}

.searchInput {
  border: 0px;
  margin-left: 2px;
  width: 98%;
}

.fieldGroup {
  display: flex;
  justify-content: start;
  width: 100%; 
  margin-top: 10px; 
  gap: 10px;
}

/* Solid border */
hr.solid {
  border-top: 3px solid var(--overwatch-neutral-400);
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (max-width: 480px) {

.fieldGroup{
  display: flow;
}
}

</style>