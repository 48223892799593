<template>
  <svg
    :class="{
      'black': color === 'black',
      'white': color === 'white',
      'primary':  color === 'primary',
      'gray':  color === 'gray',
      'red': color === 'red',
      'none': color === 'none',
      'buttonPrimary': color === 'buttonPrimary',
      'flip': flip,
      'rotate90': rotate90,
      'rotate270': rotate270,
    }"
    :height="computedSize"
    :width="computedSize"
  >
    <use
      :href="`${computedIconPath}#icon`"
    />
  </svg>
</template>

<script>
/**
 * Component for displaying ROC Core Library Icons.
 *
 * After importing a .svg file from Zeplin, please do the following:
 * 1. Remove 'icons_' from the name of the file.
 * 2. Add a ` id="icon" ` to the top level <svg> tag in the file
 * 3. Remove the height and width from the same <svg> tag
 * 4. Change the ` fill ` of the <path> tag to ` fill="currentColor" `.
 *
 * These changes allow for this component to take control of the height, width, and color
 * of the external SVG.
 *
 * Apparently new specifications for SVG will come out soon called SVG2
 * that might make modifying nested SVGs a little bit more obvious so
 * probably worth keeping an eye out for that soon?
 *
 * Sean Choi 01/11/2024
 */
import { computed } from 'vue';

export default {
  name: 'RocIcon',
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', 'white', 'primary', 'gray', 'red', 'none', 'buttonPrimary'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['xxs', 'xs', 'sm', 'md', 'lg', 'match', 'buttonPrimary'].includes(value);
      }
    },
    icon: {
      type: String,
      required: true
    },
    flip: {
      type: Boolean
    },
    customSize: {
      type: [String, Number]
    },
    rotate90: {
      type: Boolean
    },
    rotate270: {
      type: Boolean
    }
  },
  setup(props) {
    const computedSize = computed(() => {
      if (props.customSize) {
        return props.customSize;
      } else {
        switch (props.size) {
          case 'xxs':
            return '8'
          case 'xs':
            return '12'
          case 'sm':
            return '16'
          case 'md':
            return '24'
          case 'lg':
            return '32'
          //allows the icon to be the size of the parent container
          case 'match':
            return '100%'
        }
      }
    });


    const computedIconPath = computed(() => {
      const icons = require.context('@/assets/img/icons');
      return icons(`./${props.icon}.svg`);
    });

    return {
      computedSize,
      computedIconPath
    }
  }
}
</script>

<style scoped>
.black {
  color: var(--overwatch-neutral-100);
}

.white {
  color: var(--overwatch-button-text);
}

.primary {
  color: var(--overwatch-primary)
}

.buttonPrimary {
  color: var(--overwatch-button-primary)
}

.gray {
  color: var(--overwatch-neutral-300)
}

.red {
  color: var(--overwatch-error)
}

/* Used to match the color styling of the parent div's text */
.none {
  color: none;
}

.flip {
  transform: scaleX(-1) rotate(180deg);
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate270 {
  transform: rotate(270deg);
}

</style>
