export default {
  resetState (state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setRetention(state, payload) {
    state.retention = payload;
  },
  setSpoofThreshold(state, payload) {
    state.spoof_threshold = payload;
  },
  setServerConfig(state, payload) {
    state.server_config = payload;
  },
  setDeviceInfo(state, payload) {
    state.deviceInfo = payload;
  },
  setDeviceToken(state, payload) {
    state.deviceInfo.token = payload;
  },
  setIsEnrollButtonEnabled(state, payload) {
    state.is_enroll_button_enabled = payload;
  },
  setIsExamineButtonEnabled(state, payload) {
    state.is_examine_button_enabled = payload;
  },
  setIsWatchEnabled(state, payload) {
    state.is_watch_enabled = payload;
  },
  setLicenseFlags(state, payload) {
    state.flags = payload;
  },
  setTaggedFacesWatchlistConfig(state, payload) {
    state.taggedFacesWatchlistConfig = payload;
  },
  setFaceTaggingEnabled(state, payload) {
    state.faceTaggingEnabled = payload;
  },
  setReverseSearchObjModalities(state, payload) {
    state.reverseSearchObjModalities = payload;
  },
  setWatchlistColors(state, payload) {
    state.watchlistColors = payload;
  },
  setWatchlistDefaultColor(state, payload) {
    state.watchlistDefaultColor = payload;
  },
  setEncounterCardUX(state, payload) {
    state.encounterCardUX = payload
  },
  setUserGroups(state, payload) {
    state.userGroups = payload;
  },
  setWatchlistAdditionalInfoFields(state, payload) {
    state.watchlistAdditionalInfoFields = payload;
  },
  setDarkMode(state, payload) {
    state.darkMode = payload;
  },
};
