<template>
  <div class="fileSelectBox">
    <div class="d-flex justify-content-center align-items-center rectangle-drag" 
      @drop.prevent="onDrop" @dragover.prevent="onDragOver" @dragleave.prevent="offDragOver">
      <div v-if="!isDragging" class="choose-files-or-drag">
        <input ref="manifestFileSelector" type="file"  accept=".csv"
          style="display: none" 
          @change="selectFile($event)" />
        Choose <a @click.prevent="selectManifestFile" style="color: var(--overwatch-primary); cursor: pointer;"> CSV File </a> or drag here
      </div>
      <div v-else class="choose-files-or-drag">
        Drop CSV Here
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import csvHelper from "@/js/csvHelper";

export default {
  name: "EntitlementFileSelector",
  emit: ["csvAdded"],
  props: { },
  components: { },
  setup(props, context) {
    const manifestFileSelector = ref(null);
    const isDragging = ref(false);

    function selectManifestFile() {
      manifestFileSelector.value.click();
    }

    async function selectFile(e) {
      const manifestFile = e.target.files[0];
      e.target.value = null;
      if(manifestFile) {
        await processFile(manifestFile);
      }
    }

    async function processFile(manifestFile) {
      const manifestData = await readAsText(manifestFile);
      const manifest = CSVToManifestObjArray(manifestData);
      context.emit('csvAdded', manifest);
    }

    function CSVToManifestObjArray(csvString) {
      const delimiter = ',';      
      const resultObj = new csvHelper().csvToObject(csvString, delimiter);
      if(resultObj && resultObj.rows.length) {
        try {
          const arrayOfObjs = resultObj.rows.map(entry => ({
            firstname: entry[0],
            lastname: entry[1],
            email: entry[2],
            phoneNumber: entry[3],
            notes: entry[4]
          }));
          resultObj.rows = arrayOfObjs;
          return resultObj;
        }
        catch(err) {
          console.log(err);
        }
        return {};
      }
    }

    function readAsText(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();
        fr.onload = function () {
          resolve(fr.result);
        };
        fr.onerror = function () {
          reject(fr);
        };
        fr.readAsText(file, "UTF-8");
      });
    }

    async function onDrop(ev) {
      ev.preventDefault();
      isDragging.value = false;
      ev.currentTarget.classList.remove('rectangle-drag-active');
      
      if (ev.dataTransfer.items) {
        // Use DataTransferItemList interface to access the file(s)
        [...ev.dataTransfer.items].forEach(async (item, i) => {
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const file = item.getAsFile();
            await processFile(file);
          }
        });
      } else {
        // Use DataTransfer interface to access the file(s)
        [...ev.dataTransfer.files].forEach(async (file, i) => {
          await processFile(file);
        });
      }
    }

    function onDragOver(ev) {
      ev.preventDefault();
      isDragging.value = true;
      ev.currentTarget.classList.add('rectangle-drag-active');
    }
    function offDragOver(ev) {
      ev.preventDefault();
      isDragging.value = false;
      ev.currentTarget.classList.remove('rectangle-drag-active');
    }

    return {
      manifestFileSelector,
      selectManifestFile,
      selectFile,
      onDrop,
      isDragging,
      onDragOver,
      offDragOver
    }
  }
};
</script>

<style scoped lang="scss">
.rectangle-drag {
  box-sizing: border-box;
  width: 100%;
  height: 98%;
  border: 3px dashed var(--overwatch-button-primary);
  padding: 20px;
}
.rectangle-drag-active {
  border: 3px solid var(--overwatch-button-primary);
}

.choose-files-or-drag {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
  letter-spacing: 0;
  line-height: 35px;
  text-align: center;
}

.fileSelectBox {
  box-sizing: border-box;
  @include overwatch-body-small;
  color: black;
  height: 100%;
  width: 100%;
}

@media (max-width: 480px) {
  .choose-files-or-drag {
    font-size: 18px;
  }
}

</style>