<template>
  <div class="wrapper">
    <div class="overwatch-title-med">
      Search Result
    </div>
    <MDBCard style="width: fit-content; margin-top: var(--spacing-m)">
      <MDBCardBody>
        <div class="probe">
          <AuthImg :src="probeImgSrc"/>
          <div class="probe-info">
            <div class="overwatch-body-small gray-font">File Name</div>
            <div class="overwatch-body-large">
              {{ recordComputed?.probe.fileName }}
            </div>
            <div class="overwatch-body-small gray-font">Galleries</div>
            <div class="overwatch-body-large galleries">
              <div v-for="watchlist of recordComputed?.watchlists">
                <RocIcon icon="image" size="sm"/>
                {{ watchlist.name }}
              </div>
              <div v-for="media of recordComputed?.media">
                <RocIcon icon="image" size="sm"/>
                {{ media.fileName }}
              </div>
            </div>
          </div>
        </div>
      </MDBCardBody>
    </MDBCard>
    <!-- break -->
    <div class="results">
      <div>
        Top Results
      </div>
      <div class="results-list">
        <CandidateCard v-for="result of sortedResults" 
          :result="result" 
          @download="drawAndDownloadCanvas(result)"
          @compare="showMatchComparison(result)"
          @view-linked="showAssociatedImages(result)"
        />
      </div>
    </div>
    <canvas id="testCanvas" width="1138" height="1692" style="display: none;">
    </canvas>
    <BaseDialog v-if="isShowingMatchComparison" show title="Compare Images" @close="isShowingMatchComparison = false;">
      <div class="face-comparison">
        <div class="comparison-column">
          <span class="overwatch-title-small">Gallery Image</span>
          <span class="overwatch-body-med comparison-gallery">
            <RocIcon icon="image" size="sm"/>
            {{ lastSelectedResult.watchlistedFace 
              ? lastSelectedResult.watchlistedFace.source.name 
              : lastSelectedResult.faceTemplate.source.fileName }}
          </span>
          <AuthImg class='face' :src="probeImgSrc"/>
        </div>
        <div class="comparison-column">
          <span class="overwatch-title-small">Image Result</span>
          <span class="overwatch-body-med comparison-similarity" 
            :style="setBackgroundColorByScore(lastSelectedResult.similarity*100)"
          >
            Match Score
            {{ (lastSelectedResult.similarity*100).toFixed(1) }}
          </span>
          <AuthImg class='face' :src="getImgSrc(lastSelectedResult)" />
        </div>
      </div>
    </BaseDialog>
    <BaseDialog v-if="isShowingAssociatedImages" show title="Associated Images" @close="isShowingAssociatedImages = false;">
      <AssociatedImages :candidate="lastSelectedResult" />
    </BaseDialog>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import { 
  MDBCard,
  MDBCardBody,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem
} from 'mdb-vue-ui-kit';
import CandidateCard from '@/components/investigations/CandidateCard';
import BaseDialog from '@/components/ui/BaseDialog';
import AssociatedImages from '@/components/investigations/AssociatedImages.vue';
import AuthImg from '@/components/ui/AuthImg';
import { jsPDF } from 'jspdf';

export default {
  name: 'InvestigativeCaseRecordView',
  props: ['record'],
  components: {
    MDBCard,
    MDBCardBody,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    CandidateCard,
    BaseDialog,
    AssociatedImages,
    AuthImg
},
  setup(props, context) {
    const store = useStore();

    const recordComputed = computed(() => props.record);
    const sortedResults = ref([]);

    const probeImgSrc = ref();

    onMounted(() => {
      if (recordComputed.value) {
        probeImgSrc.value = 'data:image/jpg;base64,' + recordComputed.value.probe_tn_base64;
        sortResultsBySimilarity();
      }
    })

    watch(recordComputed, async nv => {
      if (nv) {
        probeImgSrc.value = 'data:image/jpg;base64,' + nv.probe_tn_base64;
        sortResultsBySimilarity();
      }
    });


    /**
     * Takes the watchlistedFace and faceTemplate results and
     * sorts them by similarity score under sortedResults.
     */
    function sortResultsBySimilarity() {
      const results = recordComputed.value.results;
      const faceTemplates = results.faceTemplates;
      const watchlistedFaces = results.watchlistedFaces;

      const output = [];

      for (let i = 0; i < faceTemplates.length; i++) {
        const newObject = {
          faceTemplate: faceTemplates[i],
          similarity: faceTemplates[i].similarity
        };
        output.push(newObject);
      }
      
      for (let i = 0; i < watchlistedFaces.length; i++) {
        const newObject = {
          watchlistedFace: watchlistedFaces[i],
          similarity: watchlistedFaces[i].similarity
        }
        output.push(newObject);
      }

      output.sort((a,b) => {
        return b.similarity-a.similarity;
      });

      const topNResults = 250;

      sortedResults.value = output.slice(0, topNResults);
    }

    function getImgSrc(result) {
      if (result.faceTemplate) {
        return result.faceTemplate.tn;
      } else {
        return result.watchlistedFace.tn;
      }
    }

    function drawAndDownloadCanvas(result) {
      var canvas = document.getElementById('testCanvas');
      var ctx = canvas.getContext("2d");

      // Probe and candidate image parameters eyeballed.
      // Fun tip: use the Apple image viewer to measure pixel height and width.

      var imageObj = new Image();
      imageObj.src = require('@/assets/investigations/blank_report.png').toString('base64');
      imageObj.onload = function(){
        ctx.drawImage(imageObj, 0, 0);

        var probeImg = new Image();
        probeImg.src = probeImgSrc.value;
        probeImg.onload = function() {
          ctx.drawImage(probeImg, 58, 297, 504, 603);

          var candidateImg = new Image();
          candidateImg.src = getImgSrc(result);
          candidateImg.onload = function() {
            ctx.drawImage(candidateImg, 564, 297, 504, 603);

            const imgData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF();
            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();
            pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
            pdf.save('download.pdf');
          }
        }
      }     
    }

    const isShowingMatchComparison = ref(false);
    const lastSelectedResult = ref();
    function showMatchComparison(result) {
      lastSelectedResult.value = result;
      isShowingMatchComparison.value = true;
    }

    const isShowingAssociatedImages = ref(false);
    function showAssociatedImages(result) {
      lastSelectedResult.value = result;
      isShowingAssociatedImages.value = true;
    }

    function setBackgroundColorByScore(similarity) {
      let bgStyle = 'background: var(--roc-watch-similarity-90);'; //green
      if (similarity) {
        if (similarity < 90 && similarity >= 70) {
          bgStyle = 'background: var(--roc-watch-similarity-70) !important;'; //y
        }
        else if (similarity < 70) {
          bgStyle = 'background: var(--roc-watch-similarity-0) !important;'; //r
        }
      }
      return bgStyle;
    }

    return {
      recordComputed,
      probeImgSrc,
      sortedResults,
      getImgSrc,
      drawAndDownloadCanvas,
      isShowingMatchComparison,
      showMatchComparison,
      lastSelectedResult,
      setBackgroundColorByScore,
      showAssociatedImages,
      isShowingAssociatedImages
    }
  }
}
</script>

<style scoped lang="scss">

div {
  color: var(--overwatch-neutral-100);
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.probe {
  display: flex;
  gap: var(--spacing-l);
  align-items: center;
  width: fit-content;
  
}
.probe img {
  width: 200px;
}

.probe-info {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}

.galleries {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-base);
}
.galleries * {
  color: var(--overwatch-button-primary);
}

.card-body{
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
}

.results {
  margin-top: var(--spacing-xl);

  flex: 1;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.results-list {
  display: grid;
  height: 100%;
  /* width: fit-content; */
  grid-template-columns: 1fr 1fr 1fr;
  gap: var(--spacing-s);

  margin-top: var(--spacing-s);

  padding-right: var(--spacing-xl);

  flex: 1;
  overflow-y: auto;
}

.gray-font {
  color: var(--overwatch-neutral-200);
}

.face-comparison {
  display: flex;
  gap: var(--spacing-s);
}
.face {
  width: auto;
  height: 100%;
  margin: 0 auto;
  justify-self: center;
}
.comparison-column {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s);
}

.comparison-similarity {
  border: 1px solid black;
  width: fit-content;
  padding: var(--spacing-base) var(--spacing-s);
  border-radius: 25px;
}
.comparison-gallery {

  border: 1px solid var(--overwatch-button-primary);
  width: fit-content;
  padding: var(--spacing-base) var(--spacing-s);
  border-radius: 25px;
  background-color: var(--overwatch-button-primary-20);
}
</style>
