<template>
  <MDBDropdownMenu class="menu">
    <slot /> 
  </MDBDropdownMenu>
</template>

<script>
/**
 * Component that works identically to MDBDropdownMenu, 
 * but with ROC Standard Library styling.
 * 
 * TODO as of Jan 19th - Create size prop for small, medium, large menu sizes.
 * Currently by default designed around medium size menu.
 */
import {
  MDBDropdownMenu
} from 'mdb-vue-ui-kit';

export default {
  name: 'RocDropdownMenu',
  components: {
    MDBDropdownMenu
  }
}
</script>

<style scoped lang="scss">
.menu {
  margin-top: -12px; //compensate for padding in kebab icon
  border-radius: 5px;
  width: fit-content;
  background-color: var(--overwatch-secondary);
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.15);
}
.menu :deep(li) {
  @include overwatch-body-small;
}

//Remove divider between menu items
// Links
.menu :deep(hr) {
  display: none;
}

// Links
.menu :deep(li:first-child a) {
  border-radius: 5px 5px 0 0;
}

.menu :deep(li:last-child a) {
  border-radius: 0 0 5px 5px;
}

.menu :deep(a) {
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--overwatch-neutral-100);
}

.menu :deep(a):hover {
  background-color: var(--overwatch-button-primary-20);
}

.menu :deep(a):focus {
  border: var(--overwatch-focus)
}

// Buttons
.menu :deep(li:first-child button) {
  border-radius: 5px 5px 0 0;
}

.menu :deep(li:last-child button) {
  border-radius: 0 0 5px 5px;
}

.menu :deep(button) {
  padding: 8px 16px;
  border-radius: 5px;
  color: var(--overwatch-neutral-100);
}

.menu :deep(button):hover {
  // Opacity .25 
  background-color: color-mix(in srgb, var(--overwatch-accent), transparent 75%);
}

.menu :deep(button.active) {
  color: var(--overwatch-button-primary);
  background-color: var(--overwatch-secondary);

}

.menu :deep(a):focus {
  border: var(--overwatch-focus)
}

.menu :deep(a).disabled {
  background-color: var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-200);
}

</style>