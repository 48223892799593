class versionInfo {
    constructor() {
        this.version = "v2.1";
        this.revision = "02c0cd586e346fb026cc113ed8a6cdc1c239ee5b";
        this.commitCount = "6";
        this.buildDate = "Mon Jun  3 14:56:33 UTC 2024";
        this.isProduction = !this.version.startsWith("{{"); // The script for building the production container will substitute the above values

        if (!this.isProduction) {
            // For dev environment determine version from git
            try {
                this.version = require('child_process')
                               .execSync('git rev-parse --abbrev-ref HEAD')
                               .toString().trim();
            } catch(err) {
                this.version = "DEV";
            }

            try {
                this.revision = require('child_process')
                                .execSync('git rev-parse HEAD')
                                .toString().trim();
            } catch(err) {
                this.revision = "DEV";
            }

            try {
                this.commitCount = require('child_process')
                                   .execSync('git rev-list --count origin/master..')
                                   .toString().trim();
            } catch(err) {
                this.commitCount = "DEV";
            }

            this.buildDate = new Date().toUTCString();
        }
    }
}

module.exports = versionInfo;
