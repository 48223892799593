import { Capacitor } from "@capacitor/core";
import { Device } from "@capacitor/device";
import { get as lodashGet } from "lodash";
import { apiCall } from "../../utils/utils"

export default {
  async getRetentionSettings(context) {
    const url = `/rest/v1/maintenance/retention`;
    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    return responseData;
  },
  async saveRetentionSettings(context, payload) {
    const url = '/rest/v1/maintenance/retention';
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async getServerConfig(context) {
    const url = `/rest/v1/config`;
    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    return responseData;
  },
  async saveServerConfig(context, payload) {
    const url = '/rest/v1/config';
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
    );
  },
  async getUsers(context) {
    const url = `/rest/v1/auth/users`;
    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    return responseData;
  },
  async loadUserGroups(context) {
    const url = `/rest/v1/auth/usergroups`;
    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    if (responseData && responseData.status === 'success') {
      context.commit("setUserGroups", responseData.userGroups);
    } else {
      console.warn(`Failed to load user groups`);
      context.commit("setUserGroups", []);
    }
  },
  async createUser(context, payload) {
    const url = `/rest/v1/auth/user/create`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
    );
  },
  async updateUser(context, payload) {
    const url = `/rest/v1/auth/user/update`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async deleteUser(context, payload) {
    const url = `/rest/v1/auth/user/delete`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
    );
  },
  async createUserGroup(context, payload) {
    const url = `/rest/v1/auth/userGroup`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
        'auth/fetchJSON',
        { url: url, payload: httpPayload },
        { root: true }
    );
  },
  async updateUserGroup(context, payload) {
    const {_id, ...body} = payload;

    const url = `/rest/v1/auth/userGroup/${_id}`
    const httpPayload = {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );

  },
  async deleteUserGroup(context, payload) {
    const {_id, ...body} = payload;

    const url = `/rest/v1/auth/userGroup/${_id}`
    const httpPayload = {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    }
    return await context.dispatch(
      'auth/fetchJSON',
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async canAddUser(context, _){
    const  url = `/rest/v1/license/canAddUser`;
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'POST'}}, {root: true});
    return response?.canAddUser ?? false;
  },
  async canAddCamera(context, _){
    const  url = `/rest/v1/license/canAddCamera`;
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'POST'}}, {root: true});
    return response?.canAddCamera ?? false;
  },
  async updatePreAuthConfig(context, payload){
    const  url = `/rest/v1/license/updatePreAuthConfig`;
    const httpPayload = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    };
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: httpPayload}, {root: true});
    if(response.status === 'success'){
      return payload;
    }
  },
  async getLogo(context, payload){
    const preAuthConfig = payload.preAuthConfig;
    const logo = preAuthConfig?.logoImageBase64 ?? null;
    const isWhiteLabelEnabled = preAuthConfig?.isWhiteLabelEnabled ?? false;
    const hideLogo = preAuthConfig?.hideLogo ?? false;
    const images = require.context('@/assets/img/icons');

    if(isWhiteLabelEnabled){
      const favicon = document.getElementById("favicon");
      favicon.href = images('./blank.svg');
      return (hideLogo) ? '' : logo;
    } else {
      let configValue = '';
      if(context.getters['getDarkMode']) {
        configValue = preAuthConfig?.logoImageBase64 ?? 'ROC_Watch_Logo_Dark_Mode.svg';
      } else {
        configValue = preAuthConfig?.logoImageBase64 ?? 'ROC_Watch_Logo_Light_Mode.svg';
      }

      if(configValue === '') return '';

      if (require('@/js/base64Helper').isBase64DataUri(configValue)) {
        return configValue;
      } else {
        return images('./' + configValue);
      }

    }
  },
  async getUXSetting(context, payload) {
    let  url = `/rest/v1/objectstore/uxsettings`;

    if(payload && payload.includes("read_only")){
      url = `/rest/v1/objectstore/read_only_uxsettings`;
    }

    const responseData  = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    if(responseData.status === 'success') {
      context.commit("setSpoofThreshold", responseData.value.encounters.spoof_threshold);
      context.commit("setIsEnrollButtonEnabled", responseData.value.is_enroll_button_enabled);
      context.commit("setIsExamineButtonEnabled", responseData.value.is_examine_button_enabled);

      context.commit("setLicenseFlags", responseData.value.flags);
      context.commit("setTaggedFacesWatchlistConfig", responseData.value.faceTagging);
      if (responseData.value.watchlists) {
        context.commit("setWatchlistColors", responseData.value.watchlists.availableColors);
        context.commit("setWatchlistDefaultColor", responseData.value.watchlists.defaultColor);
        context.commit("setWatchlistAdditionalInfoFields", responseData.value.watchlists.enrollmentAdditionalInfoFields ?? []);
      }
      if (responseData.value.encounters) {
        if (responseData.value.encounters.maxLiveEncounters) {
          context.commit("encounters/setMaxLiveEncounters", responseData.value.encounters.maxLiveEncounters, { root: true });
        }
        if (responseData.value.encounters.queryPageSize) {
          context.commit("encounters/setQueryPageSize", responseData.value.encounters.queryPageSize, { root: true });
        }
        if (responseData.value.encounters.encounterCardUX) {
          context.commit("setEncounterCardUX", responseData.value.encounters.encounterCardUX);
        }
      }
      if(responseData.value.events)
      {
        context.commit("events/setQueryPageSize",responseData.value.events.queryPageSize,{ root: true });
      }
    }
    return responseData;
  },

  async getObjectValueByKey(context, key) {
    let url = `/rest/v1/objectstore/${key}`
    const response = await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
    if (response && response.status === 'success') {
      return response.value;
    } else {
      console.error(`getObjectValueByKey::Failure querying object store for key ${key}, response: ${JSON.stringify(response)}`);
      return null;
    }
  },

  async getDeviceInfo(context) {
    try {
      const isMobile = Capacitor ? Capacitor.getPlatform() != 'web' : false;
      if(isMobile) {
        const deviceInfo = await Device.getInfo();
        context.commit("setDeviceInfo", deviceInfo);
        return deviceInfo;
      }
    }

    catch(err) {
      console.log(err);
    }
  },
  async getUserAccess(context, type='user') {
    const url = `/rest/v1/auth/useraccess/${type}`;
    return await context.dispatch("auth/fetchJSON", {url: url, payload: {method: 'GET'}}, {root: true});
  },
  async getFaceTaggingEnabled(context) {
    const config = await context.dispatch('getServerConfig');
    if (config && config.status === 'success') {
      const enabled = lodashGet(config.value, 'import.reverseMatching.enabled', false);
      context.commit('setFaceTaggingEnabled', enabled);
    }
  },
  async loadReverseSearchObjModalities(context) {
    const config = await context.dispatch('getServerConfig');
    if (config && config.status === 'success') {
      const modalities = lodashGet(config.value, 'bestshotGalleryObject.enabledModalities', []);
      context.commit("setReverseSearchObjModalities", modalities);
    }
  },

  async getApiKeys(context) {
    const responseData = await apiCall(
      context,
      `/rest/v1/auth/apikeys`,
      'GET'
    );
    return responseData;
  },
  async createApiKey(context, payload) {
    const responseData = await apiCall(
      context,
      `/rest/v1/auth/apikey`,
      'POST',
      JSON.stringify(payload)
    );
    return responseData;
  },
  async updateApiKey(context, payload) {
    const responseData = await apiCall(
      context,
      `/rest/v1/auth/apikey/${payload._id}`,
      'PUT',
      JSON.stringify(payload)
    );
    return responseData;
  },
  async deleteApiKey(context, payload) {
    const responseData = await apiCall(
      context,
      `/rest/v1/auth/apikey/${payload._id}`,
      'DELETE',
      JSON.stringify(payload)
    );
    return responseData;
  },
  async getEntityExports(context) {
    const responseData = await apiCall(
      context,
      `/rest/v1/migration/exports`,
      'GET'
    );
    return responseData?.result;
  },
  async requestEntityExport(context, payload) {
    const responseData = await apiCall(
      context,
      `/rest/v1/migration/export`,
      'POST',
      JSON.stringify(payload)
    );
    return responseData;
  },
  async deleteEntityExport(context, payload) {
    const responseData = await apiCall(
      context,
      `/rest/v1/migration/export/${payload.deleteAll ? 'all' : payload.id}`,
      'DELETE'
    );
    return responseData;
  },
  async getEntityImports(context) {
    const responseData = await apiCall(
      context,
      `/rest/v1/migration/imports`,
      'GET'
    );
    return responseData?.result;
  },
  async requestEntityImport(context, payload) {
    const formDataBody = new FormData();
    formDataBody.append('import', payload.file);
    const responseData = await apiCall(
      context,
      `/rest/v1/migration/imports`,
      'POST',
      formDataBody,
      'multipart/form-data',
      payload.onUploadProgress,
      payload.abortController
    )
    return responseData;
  },
  async deleteEntityImport(context, payload) {
    let responseData;
    if (payload.deleteAll) {
      responseData = await apiCall(
        context,
        `/rest/v1/migration/imports`,
        'DELETE'
      );
    } else {
      responseData = await apiCall(
        context,
        `/rest/v1/migration/imports/${payload.id}`,
        'DELETE'
      );
    }
    return responseData;
  },
};
