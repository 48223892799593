<template>
  <div class="case-card">
    <div>
      {{ name }}
    </div>
    <div>
      {{ totalCameras }}
    </div>
    <div style="width: 100%">
      <div v-if="filesProcessed != totalFiles" style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; width: 100%">
        <div style="display: flex; align-items: center; gap: var(--spacing-s)">
          <div>
            {{filesProcessed + " / " + totalFiles}}
          </div>
          <RocIcon
            v-if="hasErrorFile"
            icon="unknown"
            size="sm"
            color="red"
            style="cursor: pointer;"
            @click="isShowingInfo = true;"
          />
        </div>
      </div>
      <div v-else style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center;">
        <div style="display: flex; align-items: center; gap: var(--spacing-s)">
          <div>
            {{filesProcessed }}
          </div>
          <RocIcon
            v-if="hasErrorFile"
            icon="unknown"
            size="sm"
            color="red"
            style="cursor: pointer;"
            @click="isShowingInfo = true;"
          />
        </div>        
        <div class="overwatch-body-xsmall" style="color: var(--overwatch-neutral-200)">
          {{ humanReadableTotalBytes }}
        </div>
      </div>

    </div>
    <div class="d-flex flex-row">
      <div v-if="showProgressBar && caseProgress >= 0" style="display: flex; justify-content: space-between; align-items: center; width: 28%">
        {{ caseProgressString }}
        <!-- <CaseUploadProgressCircle :value="caseProgress" /> -->
      </div>
      <div v-else-if="showProgressBar">
        {{ caseProgressString }}
      </div>
      <div v-else style="display: flex; justify-content: space-between; align-items: center; width: 25%">
        Complete
      </div>
    </div>
    <div>
      {{ totalWatchlists }}
    </div>
    <div class="dropdown-div">
      <div v-if="caseClusteringStatus === 'completed'" style="display: flex; flex-direction: column; align-items: center; gap: var(--spacing-base)">
        <router-link :to="{path: `/clusters/${caseId}`}">
          <RocButton size="tiny" style="padding-top: var(--spacing-xs); padding-bottom: var(--spacing-xs)">
            View
          </RocButton>
        </router-link>
        <div class="overwatch-body-xsmall" style="color: var(--roc-global-light-button-primary)">
          {{ clusterCount }} Clusters
        </div>
      </div>
      <div v-else style="display: flex; align-items: center; gap: var(--spacing-s);">
        <MDBSpinner v-if="caseClusteringStatus === 'processing'"
          size="sm"
          style="color: var(--roc-global-light-button-primary)"
        ></MDBSpinner>
        {{ capitalize(caseClusteringStatus) }}
      </div>
      <MDBDropdown v-model="dropdownOptions" style="margin-left: auto;">
        <RocDropdownToggle @click="dropdownOptions = !dropdownOptions">
          <RocIcon
          color="black"
          size="sm"
          icon="kebab"
        />
        </RocDropdownToggle>
        <RocDropdownMenu>
          <RocDropdownItem @click.prevent="isShowingInfo = true; dropdownOptions = false;">
            <div>
              View
            </div>
          </RocDropdownItem>
          <RocDropdownItem @click.prevent="addFiles" :disabled="isCaseProcessing">
            <div>
              Add Files
            </div>
          </RocDropdownItem>
          <RocDropdownItem @click.prevent="editCaseDetails" :disabled="isCaseProcessing">
            <div>
              Edit
            </div>
          </RocDropdownItem>
          <RocDropdownItem @click.prevent="isShowingDelete = true; dropdownOptions = false;">
            <div>
              Delete
            </div>
          </RocDropdownItem>
        </RocDropdownMenu>
      </MDBDropdown>
    </div>
    <base-dialog v-if="isShowingDelete" :show="true" title="Delete Case" @close="isShowingDelete=false;" style="width: 500px">
      <DeleteConfirmation
        @close="isShowingDelete=false"
        @delete="handleDelete"
      >
        Are you sure you want to delete case <span style="color: var(--overwatch-error)">{{ caseName }}</span>? This action cannot be undone.
      </DeleteConfirmation>
    </base-dialog>
    <base-dialog v-if="isShowingInfo" :show="true" title="Case Information" @close="isShowingInfo=false;" style="width: 1000px;">
      <CaseInformation :case="propCase" :statuses="caseStatuses" :progress="caseProgress" :cameras="caseCameras" />
    </base-dialog>
  </div>
</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import {
  MDBDropdown,
  MDBSpinner,
} from 'mdb-vue-ui-kit';
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import CaseInformation from '@/components/cases/CaseInformation.vue';
import RocButton from '@/components/ui/RocButton';
import RocDropdownMenu from '@/components/ui/RocDropdownMenu.vue';
import RocDropdownToggle from '@/components/ui/RocDropdownToggle.vue';
import RocDropdownItem from '@/components/ui/RocDropdownItem';
import RocIcon from '@/components/ui/RocIcon';
import ProgressCircle from '@/components/ui/ProgressCircle.vue';
import CaseUploadProgressCircle from '@/components/cases/CaseUploadProgressCircle.vue';

export default {
  name: 'CaseCard',
  components: {
    MDBDropdown,
    DeleteConfirmation,
    CaseInformation,
    MDBSpinner,
    RocButton,
    RocDropdownMenu,
    RocDropdownToggle,
    RocDropdownItem,
    RocIcon,
    ProgressCircle,
    CaseUploadProgressCircle
  },
  props: {
    case: Object,
    trigger: Number
  },
  emits: ['edit-case'],
  setup(props, context) {
    const store = useStore();

    const name = computed(() => {
      return props.case.name ? props.case.name : '';
    })

    const showProgressBar = computed(() => {
      if (filesProcessed.value === totalFiles.value && !isInitiallyLoading.value && !areMediasUploading.value) {
        // Case is done processing - show badge
        return false;
      }
      if (caseProgress.value === -1) {
        // Case is new and hasn't started processing yet - show spinner
        return true;
      }
      else {
        return true;
      }
    });

    const totalCameras = ref(0);
    const totalFiles = ref(0);
    const totalWatchlists = computed(() => {
      if (props.case.watchlistIds) {
        return props.case.watchlistIds.length;
      } else {
        return 0;
      }
    });

    const caseId = ref(props.case._id ? props.case._id : '');

    /**
     * Calculate case progress.
     * TODO: Pop up the wizard and show the case progress in the wizard when clicked?
     */

    const caseProgress = ref(-1);

    onMounted(async () => {
      await updateCameraAndFileCount();
    });
    watch(() => props.trigger, async () => {
      await updateCameraAndFileCount();
    })


    const caseCameras = ref([]);
    const caseStatuses = ref([]);

    // pending, processing, completed, error
    const caseClusteringStatus = ref('');
    const clusterCount = ref(0);

    const isInitiallyLoading = ref(false);
    async function updateCameraAndFileCount() {
      isInitiallyLoading.value = true;

      var response = await store.dispatch("cases/getCamerasByCaseId", {caseId: caseId.value});
      if (response && response.status === 'success') {
        caseCameras.value = response.result;
        totalCameras.value = response.result.length;
      }

      response = await store.dispatch("cases/getCaseStatus", {caseId: caseId.value});
      if (response && response.status === 'success') {
        caseStatuses.value = response.result;
        totalFiles.value = response.result.length;
      }

      response = await store.dispatch('cases/getCaseById', caseId.value);
      if (response && response.status === 'success') {
        caseClusteringStatus.value = response.result.clusteringStatus
        if (caseClusteringStatus.value === 'completed') {
          var count = await store.dispatch('clusters/getClusterCount', caseId.value)
          if (count.status === 'success') {
            clusterCount.value = count.result;
          }
        }
      }

      isInitiallyLoading.value = false;
    }

    const caseObject = computed(() => {
      return store.getters['cases/cases'].find(c => c._id === caseId.value);
    })

    // Need this reactivity for when the Case is new after starting processing in wizard.
    watch(caseObject, nv => {
      if (nv && nv.processing) {
        var caseUpdateInterval = setInterval(() => caseUpdateIntervalHandler(caseUpdateInterval), 1000);
      }
    })

    onMounted(() => {
      var caseUpdateInterval = setInterval(() => caseUpdateIntervalHandler(caseUpdateInterval), 1000);
    });

    const areMediasUploading = ref(true);

    const filesProcessed = ref(0);
    const hasErrorFile = ref(false);
    const totalBytes = ref(0);
    async function caseUpdateIntervalHandler(interval)  {
      updateCameraAndFileCount();

      var response = await getCaseStatus();

      if (response && response.status === 'success') {
        var caseMediaStatuses = response.result;

        if (caseMediaStatuses.length > 0) {
          var finishedCount = 0;
          var fileBytesSum = 0;

          caseMediaStatuses.forEach(m => {
            if (m.status === 'completed' || m.status === 'error') {
              finishedCount++;
              areMediasUploading.value = false;
            }
            if (m.status === 'pending' || m.status === 'processing') {
              areMediasUploading.value = false;
            }
            if (m.status === 'error') {
              hasErrorFile.value = true
            }

            fileBytesSum += m.fileBytes;
          });

          filesProcessed.value = finishedCount;
          totalBytes.value = fileBytesSum;

          if (finishedCount === caseMediaStatuses.length) {
            disconnectAndResetSockets();
            clearInterval(interval);
            store.commit('cases/setIsCaseProcessing', false);
            caseProgress.value = 100;
          } else {
            if (sockets.value.length === 0) {
              await connectCaseSockets();
            }
            store.commit('cases/setIsCaseProcessing', true);
          }
        } else {
          clearInterval(interval);
        }
      } else {
        clearInterval(interval);
      }
    }

    async function getCaseStatus() {
      return await store.dispatch("cases/getCaseStatus", {caseId: caseId.value});
    }

    const caseProgressString = computed(() => {
      if (caseProgress.value >= 0) {
        return `${caseProgress.value}%`;
      } else {
        return "Loading...";
      }
    })

    const dropdownOptions = ref(false);

    const isShowingDelete = ref(false);
    async function handleDelete() {
      dropdownOptions.value = false;
      await store.dispatch('cases/deleteCase', {id: caseId.value});
      //set case id to blank to prevent getCaseStatus from erroring
      caseId.value = '';
    }

    const caseName = ref(props.case.name);

    function addFiles() {
      store.commit('cases/setEditingCase', props.case);
      store.commit('cases/setImporterVisible', true);
      dropdownOptions.value = false;
    }

    function editCaseDetails() {
      context.emit("edit-case");
      dropdownOptions.value = false;
    }

    const isShowingInfo = ref(false);

    const propCase = ref(props.case);

    // If any case is processing, disable edit button.
    const isCaseProcessing = computed(() => {
      return store.getters['cases/isCaseProcessing'];
    });

    /* If case isn't finished processing according to the caseMediaStatuses,
    *  connect sockets to get more detailed progress
    */
    const sockets = ref([]);
    const liveProgressForFilename = ref({});

    watch(liveProgressForFilename, nv => {
      const alreadyFinishedMedia = caseStatuses.value.filter(s =>
        s.status === 'completed' || s.status === 'error'
      )
      const numberOfFiles = caseStatuses.value.length;

      var totalPercentage = alreadyFinishedMedia.length * 100;
      for (var [key, value] of Object.entries(nv)) {
        if (alreadyFinishedMedia.map(m => m.fileName).includes(key)) continue;
        totalPercentage += Number(value);
      }

      caseProgress.value = (totalPercentage / (numberOfFiles * 100)) * 100;
      caseProgress.value = Number(caseProgress.value.toFixed(1));
    }, {deep:true});

    async function connectCaseSockets() {
      const camResponse = await store.dispatch("cases/getCamerasByCaseId", {caseId: caseId.value});
      const cameras = camResponse.result;

      for (let camera of cameras) {
        let socket = await store.dispatch("auth/getSocketIO", `feed=livestats&topic=${camera.GUID}`);

        socket.on(camera.GUID, (payload) => {
          const cameraStats = payload.cameraStats;
          const parts = cameraStats.filename.split('/');
          const filename = parts[parts.length - 1];
          const progress = cameraStats.progress;

          liveProgressForFilename.value[filename] = progress;
        });

        sockets.value.push(socket);
      }
    }
    function disconnectAndResetSockets() {
      sockets.value.forEach(s => {
        s.disconnect();
        s.removeAllListeners();
      });
      sockets.value = [];
    }

    function capitalize(s) {
      return s.charAt(0).toUpperCase() + s.slice(1)
    }

    const humanReadableTotalBytes = computed(() => {
      const size = totalBytes.value;
      var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
      return (size / Math.pow(1024, i)).toFixed(2) * 1 + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    })

    return {
      name,
      totalCameras,
      filesProcessed,
      totalFiles,
      totalWatchlists,
      caseProgress,
      dropdownOptions,
      caseProgressString,
      handleDelete,
      isShowingDelete,
      caseName,
      addFiles,
      editCaseDetails,
      isShowingInfo,
      propCase,
      caseCameras,
      caseStatuses,
      isCaseProcessing,
      showProgressBar,
      caseId,
      caseClusteringStatus,
      capitalize,
      hasErrorFile,
      totalBytes,
      humanReadableTotalBytes,
      clusterCount
    };
  }
};
</script>

<style>
.dropdown-toggle:after {
  display: none;
}
</style>

<style scoped lang="scss">
.case-card {
  @include overwatch-body-med;
}
</style>
