import mutations from "./mutations.js";
import getters from "./getters.js";
import actions from "./actions.js";

const getDefaultState = () => {
  return {
    cases: [],
    editingCase: null, // Selected case for editing
    cameras: [],  // Used for storing selected case cameras
    importerVisible: false,
    isCaseProcessing: false,
    encounterFilterSelectedCase: null,
    dateTimeParserValue: {}
  }
}

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};
