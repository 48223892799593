<template>
  <div class="disable-select">
    <MDBNavbar class="navbar" container expand='lg' style='box-shadow: 0 1px 2px 0 var(--overwatch-overlay); background: var(--overwatch-secondary);'>
      <MDBNavbarBrand href='#'>
        <router-link class='header-options-dd' to='/encounters' style="display:flex !important; align-items: center;">
            <RocLogo style="height: 50px; width: 90px;"/>
        </router-link>
      </MDBNavbarBrand>
      <div class='d-flex justify-content-end'>
        <MDBNavbarNav>
          <MDBNavbarItem v-if="isPageAllowed('/workflows')" class="actionItem">
            <router-link :to="{ path: '/workflows'}">
              <span class="d-none d-sm-block" style="color: var(--overwatch-neutral-100); user-select: none;">Workflows</span>
            </router-link>
          </MDBNavbarItem>

          <MDBNavbarItem class="actionItem" style="padding: 0 !important;">
            <BaseDropdown v-model="isCaseDropdownShowing" show>
              <MDBDropdownToggle v-if="isPageAllowed('/missions') || isPageAllowed('/investigations')" @click="isCaseDropdownShowing = !isCaseDropdownShowing" tag="x" class="dropdownToggle" style="color: var(--overwatch-neutral-100);">
                Cases
                <RocIcon class="toggleArrow" icon="downArrow" size="sm" color="black" :style="{transform: isCaseDropdownShowing ? 'rotate(180deg)' : 'rotate(0deg)'}"/>
              </MDBDropdownToggle>
              <RocDropdownMenu aria-labelledby="dropdownMenuButton" style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
                <MDBDropdownItem v-if="isPageAllowed('/missions')" router :to="{ path: '/missions' }" @click="isCaseDropdownShowing = false;">
                  Post-Event Media
                </MDBDropdownItem>
                <MDBDropdownItem v-if="isPageAllowed('/investigations')" router :to="{ path: '/investigations' }" @click="isCaseDropdownShowing = false">
                  Investigations
                </MDBDropdownItem>
              </RocDropdownMenu>
            </BaseDropdown>
          </MDBNavbarItem>

          <MDBNavbarItem v-if="isPageAllowed('/events') && isEventButtonEnabled" class="actionItem">
            <router-link :to="{ path: '/events'}">
              <span class="d-none d-sm-block" style="color: var(--overwatch-neutral-100); user-select: none;">Events</span>
            </router-link>
          </MDBNavbarItem>
          <MDBNavbarItem v-if="!isMobile" class="actionItem mobile" style="padding: 0 !important;">
            <BaseDropdown v-model="dropdownEncounters" :show="dropdownEncounters">
              <MDBDropdownToggle @click="dropdownEncounters = !dropdownEncounters" tag='x' class="dropdownToggle" style="color: var(--overwatch-neutral-100);">
                Encounters
                <RocIcon class="toggleArrow" icon="downArrow" size="sm" color="black" :style="{transform: dropdownEncounters ? 'rotate(180deg)' : 'rotate(0deg)'}"/>
              </MDBDropdownToggle>
              <RocDropdownMenu aria-labelledby="dropdownMenuButton" style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
                <MDBDropdownItem router :to="{ path: '/encounters'}" @click="dropdownEncounters = !dropdownEncounters">
                  Encounters Page
                </MDBDropdownItem>
                <MDBDropdownItem router :to="{ path: '/views'}" @click="dropdownEncounters = !dropdownEncounters">
                  Saved Views
                </MDBDropdownItem>
              </RocDropdownMenu>
            </BaseDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem v-if="isPageAllowed('/rocenroll/home') && isEnrollButtonEnabled" class="actionItem mobile">
            <router-link :to="{ path: '/rocenroll/home', query: { flavor: getRocEnrollFlavor() }}">
              <span class="d-none d-sm-block" style="color: var(--overwatch-neutral-100); user-select: none;">Enroll</span>
              <span class="d-block d-sm-none"><RocIcon icon='cameraOn' style="user-select: none;"/></span>
            </router-link>
          </MDBNavbarItem>

          <MDBNavbarItem v-if="isPageAllowed('/examine') && isExamineButtonEnabled" class="actionItem">
            <router-link :to="{ path: '/examine'}">
              <span class="d-none d-sm-block" style="color: var(--overwatch-neutral-100); user-select: none;">Examine</span>
              <!-- <span class="d-block d-sm-none"><MDBIcon icon='list' iconStyle='fas' size="lg" style="color: var(--overwatch-neutral-100); user-select: none;"/></span> -->
            </router-link>
          </MDBNavbarItem>

          <MDBNavbarItem class="actionItem mobile" style="padding: 0 !important;">
            <!-- dropdownActions is the default overflow menu -->
            <BaseDropdown v-model='dropdownActions' :show="dropdownActions" align='end'>
              <MDBDropdownToggle v-if="overflowMenu && overflowMenu.length > 0" class="dropdownToggle" tag='a' @click="dropdownActions = !dropdownActions">
                <RocIcon color="black" size="sm" icon="kebab"/>
              </MDBDropdownToggle>
              <RocDropdownMenu v-if="overflowMenu && overflowMenu.length > 0" aria-labelledby='dropdownMenuButton' style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
                <span v-for="(item, index) in overflowMenu" :key="item">
                  <MDBDropdownItem router :to="item.routerPath" @click="item.clickHandler" style="cursor: pointer;">
                    {{ item.label }}
                  </MDBDropdownItem>
                  <MDBDropdownItem v-if="index < (overflowMenu.length-1)" divider/>
                </span>
              </RocDropdownMenu>
            </BaseDropdown>
          </MDBNavbarItem>
          <MDBNavbarItem>
            <!-- dropDownUserSettings is the user's circle icon -->
            <BaseDropdown v-model='dropDownUserSettings' :show="dropDownUserSettings" align="end">
              <MDBDropdownToggle class="nameCircle" tag='button' @click="dropDownUserSettings = !dropDownUserSettings">{{userInitials}}</MDBDropdownToggle>
              <RocDropdownMenu aria-labelledby='dropdownMenuButton' style="box-shadow: 4px 4px 15px 4px rgba(0, 19, 58, 0.3);">
                <span v-for="(item, index) in userMenu" :key="item">
                  <MDBDropdownItem router :to="item.routerPath" @click="item.clickHandler" style="cursor: pointer;">
                    {{ item.label }}
                  </MDBDropdownItem>
                  <MDBDropdownItem divider v-if="index < (userMenu.length-1)"/>
                </span>
              </RocDropdownMenu>
            </BaseDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </div>
    </MDBNavbar>
    <base-dialog v-if="showRetention" :show='true' title='Retention Settings' @close='showRetention = false;' :style="retentionStyle">
      <retention-settings @close="showRetention = false;"></retention-settings>
    </base-dialog>
    <base-dialog v-if="showServerConfig" :show="true" title="Server Settings" @close="showServerConfig = false;" :style="serverConfigStyle">
      <ServerConfig @close="showServerConfig=false;"/>
    </base-dialog>
    <base-dialog v-if="showUISettings" :show="true" title="UI Settings" @close="showUISettings = false;" :style="uiSettingsStyle">
      <UISettings @close="showUISettings=false;"/>
    </base-dialog>
    <base-dialog v-if="showUser" :show='true' title='Change Password' @close='showUser = false;' :style="passwordChangeStyle">
      <PasswordChange @close="showUser = false" />
    </base-dialog>
  </div>
</template>

<script>
import retentionSettings from "@/components/settings/retentionSettings";
import PasswordChange from "@/components/settings/PasswordChange";
import ServerConfig from "@/components/settings/ServerConfig.vue";
import UISettings from "@/components/settings/UISettings.vue";
import BaseDropdown from "@/components/ui/BaseDropdown.vue";
import RocDropdownMenu from "@/components/ui/RocDropdownMenu.vue";

import {
  MDBDropdownItem,
  MDBDropdownToggle,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarItem,
  MDBNavbarNav,
  MDBNavbarToggler
} from "mdb-vue-ui-kit";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Capacitor } from "@capacitor/core";
import userAccessHelper from "@/js/userAccessHelper";
import RocLogo from "../ui/RocLogo.vue";
import RocIcon from "../ui/RocIcon.vue";

export default {
  name: "Header",
  components: {
    MDBNavbar,
    MDBNavbarItem,
    MDBNavbarBrand,
    BaseDropdown,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBNavbarToggler,
    MDBNavbarNav,
    retentionSettings,
    PasswordChange,
    ServerConfig,
    BaseDropdown,
    UISettings,
    RocIcon,
    RocLogo,
    RocDropdownMenu
},
  setup() {
    const store = useStore();
    const collapseNavBar = ref(false);
    const dropdownActions = ref(false);
    const dropDownUserSettings = ref(false);
    const showRetention = ref(false);
    const showUser = ref(false);
    const showServerConfig = ref(false);
    const dropdownEncounters = ref(false);
    const isCaseDropdownShowing = ref(false);
    const showUISettings = ref(false);
    const canWhiteLabel = ref(false);

    onMounted(async () => {
      canWhiteLabel.value = store.getters['settings/getLicenseFlags']?.whitelabel ?? true;
    });


    const isEnrollButtonEnabled = computed(() => {
      return store.getters['settings/isEnrollButtonEnabled'] || isMobilePlatform.value;
    });
    const isExamineButtonEnabled = computed(() => {
      return store.getters['settings/isExamineButtonEnabled'];
    });
    const isEventButtonEnabled = computed(() => {
      return store.getters['rocenroll/isEventWorkflowEnabled'];
    });

    const isiPhone = computed(function() {
      try {
        const model = store.getters['settings/getDeviceInfo']['model'];
        console.log(model);

        return model.toLowerCase().includes('iphone');
      } catch {
        return false
      }
    });

    const userInitials = computed(function() {
      return store.getters['auth/userInitials'];
    });

    function toggleDropDownActions() {
      dropdownActions.value = !dropdownActions.value;
    }

    function toggleDropDownUserSettings() {
      dropDownUserSettings.value = !dropDownUserSettings.value;
    }

    /* Dialog Styles */
    const windowWidth = ref(window.innerWidth);
    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
      });
    });

    const isMobile = computed(() => {
      return windowWidth.value <= 480;
    });

    const isMobilePlatform = computed(() => {
      return Capacitor ? Capacitor.getPlatform() != 'web' : false;
    });

    const retentionStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%'
        }
      } else {
        return {
          width: '450px'
        }
      }
    });


    const uiSettingsStyle = computed (() => {
      if (windowWidth.value <= 480) {
        return {
          width: '100%',
          'max-height': '100%'
        }
      } else {
        return {
          width: '500px'
        }
      }
    });

    const serverConfigStyle = computed (() => {
      if (windowWidth.value <= 480) {
        return {
          width: '100%',
          'max-height': '100%'
        }
      } else {
        return {
          width: '900px'
        }
      }
    });

    const uxConfigStyle = computed (() => {
      if (windowWidth.value <= 480) {
        return {
          width: '100%',
          'max-height': '100%'
        }
      } else {
        return {
          width: '500px'
        }
      }
    });

    const passwordChangeStyle = computed(() => {
      if (windowWidth.value <= 480) {
        // Mobile style
        return {
          width: '90%',
        }
      } else {
        return {
          width: '700px'
        }
      }
    });

    function isPageAllowed(page) {
      return new userAccessHelper().isPageAllowed(page);
    }

    const overflowMenu = computed(() => {
      const isMobile = windowWidth.value <= 480;
      const overflowMenuItems = [];
      if (isMobile) {
        if (isPageAllowed('/events') && isEventButtonEnabled.value)  {
          overflowMenuItems.push({
            routerPath: "/events",
            label: "Events",
            clickHandler: () => { toggleDropDownActions(); }
          });
        }
        if (isPageAllowed('/encounters')) {
          overflowMenuItems.push({
            routerPath: "/views",
            label: "Saved Views",
            clickHandler: () => { toggleDropDownActions(); }
          });
        }
      }
      if (isPageAllowed('/cameras')) {
        overflowMenuItems.push({
          routerPath: "/cameras",
          label: "Cameras",
          clickHandler: () => { toggleDropDownActions(); }
        });
      }
      if (isPageAllowed('/watchlists')) {
        overflowMenuItems.push({
          routerPath: "/watchlists",
          label: "Watchlists",
          clickHandler: () => { toggleDropDownActions(); }
        });
      }
      if (isMobile) {
        overflowMenuItems.push({
          routerPath: "/livefeed",
          label: "Camera Live Feed",
          clickHandler: () => { toggleDropDownActions(); }
        });
      }
      if (isAdmin.value) {
        overflowMenuItems.push({
          routerPath: "",
          label: "Retention Settings",
          clickHandler: () => { toggleDropDownActions(); showRetention.value = !showRetention.value; }
        });
        overflowMenuItems.push({
          routerPath: "/settings/users",
          label: "User Management",
          clickHandler: () => { toggleDropDownActions(); }
        });
        overflowMenuItems.push({
          routerPath: "",
          label: "Server Settings",
          clickHandler: () => { toggleDropDownActions(); showServerConfig.value = !showServerConfig.value;}
        });
        if(canWhiteLabel.value){
          overflowMenuItems.push({
            routerPath: "",
            label: "UI Settings",
            clickHandler: () => { toggleDropDownActions(); showUISettings.value = !showUISettings.value;}
          });
        }
      }
      return overflowMenuItems;
    });

    const isAdmin = computed(() => {
      try {
        const userAccessCookie = new userAccessHelper().parseUserAccessCookie();
        return userAccessCookie && (userAccessCookie.role === 'admin');
      } catch (err) {
        return false;
      }
    });

    const userMenu = computed(() => {
      const userMenuItems = [];
      userMenuItems.push({
        routerPath: "",
        label: "Change Password",
        clickHandler: () => { toggleDropDownUserSettings(); showUser.value = !showUser.value; }
      });

      userMenuItems.push({
        routerPath: "/logout",
        label: "Logout",
        clickHandler: () => { toggleDropDownUserSettings(); }
      });
      return userMenuItems;
    });

    function getRocEnrollFlavor() {
      return store.getters['rocenroll/enrollDefaultFlavor'] ?? 'rww';
    }

    return {
      collapseNavBar,
      dropdownActions,
      toggleDropDownActions,
      dropDownUserSettings,
      toggleDropDownUserSettings,
      showRetention,
      showUser,
      showServerConfig,
      userInitials,
      retentionStyle,
      passwordChangeStyle,
      serverConfigStyle,
      uiSettingsStyle,
      isMobile,
      isiPhone,
      isEnrollButtonEnabled,
      isExamineButtonEnabled,
      isPageAllowed,
      overflowMenu,
      userMenu,
      isEventButtonEnabled,
      dropdownEncounters,
      getRocEnrollFlavor,
      isCaseDropdownShowing,
      showUISettings,
    };
  }
};
</script>

<style scoped lang="scss">
.navbar {
  height: 60px;
}

.actionItemDropdown {
  border: none !important;
}

.dropdown-toggle:after {
  display: none;
}
.header-options-dd {
  @include overwatch-title-small;
  color: #000000;
}
.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
.nameCircle {
  display: block;
  width: 30px !important;
  height: 30px !important;
  line-height: 28px;
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  color: var(--overwatch-button-text);
  // border: 1px solid #666;
  background: var(--overwatch-primary);
  margin-right: 18px;
  margin-left: 18px;

  padding: 0;
  box-shadow: none;
}
.dropdownToggle {
  color: var(--overwatch-neutral-100);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 18px;
  padding-left: 18px;
  
  display: flex;
  align-items: center;
  gap: 6px;
}
.actionItem {
  @include overwatch-body-med;

  display: flex;
  align-items: center;

  height: 100%;
  margin: 0; 
  list-style-type: none; 
  cursor: pointer; 
  padding-right: 18px; 
  padding-left: 18px;
}

.toggleArrow { 
  transition: transform 0.3s;
}

a {
  font-weight: unset;
}

/* MOBILE */
@media (max-width: 480px) {
  
  .actionItem {
    display: none;
  }
  .actionItem.mobile {
    display: flex;
  }
}
.navbar-nav{
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
}</style>

